<template>
  <div class="app">
    <!-- <ding-bu></ding-bu> -->

    <router-view></router-view>

    <!-- <di-bu></di-bu> -->

  </div> 
</template>

<script>
// import DingBu from '@/components/DingBu.vue';
// import DiBu from '@/components/DiBu.vue';

export default {
  // components: { DingBu, DiBu },

  
  }
</script>

<style>
* {
    padding: 0;
    margin: 0;
  }

  html,body,.app{
  height: 100%;
  }
  li{
    list-style: none;
  }

.app {
  text-align: center;
  -ms-overflow-style: none;
}
/* html{
  font-size: 16px !important;
} */

/* .app a {
  color: black;
} */

::-webkit-scrollbar {
  width: 8px;
  background-color: #ccc;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(192, 236, 236);
  border-radius: 5px;
}

/* 走进翼博公共部分 */
.router-link-active {
        color: red;
    }

    a {
        color: black;
    }

.yy {
        /* width: 1170px; */
        margin: 0 auto;
    }

    .yy::after {
        content: "";
        display: block;
        clear: both;
    }

    .yy-box1 {
        /* width: 220px; */
        width: 100%;
        /* float: left; */
        margin-top: 20px;
        display: flex;
    }

    /* .yy-box1-1 {
        line-height: 50px;
        margin-left: 20px;
    } */

    .yy-box1-1 li a.on {
        background-color: #0075c2;
    }

    .yy-box1 ul {
        width: 100%;
        /* margin-top: 10px; */
        /* border-top: 1px dashed #d7d7d7; */
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .yy-box1 li {
        list-style: none;
        float: left;
        /* letter-spacing:5px */
        margin-left: 20px;
    }

    .yy-box1 a {
        background: url(@/assets/右箭头.png) no-repeat 10px center;
        font-size: 20px;
        line-height: 50px;
        display: block;
        text-align: left;
        padding-left: 25px;
        /* font-family: 华文隶书; */
        /* border-bottom: 1px dashed #d7d7d7; */
        /* color:#534f4f; */
        
    }
    /* 预览图片 */
    .el-image-viewer__wrapper{
  position: absolute;
}
  .el-image-viewer__canvas{
    width: 60%;
    position: absolute;
    height: 80%;
}
.el-image-viewer__actions{
  display: none !important;
}
.el-image-viewer__canvas img{
  max-height: 50% !important;
  transform: none !important;
}
</style>
