<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">郑州翼博餐饮携手海为酒店，首次婚宴合作美食盛宴引赞叹</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>近日，郑州翼博餐饮服务有限公司（以下简称“翼博餐饮”）与郑州市知名酒店海为酒店成功达成首次婚宴合作，共同为一对新人打造了一场温馨浪漫、美食盛宴的婚礼庆典。此次合作进一步巩固了其在餐饮管理服务行业的领先地位。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-m1.png" alt=""></div>
            <p>婚宴现场，一道道色香味俱全的佳肴令人目不暇接。从色彩斑斓的凉菜拼盘到热气腾腾的主菜大菜，再到精致可口的甜品点心，每一道菜品都凝聚了翼博餐饮大厨们的心血与创意。这些美食不仅满足了味蕾的享受，更是一场视觉的盛宴。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-m2.png" alt=""></div>
           
            <div class="img_bg">
                <div class=" c"><img src="../../assets/goyb/new-m3.png" alt=""></div>
                <div class=" c"><img src="../../assets/goyb/new-m4.png" alt=""></div>
                <div class=" c"><img src="../../assets/goyb/new-m6.png" alt=""></div>
                <div class=" c"><img src="../../assets/goyb/new-m5.png" alt=""></div>
            </div>
            <p> 此次婚宴的成功举办，不仅为翼博餐饮与海为酒店的合作奠定了坚实的基础，也为翼博餐饮在婚宴市场的进一步拓展提供了有力支持。未来，翼博餐饮将继续秉承“以客户为中心，以品质为基石”的经营理念，不断创新服务模式，提升服务质量，为更多新人打造难忘的婚礼庆典。</p>
        </div>
        <hr>
        <div class="new_next">
        <router-link to="/honor/new7">
            <div class="new_right">上一篇:郑州翼博餐饮企业管理有限公司成功举办2024年度秋季安全生产管理培训会议</div>
        </router-link>
        <router-link to="/honor/new5">
            <div class="new_right">下一篇:翼博餐饮集成信息化系统盛大上线</div>
        </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    font-weight: 700;
}
.new_img1{
    width: 800px;
    height: 400px;
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    /* height: 2rem; */
    /* margin-top: .2rem; */
    /* background-color: pink; */
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
.img_bg{
    width: 800px;
    height: 400px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 5px auto;
}
.c{
    width: 400px;
    height: 198px;
    padding: 1px;
}
  </style>