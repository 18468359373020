<template>
  <div class="glht">
      <el-container>
        <div class="top" style="height: 30px;">
        <h2 style="text-align: left;background-color: lightblue;">
          <span style="margin-left: 10px;">翼博餐饮管理平台</span>
        </h2>
      </div>
        <el-header>
          <router-link to="/myht/cggl">采购管理</router-link>
          <router-link to="/myht/xmgl">项目管理</router-link>
          <router-link to="/myht/kcgl">库存管理</router-link>
          <router-link to="/myht/szgl">收支管理</router-link>
          <router-link to="/myht/rlzy">人力资源</router-link>
          <router-link to="/myht/xtgl">系统管理</router-link>
        </el-header>
          <el-container>
            <el-aside width="200px">
              <ul>
                <li><router-link to="/myht/rlzy/rl1">员工信息录入</router-link></li>
                <li><router-link to="/myht/rlzy/rl2">员工信息维护</router-link></li>
                <li><router-link to="/myht/rlzy/rl3">员工离职标注</router-link></li>
                <li><router-link to="/myht/rlzy/rl4">员工信息查询</router-link></li>
                <li><router-link to="/myht/rlzy/rl5">健康证临期预警查询</router-link></li>
              </ul> 
            </el-aside>
            <!-- <el-main>
              <div>人力资源</div>
            </el-main> -->
            <router-view></router-view>
          </el-container>
  </el-container>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>