<template>
    <div>
      <ding-bu></ding-bu>
      <router-view></router-view>
      <!-- <back-top></back-top> -->
      <di-bu></di-bu>
    </div>
  </template>
  
  <script>
  import DingBu from '@/components/DingBu.vue';
  import DiBu from '@/components/DiBu.vue';
  // import BackTop from '../BackTop.vue';
  export default {
    components: { DingBu, DiBu},
    }
  </script>
  
  <style>
  * {
      padding: 0;
      margin: 0;
    }
  
  
  </style>