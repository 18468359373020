<template>

<div>
    <div class="banner">
        <img src="../../assets/goyb.jpg" alt="">
    </div>
    <!-- 上盒子 -->
    <!-- <div class="yy-box1">
        <ul>
        <li><router-link to="/case/CaseSix" class="router-link-active">国机集团智能制造高峰论坛</router-link></li>
        <li><router-link to="/case/CaseZJLY70">中机六院成立70周年</router-link></li>
        <li><router-link to="/case/Case713">客户公司成立60周年</router-link></li>
        <li><router-link to="/case/CaseDIY">中秋月饼DIY</router-link></li>
        <li><router-link to="/case/Case38">女神节的祝福</router-link></li>
        </ul>
    </div> -->
    <h2 class="title">国机集团第一届智能制造创新发展高峰论坛</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>2021年5月25日-27日，国机集团第一届智能制造创新发展高峰论坛成功举办。本届论坛由中机六院和国机互联承办。论坛以“强基智造，数链未来”为主题，旨在深入贯彻落实习近平总书记“加快建设制造强国、加快发展先进制造业”重要指示精神，深化智能制造领域科技创新，加快技术进步和融合发展。</p>
            <p>为了贴合本次活动的主旨，翼博餐饮为论坛嘉宾推出“强基智造，数链未来”的主题活动。中国机械工业集团有限公司党委书记、董事长张晓仑先生和其他领导，在郑州翼博餐饮企业管理有限公司总经理杨天义先生陪同下，参观了餐厅的各个区域，详细了解了餐厅的环境设施和服务水平。国机集团领导对餐厅的特色活动给予了高度评价，认为此次活动准备精心、创意独特，菜品味道鲜美、外观精致，给论坛嘉宾带来了独特的用餐体验。
            通过我们的努力，赢得了国机集团领导和与会嘉宾的肯定和赞誉。</p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/znzz-6/03.jpg" alt="">
                </div>
                <div class="img2 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-left: 1.25rem;">
                    <img src="../../assets/znzz-6/04.jpg" alt="">
                </div>
                <div class="img3 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/znzz-6/01.jpg" alt="">
                </div>
                <div class="img4 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-left: 1.25rem;">
                    <img src="../../assets/znzz-6/02.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
    mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  },
}
</script>

<style scoped>
.banner{
    width: 100%;
    height: 3rem;
    overflow: hidden;
}
.banner:hover img{
    transform: scale(1.2);
}
.banner img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.yy-box1{
margin: 0;
padding-top: 20px;
padding-bottom: 20px;
background-color: #f6f6f6;
}
/* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .18rem;
}
/* 图片 */
.img-all{
    width: 70%;
    height: 9.2rem;
    /* background-color: blue; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.img-all .img1,
.img-all .img2,
.img-all .img3,
.img-all .img4{
    width: 70%;
    height: 2.2rem;
    /* background-color: yellow; */
    overflow: hidden;
}
.img-all div:hover img{
    transform: scale(1.2);
}
.img-all img{
    transition: all .4s;
    /* width: 100%;
    height: 100%; */
    display: block;
    object-fit: cover;
}

</style>