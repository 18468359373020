<template>
    <div>
        <div class="banner">
            <img src="../../assets/goyb.jpg" alt="">
        </div>
        <div class="main1">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">中机六院</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/e01.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/e02.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/e03.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/e04.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/a08.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/swc/a09.jpg" alt="">
                </div>
            </div>
        </div>
        
        <div class="main2">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">某研究所</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/b01.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/b02.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/b03.gif" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/b04.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main3">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">壹米滴答供应链</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/c01.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/c02.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">恒天重工股份有限公司</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/d01.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/d02.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/d03.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/d04.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/d05.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/d06.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main1">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">河南城建学院</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/a01.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/a02.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/a03.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/a04.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/a05.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/a06.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">中原工学院</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/f01.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/f02.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/f03.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/f04.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main1">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">疫情隔离餐保障</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/g01.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/g02.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/g03.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/dxal/g04.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">紧急团餐保障</div>
            <div class="content1 tc">
                <div class="tc1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/h01.jpg" alt="">
                </div>
                <div class="tc2">
                    <div class="tc2l wow animate__animated animate__fadeInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                        <img src="../../assets/dxal/h04.jpg" alt="">
                    </div>
                    <div class="tc2r wow animate__animated animate__fadeInUp animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                        <img src="../../assets/dxal/h03.jpg" alt="">
                    </div>
                </div>
                <div class="tc3 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/dxal/h02.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
    mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
    var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
    })
    wow.init()
    })
},
}
</script>

<style scoped>
.banner{
    width: 100%;
    height: 3rem;
    overflow: hidden;
}
.banner:hover img{
    transform: scale(1.2);
}
.banner img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.main1,
.main2{
    width: 100%;
    /* height: 3rem; */
    background-color: #ededed;
    /* margin: 20px auto; */
    padding-bottom: 20px;
}
.title1{
    font-size: .1rem;
    font-weight: 700;
    padding: 20px 0;
}
.content1{
    width: 70%;
    /* height: 2.66rem; */
    margin: 0 auto;
    display: flex;
    /* background-color: green; */
    flex-wrap: wrap;
}
.img1,.img2{
    width: 50%;
    height: 375px;
}
img{
    width: 100%;
    height: 100%;
}
.text1{
    width: 50%;
    /* height: 2.66rem; */
    /* background-color: yellow; */
}
/* 第二部分 */
.main2{
    background-color: #fff;
}
.content2{
    width: 60%;
    height: 2.6rem;
    margin: 0 auto;
}
/* 第三部分 */
.main3{
    width: 100%;
    height: 2.85rem;
    background-color: #ededed;
    padding-bottom: 20px;
}
.img3{
    width: 50%;
    height: 375px;
}
.content3{
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}
.tc{
    position: relative;
    height: 3rem;
    display: flex;
    justify-content: space-between;
}
.tc1{
    width: 356px;
    height: 580px;
}
.tc2{
    width: 600px;
    height: 580px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.tc3{
    width: 356px;
    height: 580px;
}
.tc2l{
    width: 600px;
    height: 285px;
}
.tc2r{
    width: 600px;
    height: 285px;
}
</style>