<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">六十一载砥砺奋进，共谱辉煌新篇章</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>春风拂面，万物复苏。在这春意盎然的四月，我们迎来了中国船舶集团第七一三研究所的第61个生日。在这个值得纪念的日子里，翼博餐饮为七一三所全体职工准备了一份71.3cm*6.1m的巨型蛋糕，预祝七一三所六十一周岁生日快乐。</p>
            <p>蛋糕造型设计以中国古代横卷为灵感，巧妙地将七一三所六十一载的辉煌历程和未来的美好愿景完美融合。横卷之上，七一三所六十一华诞的誓词熠熠生辉，犹如历史长河中的璀璨星辰，闪耀着对民族复兴、科技创新、国家繁荣的坚定信念和豪情壮志。</p>
            <div class="new_img2"><img src="../../assets/goyb/new-g1.png" alt=""></div>
            <p>为了保障所庆当天的活动能如期进行，4月7日下午，翼博餐饮的713项目部就开始布置展台。面点组组长亲自上阵，带领团队晚餐闭餐后立即开始加工蛋糕胚。4月8日零点三十分左右，44块蛋糕胚制作完成。随即，师傅们在展台上对首层蛋糕坯摆台、切边、量尺寸，确保整体蛋糕尺寸分毫不差。蛋糕底座布局完毕后，抹奶油、撒水果粒，然后铺第二层蛋糕坯，再抹奶油、撒水果粒，再找平。截止4月8日开餐前，生日蛋糕已基本成型。</p>
            <p>4月8日早餐闭餐后，师傅们马不停蹄地继续对生日蛋糕进行深加工。蛋糕最上面的周年誓词，采用的是绿色健康可食用的糖纸雕刻而成，对温度有极高的要求，铺设太早容易融化，铺设太晚蛋糕不易分割。所以，在深加工的时候，我们一边对糖纸的铺设时间进行严格管控，一边对蛋糕进行花瓣点缀以及鲜花造型布局，包括横卷两端的卷轴，也是到了最后时刻再用面包卷进行加工和装饰，确保生日蛋糕的每个细节都能完美无暇。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-g2.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-g3.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-g4.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-g5.jpg" alt=""></div>
            <p>4月8日中午11：50左右，所领导亲临餐厅。翼博餐饮副总经理杨天龙为就生日蛋糕的设计理念为领导们做了详细的介绍，从蛋糕的“破坚摧刚”处将蛋糕一分为二，寓意七一三所凝心聚力、攻坚克难、砥砺前行、再创佳绩。现场人头攒动，热闹非凡，大家都怀着激动的心情，共同见证这一难忘的时刻。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-g6.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-g7.jpg" alt=""></div>
            <p>此次体检不仅体现了公司对员工健康的深切关怀，也彰显了公司对食品安全和消费者健康的负责态度。公司将以此为契机，进一步加强员工健康管理，提升员工健康意识，为消费者提供更加安全、健康的餐饮服务。紧接着，现场的气氛愈发高涨。大家纷纷围聚在蛋糕周围，笑语盈盈，充满期待。师傅们忙碌而有序的分切着蛋糕，并逐一递送到每位职工的手中。蛋糕的香甜气息在空气中弥漫，让人忍不住垂涎欲滴。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-g8.jpg" alt=""></div>
            <p>那蛋糕横卷上的誓词，不仅是对过去的回顾，更是对未来的展望。我们坚信，七一三所将继续以坚定不移的信念和豪情满怀的壮志，勇毅前行，书写更加辉煌的明天。在此，我们衷心祝愿七一三所的未来更加璀璨夺目，为国家的繁荣富强和人民的幸福安康贡献更加卓越的力量，共同开创更加美好的未来！</p>
        </div>
        <hr>
        <div class="new_next">
            <!-- <div class="new_right">上一篇:</div> -->
            <router-link to="/honor/new1">
                <div class="new_right">上一篇:翼博餐饮中标郑州恒达智控新区餐厅服务商</div>
            </router-link>
            <router-link to="/honor/newSix">
                <div class="new_right">下一篇:关注员工健康，确保食品安全</div>
            </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.jian{
    width: 70%;
    /* background-color: pink; */
    margin: 0 auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    color: red;
    font-size: .12rem;
    padding: .05rem 0;
}
.content .a2{
    text-align: center;
    color: #454545;
}
.new_img1{
    width: 800px;
    /* height: 400px; */
    margin: 5px auto;
}
.new_img2{
    width: 900px;
    /* height: 400px; */
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
.new_img2 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}

  </style>