<template>
  <div class="glht">
      <el-container>
        <div class="top" style="height: 30px;">
        <h2 style="text-align: left;background-color: lightblue;">
          <span style="margin-left: 10px;">翼博餐饮管理平台</span>
        </h2>
      </div>
        <el-header>
          <router-link to="/myht/cggl">采购管理</router-link>
          <router-link to="/myht/xmgl">项目管理</router-link>
          <router-link to="/myht/kcgl">库存管理</router-link>
          <router-link to="/myht/szgl">收支管理</router-link>
          <router-link to="/myht/rlzy">人力资源</router-link>
          <router-link to="/myht/xtgl">系统管理</router-link>
        </el-header>
          <el-container>
            <el-aside width="200px">
              <ul>
                <li @click="show1=!show1"><router-link to="/myht/xmgl/xm1">项目/客户信息</router-link></li>
                <transition name="draw">
                    <ul class="xbox" v-show="show1===false">
                      <li><router-link to="">录入项目信息</router-link></li>
                      <li><router-link to="">查询修改项目信息</router-link></li>
                    </ul>
                  </transition>

                <li @click="show2=!show2"><router-link to="/myht/xmgl/xm2">客户员工管理</router-link></li>
                <transition name="draw">
                    <ul class="xbox" v-show="show2===false">
                      <li><router-link to="">录入客户员工信息</router-link></li>
                      <li><router-link to="">查询修改客户员工信息</router-link></li>
                    </ul>
                  </transition>

                <li @click="show3=!show3"><router-link to="/myht/xmgl/xm3">班组管理</router-link></li>
                <transition name="draw">
                    <ul class="xbox" v-show="show3===false">
                      <li><router-link to="">录入班组信息</router-link></li>
                      <li><router-link to="">查询修改班组信息</router-link></li>
                    </ul>
                  </transition>
              </ul> 
            </el-aside>
            <!-- <el-main>
              <div>项目管理</div>
            </el-main> -->
            <router-view></router-view>
          </el-container>
  </el-container>
    </div>
</template>

<script>
export default {
data() {
  return {
    show1: true,
    show2: true,
    show3: true,
  }
}
}
</script>

<style>

</style>