<template>

    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <!-- 上盒子 -->
      <!-- <div class="yy-box1">
          <ul>
            <li><router-link to="/case/CaseSix">国机集团智能制造高峰论坛</router-link></li>
            <li><router-link to="/case/CaseZJLY70">中机六院成立70周年</router-link></li>
            <li><router-link to="/case/Case713">客户公司成立60周年</router-link></li>
            <li><router-link to="/case/CaseDIY">中秋月饼DIY</router-link></li>
            <li><router-link to="/case/Case38">女神节的祝福</router-link></li>
          </ul>
      </div> -->
      <h2 class="title">七十载薪火相传 新时代再谱华章<br>庆祝国机集团机械工业第六设计研究院有限公司成立70周年</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>机械工业第六设计研究院有限公司（简称“中机六院”）始建于1951年。经过几代六院人的不懈努力，现已成为全国勘察设计行业首批质量管理体系升级认证"卓越"级单位、全国勘察设计行业创新型优秀企业。如今，中机六院已走过了七十个春秋，迎来了七十华诞。</p>
            <p>我们举办了"七十载薪火相传，新时代再谱华章"的活动，旨在表达对中机六院70年辉煌成就的祝贺。活动前我们与中机六院的相关部门进行了充分的沟通和协调，了解本次活动的主题，确定了活动当天的菜单，增加了菜品供应的质量和品种。为了保证菜品的新鲜，我们的工作人员通宵准备，并且制做了长670厘米，宽99厘米的蛋糕，寓意"中机六院走过七十年风雨，初心不变，薪火相传，辉煌永续，长长久久！"同时，我们还提前进行了场地布置，现场布满了庆祝彩旗，提升了现场的氛围感。在活动当天，我们为中机六院员工们提供热情周到的服务，活动现场热烈而欢乐。中机六院的领导和部分老职工一起切蛋糕，更是将活动现场的氛围推向了又一个高潮。活动结束后，我们与中机六院相关负责人进行了反馈交流，得到了他们的高度赞扬和认可。</p>
            <p>通过这次庆祝活动，中机六院的员工们感受到了我们翼博餐饮的用心和专业，同时也加深了他们对中机六院的归属感和认同感。</p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/six-70/01.jpg" alt="">
                </div>
                <div class="img2 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/six-70/02.jpg" alt="">
                </div>
                <div class="img3 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/six-70/03.jpg" alt="">
                </div>
                <h2 style="font-size: .1rem;">精心制作</h2>
                <div class="img-ji">
                    <div class="img7 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                        <img src="../../assets/six-70/07.jpg" alt="">
                    </div>
                    <div class="img4 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                        <img src="../../assets/six-70/04.jpg" alt="">
                    </div>
                    <div class="img5 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                        <img src="../../assets/six-70/05.jpg" alt="">
                    </div>
                    <div class="img6 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                        <img src="../../assets/six-70/06.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  </template>
  
  <script>
 import { WOW } from 'wowjs'
export default {
    mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  },
}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .18rem;
}
/* 图片 */
.img-all{
    width: 850px;
    height: 9.3rem;
    /* background-color: blue; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.img-all .img1,
.img-all .img2{
    width: 850px;
    height: 450px;
    /* background-color: yellow; */
    overflow: hidden;
}
.img-all .img3{
    width: 850px;
    height:300px;
    /* background-color: yellow; */
    overflow: hidden;
}
.img-all .img-ji{
    width: 850px;
    height: 473px;
    /* background-color: pink; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
}
.img-all .img-ji .img4,
.img-all .img-ji .img5,
.img-all .img-ji .img6,
.img-all .img-ji .img7{
    width: 420px;
    height:230px;
    /* background-color: gray; */
    overflow: hidden;
}

.img-all .img1:hover img,
.img-all .img2:hover img,
.img-all .img3:hover img,
.img-all .img4:hover img,
.img-all .img5:hover img,
.img-all .img6:hover img,
.img-all .img7:hover img{
    transform: scale(1.2);
}
.img-all img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
  </style>