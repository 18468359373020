import Vue from 'vue'
import App from './view/index/App.vue'
import router from './router'
import axios from 'axios'
import Container, { Loading } from 'element-ui'
import Table from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'lib-flexible'
// import animate from 'animate.css' //动画 animate.css
import Video from 'video.js' //视频
import 'video.js/dist/video-js.css'

// import MyHome from '@/components/MyHome.vue'
// import MyDb from '@/components/MyDb.vue';
// import MyTb from '@/components/MyTb.vue';
// Vue.use(MyDb,MyTb)
import animated from 'animate.css'
Vue.config.productionTip = false
Vue.use( Container )
Vue.use( Table )
// Vue.use( animate )
Vue.prototype.$video = Video //在vue的原生里添加了Video这个标签，增强了vue的功能性

axios.defaults.baseURL = '/api' 
Vue.prototype.axios = axios

//声明请求拦截器
let loadingInstance = null
axios.interceptors.request.use(config => {
  //展示 Loading 效果
  loadingInstance = Loading.service({ fullscreen: true })
  return config
})

//声明相应拦截器
axios.interceptors.response.use(response => {
  //隐藏 Loading 效果
  loadingInstance.close( 
    
  )
  return response
})


new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
