<template>
    <div class="yy">
      <div class="banner">
        <img src="../../assets/goyb.jpg" alt="">
    </div>
      <!-- 上盒子 -->
      <div class="yy-box1">
          <!-- <div class="yy-box1-1">走进翼博</div> -->
          <ul>
              <li><router-link to="/honor/myzs1">企业简介</router-link></li>
              <li><router-link to="/honor/myzs2">企业文化</router-link></li>
              <li><router-link to="/honor/myzs3">组织架构</router-link></li>
              <li><router-link to="/honor/myzs4">企业资质</router-link></li>
              <li><router-link to="/honor/myzs5">荣誉证书</router-link></li>
              <li><router-link to="/honor/myzs6">新闻动态</router-link></li>

          </ul>
      </div>
  
      <!-- 下盒子 -->
      <!-- <div class="qywh">
          <p class="red">企业愿景</p>
          <p>以感恩的心，为团餐行业尽绵薄之力。</p>
          <p class="red">企业使命</p>
          <p>民以食为天、食以安为先</p>
          <p class="red">企业精神</p>
          <p>专心、专注、专业</p>
          <p class="red">企业价值观</p>
          <p>羽翼初成、博文约礼</p>
          <p class="red">企业道德准则</p>
          <p>尊重、谦虚、务实、自信、正直、担当、协作、感恩</p>
          <p class="red">企业经营理念</p>
          <p>感恩客户、敬畏规则、诚信经营、安全为先、追求品质、不断创新</p>
      </div> -->
      <!-- 企业文化内容 -->
      <div class="yb-culture">
        <!-- <div class="yb-culture-main">
          <div class="vision-big b" @mouseover="handleMouseOver(0)">01</div>
          <div class="vision a" style="display: none;" @mouseover="handleMouseOver(0)">1</div>
          <div class="mission a" @mouseover="handleMouseOver(1)">2</div>
          <div class="mission-big b" style="display: none;" @mouseover="handleMouseOver(1)">02</div>
          <div class="mental a" @mouseover="handleMouseOver(2)">3</div>
          <div class="mental-big b" style="display: none;" @mouseover="handleMouseOver(2)">03</div>
          <div class="core-values a" @mouseover="handleMouseOver(3)">4</div>
          <div class="core-values-big b" style="display: none;" @mouseover="handleMouseOver(3)">04</div>
          <div class="morality-big b" @mouseover="handleMouseOver(4)">05</div>
          <div class="morality a" style="display: none;" @mouseover="handleMouseOver(4)">5</div>
          <div class="philosophy a" @mouseover="handleMouseOver(5)">6</div>
          <div class="philosophy-big b" style="display: none;" @mouseover="handleMouseOver(5)">06</div>
        </div> -->
        <div class="yb-culture-main">
          <div class="vision-big b1" @mouseover="handleMouseOver1(0)">
            <div class="vision-big-img">
              <img src="../../assets/goyb/culture03.jpg" alt="">
            </div>
            <div class="vision-big-box">
              <ul class="vision-big-content">
                <li style="padding-bottom: 10px;">VISION</li>
                <li style="padding-bottom: 20px;">愿景</li>
                <li style="font-size: 16px;">以感恩的心,为团餐尽绵薄之力</li>
              </ul>
            </div>
          </div>
          <div class="vision a1" style="display: none;" @mouseover="handleMouseOver1(0)">
            <ul class="vision-box">
              <li style="padding-bottom:20px">VISION</li>
              <li>愿景</li>
            </ul>
          </div>
          <div class="mission a1" @mouseover="handleMouseOver1(1)">
            <ul class="vision-box">
              <li style="padding-bottom:20px">MISSION</li>
              <li>使命</li>
            </ul>
          </div>
          <div class="mission-big b1" style="display: none;" @mouseover="handleMouseOver1(1)">
            <div class="vision-big-img">
              <img src="../../assets/04.jpg" alt="">
            </div>
            <div class="vision-big-box">
              <ul class="vision-big-content">
                <li style="padding-bottom: 10px;">MISSION</li>
                <li style="padding-bottom: 20px;">使命</li>
                <li style="font-size: 16px;">民以食为天、食以安为先</li>
              </ul>
            </div>
          </div>
          <div class="mental a1" @mouseover="handleMouseOver1(2)">
            <ul class="vision-box">
              <li style="padding-bottom:20px">MENTAL</li>
              <li>精神</li>
            </ul>
          </div>
          <div class="mental-big b1" style="display: none;" @mouseover="handleMouseOver1(2)">
            <div class="vision-big-img">
              <img src="../../assets/05.jpg" alt="">
            </div>
            <div class="vision-big-box">
              <ul class="vision-big-content">
                <li style="padding-bottom: 10px;">MENTAL</li>
                <li style="padding-bottom: 20px;">精神</li>
                <li style="font-size: 16px;">专心、专注、专业</li>
              </ul>
            </div>
          </div>
          <div class="core-values a2" @mouseover="handleMouseOver2(0)">
            <ul class="vision-box">
              <li style="padding-bottom:20px">CORE VALUES</li>
              <li>核心价值观</li>
            </ul>
          </div>
          <div class="core-values-big b2" style="display: none;" @mouseover="handleMouseOver2(0)">
            <div class="vision-big-img">
              <img src="../../assets/goyb/culture07.jpg" alt="">
            </div>
            <div class="vision-big-box">
              <ul class="vision-big-content">
                <li style="padding-bottom: 10px;">CORE VALUES</li>
                <li style="padding-bottom: 20px;">核心价值观</li>
                <li style="font-size: 16px;">羽翼初成、博文约礼</li>
              </ul>
            </div>
          </div>
          <div class="morality-big b2" @mouseover="handleMouseOver2(1)">
            <div class="vision-big-img">
              <img src="../../assets/goyb/culture04.jpg" alt="">
            </div>
            <div class="vision-big-box">
              <ul class="vision-big-content">
                <li style="padding-bottom: 10px;">MORALITY</li>
                <li style="padding-bottom: 20px;">道德准则</li>
                <li style="font-size: 16px;">尊重、谦虚、务实、自信、正直、担当、协作、感恩</li>
              </ul>
            </div>
          </div>
          <div class="morality a2" style="display: none;" @mouseover="handleMouseOver2(1)">
            <ul class="vision-box">
              <li style="padding-bottom:20px">MORALITY</li>
              <li>道德准则</li>
            </ul>
          </div>
          <div class="philosophy a2" @mouseover="handleMouseOver2(2)">
            <ul class="vision-box">
              <li style="padding-bottom:20px">PHILOSOPHY</li>
              <li>经营理念</li>
            </ul>
          </div>
          <div class="philosophy-big b2" style="display: none;" @mouseover="handleMouseOver2(2)">
            <div class="vision-big-img">
              <img src="../../assets/01.jpg" alt="">
            </div>
            <div class="vision-big-box">
              <ul class="vision-big-content">
                <li style="padding-bottom: 10px;">PHILOSOPHY</li>
                <li style="padding-bottom: 20px;">经营理念</li>
                <li style="font-size: 16px;">感恩客户、敬畏规则、诚信经营、安全为先、追求品质、不断创新</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  methods:{
    // handleMouseOver(index){
    //   // 获取所有元素类名为a b的
    //   var a = document.querySelectorAll('.a');
    //   var b = document.querySelectorAll('.b')
    //   // 根据传过来的下标,进行匹配
    //   if(index === 0){
    //     a[0].style.display = 'none'
    //     a[1].style.display = 'block'
    //     a[2].style.display = 'block'
    //     a[3].style.display = 'block'
    //     a[4].style.display = 'none'
    //     a[5].style.display = 'block'
    //     b[0].style.display = 'block'
    //     b[1].style.display = 'none'
    //     b[2].style.display = 'none'
    //     b[3].style.display = 'none'
    //     b[4].style.display = 'block'
    //     b[5].style.display = 'none'
    //   }else if(index === 1){
    //     a[0].style.display = 'block'
    //     a[1].style.display = 'none'
    //     a[2].style.display = 'block'
    //     a[3].style.display = 'block'
    //     a[4].style.display = 'none'
    //     a[5].style.display = 'block'
    //     b[0].style.display = 'none'
    //     b[1].style.display = 'block'
    //     b[2].style.display = 'none'
    //     b[3].style.display = 'none'
    //     b[4].style.display = 'block'
    //     b[5].style.display = 'none'
    //   }
    //   else if(index === 2){
    //     a[0].style.display = 'block'
    //     a[1].style.display = 'block'
    //     a[2].style.display = 'none'
    //     a[3].style.display = 'block'
    //     a[4].style.display = 'none'
    //     a[5].style.display = 'block'
    //     b[0].style.display = 'none'
    //     b[1].style.display = 'none'
    //     b[2].style.display = 'block'
    //     b[3].style.display = 'none'
    //     b[4].style.display = 'block'
    //     b[5].style.display = 'none'
    //   }
    //   else if(index === 3){
    //     a[0].style.display = 'none'
    //     a[1].style.display = 'block'
    //     a[2].style.display = 'block'
    //     a[3].style.display = 'none'
    //     a[4].style.display = 'block'
    //     a[5].style.display = 'block'
    //     b[0].style.display = 'block'
    //     b[1].style.display = 'none'
    //     b[2].style.display = 'none'
    //     b[3].style.display = 'block'
    //     b[4].style.display = 'none'
    //     b[5].style.display = 'none'
    //   }
    //   else if(index === 4){
    //     a[0].style.display = 'none'
    //     a[1].style.display = 'block'
    //     a[2].style.display = 'block'
    //     a[3].style.display = 'block'
    //     a[4].style.display = 'none'
    //     a[5].style.display = 'block'
    //     b[0].style.display = 'block'
    //     b[1].style.display = 'none'
    //     b[2].style.display = 'none'
    //     b[3].style.display = 'none'
    //     b[4].style.display = 'block'
    //     b[5].style.display = 'none'
    //   }else if(index === 5){
    //     a[0].style.display = 'none'
    //     a[1].style.display = 'block'
    //     a[2].style.display = 'block'
    //     a[3].style.display = 'block'
    //     a[4].style.display = 'block'
    //     a[5].style.display = 'none'
    //     b[0].style.display = 'block'
    //     b[1].style.display = 'none'
    //     b[2].style.display = 'none'
    //     b[3].style.display = 'none'
    //     b[4].style.display = 'none'
    //     b[5].style.display = 'block'
    //   }
    // }
    handleMouseOver1(index){
      var a = document.querySelectorAll('.a1')
      var b = document.querySelectorAll('.b1')
      for (let i = 0; i < a.length; i++) {
        if (index === i) {
          a[i].style.display = 'none'
          b[i].style.display = 'block'
        }else{
          a[i].style.display = 'block'
          b[i].style.display = 'none'
        }
      }
    },
    handleMouseOver2(index){
      var a = document.querySelectorAll('.a2')
      var b = document.querySelectorAll('.b2')
      for (let i = 0; i < a.length; i++) {
        if (index === i) {
          a[i].style.display = 'none'
          b[i].style.display = 'block'
        }else{
          a[i].style.display = 'block'
          b[i].style.display = 'none'
        }
      }
    },
  }
  }
  </script>
  
  <style scoped>
  *{
    box-sizing: border-box;
}
.banner{
        width: 100%;
        height: 3rem;
        overflow: hidden;
    }
    .banner:hover img{
        transform: scale(1.2);
    }
    .banner img{
        transition: all .4s;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
   .red {
    color: red;
   }

   .qywh {
    width: 100%;
    /* text-align: center; */
    margin: 0 auto;
    background-image: url(@/assets/bj1.jpg);
   }

   .qywh p {
        line-height: 40px;
        font-size: 18px;
        font-family: 华文隶书;

   }
   .culture{
       width: 5rem;
       height: 2.6rem;
       background-color: pink;
       margin: 0 auto;
   }
   /* 企业文化 */
  .yb-culture{
    width: 100%;
    height: 3.05rem;
    margin: 20px 0px;
  }
  .yb-culture-main{
    width: 6.1rem;
    height: 3.05rem;
    /* background-color: greenyellow; */
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    /* border: 1px solid black; */
  }
  .yb-culture .yb-culture-main .vision-big,
  .yb-culture .yb-culture-main .mission-big,
  .yb-culture .yb-culture-main .mental-big,
  .yb-culture .yb-culture-main .core-values-big,
  .yb-culture .yb-culture-main .morality-big,
  .yb-culture .yb-culture-main .philosophy-big{
    width: 3rem;
    height: 1.5rem;
    position: relative;
    color: black;
    font-size: 20px;
    /* border: 1px solid black; */
    /* background-color: #b1191a; */
    background-color: #cdcdcd;
  }
  .yb-culture .yb-culture-main .vision,
  .yb-culture .yb-culture-main .mission,
  .yb-culture .yb-culture-main .mental,
  .yb-culture .yb-culture-main .core-values,
  .yb-culture .yb-culture-main .morality,
  .yb-culture .yb-culture-main .philosophy{
    width: 1.5rem;
    height: 1.5rem;
    color: white;
    font-size: 26px;
    position: relative;
    background-color: #b1191a;
  }
  .vision-big-img{
    position: absolute;
    overflow: hidden;
    width: 1.44rem;
    height: 1.44rem;
    left: 0.03rem;
    top: 0.03rem;
    
  }
  .vision-big-img:hover img{
    transform: scale(1.2);
  }
  .vision-big-img img{
    transition: all .4s;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .vision-big-box{
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #cdcdcd;
    /* border: 1px solid black; */
    /* color: white; */
    right: 0rem;
  }
  .vision-box{
    position: absolute;
    top:50%;
    width: 100%;
    transform: translateY(-50%);
  }
  .vision-big-content{
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
  </style>