<template>
  <div>
    <ding-bu></ding-bu>
    <router-view></router-view>
    <di-bu></di-bu>
  </div>
</template>

<script>
import DingBu from '@/components/DingBu.vue';
import DiBu from '@/components/DiBu.vue';

export default {
  components: { DingBu, DiBu },

  
  }
</script>

<style>
* {
    padding: 0;
    margin: 0;
  }


</style>