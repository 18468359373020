<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">关注员工健康，确保食品安全</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>近日，为了进一步加强职工健康管理，确保食品安全，郑州翼博餐饮管理有限公司组织了一次全面的职工体检活动。本次体检包括抽血检测和胸透检查两项重要内容，旨在全面了解和掌握职工的身体状况，预防和控制潜在的健康风险。</p>
            <p>抽血检测是一项常规的血液检查，通过采集静脉血液样本，可以对职工的血常规、肝肾功能、血糖血脂等多项指标进行检测。这一检测有助于发现职工是否存在贫血、感染、炎症、肝肾功能异常等健康问题，从而及时进行干预和治疗，保障职工的身体健康。</p>
            <div class="new_img1"><img src="../../assets/goyb/new6-f2.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new6-f3.jpg" alt=""></div>
            <p>胸透检查则是一种通过X射线透视胸部的方法，可以观察职工肺部、心脏、肋骨等器官的形态和结构，检查是否存在肺结核、肺炎、气胸等呼吸系统疾病。这对于在餐饮行业工作的职工尤为重要，因为他们的工作环境可能会接触到油烟、粉尘等有害物质，容易对呼吸系统造成损害。</p>
            <p>本次体检活动精心组织、合理安排、有序开展。结合项目部供餐时间，公司精准安排体检项目，做到工作、体检两不误。坚持 “应检必检，不漏一人”的原则，确保了每位职工的身体健康都得到了关注。此次体检得到了广大职工的积极响应和配合。在体检现场，职工们按照规定的流程有序排队，配合医护人员进行各项检查。</p>
            <div class="new_img1"><img src="../../assets/goyb/new6-f1.jpg" alt=""></div>
            <p>此次体检不仅体现了公司对员工健康的深切关怀，也彰显了公司对食品安全和消费者健康的负责态度。公司将以此为契机，进一步加强员工健康管理，提升员工健康意识，为消费者提供更加安全、健康的餐饮服务。</p>
            <div class="new_img1"><img src="../../assets/goyb/new6-f4.jpg" alt=""></div>
            <p>未来，公司将继续关注员工健康，定期组织体检活动，确保每一位员工都能以最佳状态投入到工作中，为消费者提供更加优质的餐饮服务。</p>
        </div>
        <hr>
        <div class="new_next">
            <router-link to="/honor/newSeven">
                <div class="new_right">上一篇:六十一载砥砺奋进，共谱辉煌新篇章</div>
            </router-link>
            <router-link to="/honor/newFive">
                <div class="new_right">下一篇:翼博餐饮2023年会</div>
            </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.jian{
    width: 70%;
    /* background-color: pink; */
    margin: 0 auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    color: red;
    font-size: .12rem;
    padding: .05rem 0;
}
.content .a2{
    text-align: center;
    color: #454545;
}
.new_img1{
    width: 800px;
    /* height: 400px; */
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}

  </style>