<template>
    <div class="yy">
        <div class="banner">
            <img src="../../assets/goyb.jpg" alt="">
        </div>
        <!-- 上盒子 -->
        <div class="yy-box1">
            <ul>
                <li><router-link to="/honor/myzs1">企业简介</router-link></li>
                <li><router-link to="/honor/myzs2">企业文化</router-link></li>
                <li><router-link to="/honor/myzs3">组织架构</router-link></li>
                <li><router-link to="/honor/myzs4">企业资质</router-link></li>
                <li><router-link to="/honor/myzs5">荣誉证书</router-link></li>
                <li><router-link to="/honor/myzs6">新闻动态</router-link></li>
            </ul>
        </div>
        <div class="yb-main">
            <div class="yb-introduction">
            <router-link to="/honor/new7">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-n-title.png" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">郑州翼博餐饮企业管理有限公司成功举办2024年度秋季安全生产管理培训会议</div>
                        <div class="yb-new-content">在秋风送爽、硕果累累的季节里，郑州翼博餐饮企业管理有限公司于2024年8月26日在郑州海为酒店二楼一号会议室成功举办了2024年度秋季安全生产管理培训会议。</div>
                        <div class="yb-new-time">2024-08-26</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/new6">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-m-title.png" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">郑州翼博餐饮携手海为酒店，首次婚宴合作美食盛宴引赞叹</div>
                        <div class="yb-new-content">近日，郑州翼博餐饮服务有限公司（以下简称“翼博餐饮”）与郑州市知名酒店海为酒店成功达成首次婚宴合作，共同为一对新人打造了一场温馨浪漫、美食盛宴的婚礼庆典。</div>
                        <div class="yb-new-time">2024-08-09</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/new5">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-l.png" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">翼博餐饮集成信息化系统盛大上线</div>
                        <div class="yb-new-content">在这个数字化飞速发展的时代，翼博餐饮也在数字化管理领域迎来了新的变革。今日，经过长时间的精心研发与测试，我司自主研发的“翼博餐饮集成信息化系统”正式全面上线。</div>
                        <div class="yb-new-time">2024-08-01</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/new3">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-j10.png" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">翼博餐饮成功中标郑州海为酒店餐厅服务商，携手共创餐饮新篇章</div>
                        <div class="yb-new-content">近日，知名餐饮服务公司翼博餐饮宣布，公司已成功中标，成为郑州海为酒店餐厅的服务商，这标志着双方将携手开启一段全新的合作旅程。</div>
                        <div class="yb-new-time">2024-07-23</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/new4">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-k.png" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">翼博餐饮成功注册“翼帆食刻”商标，开启品牌发展新篇章</div>
                        <div class="yb-new-content">近日，郑州翼博餐饮企业管理有限公司（以下简称“翼博餐饮”）旗下的“翼帆食刻”（YFSK）商标已成功获得国家知识产权局的正式批准注册。</div>
                        <div class="yb-new-time">2024-07-15</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/new2">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-i1.png" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">守护健康，共筑美味</div>
                        <div class="yb-new-content">近日，为了进一步加强职工健康管理，确保食品安全，郑州翼博餐饮管理有限公司组织了一次全面的职工体检活动，旨在全面了解和掌握职工的身体状况，预防和控制潜在的健康风险。</div>
                        <div class="yb-new-time">2024-07-07</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/new1">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-h1-title.jpg" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">翼博餐饮中标郑州恒达智控新区餐厅服务商</div>
                        <div class="yb-new-content">郑州恒达智控科技股份有限公司在1号会议室组织招标，我公司脱颖而出，成功中标郑州恒达智控新区餐厅服务商。</div>
                        <div class="yb-new-time">2024-05-07</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/newSeven">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-g1-title.jpg" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">六十一载砥砺奋进，共谱辉煌新篇章</div>
                        <div class="yb-new-content">春风拂面，万物复苏。在这春意盎然的四月，我们迎来了中国船舶集团第七一三研究所的第61个生日。</div>
                        <div class="yb-new-time">2024-04-08</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/newSix">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-f1-title.jpg" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">关注员工健康，确保食品安全</div>
                        <div class="yb-new-content">近日，为了进一步加强职工健康管理，确保食品安全，郑州翼博餐饮管理有限公司组织了一次全面的职工体检活动。本次体检包括抽血检测和胸透检查两项重要内容，旨在全面了解和掌握职工的身体状况，预防和控制潜在的健康风险。</div>
                        <div class="yb-new-time">2024-02-27</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/newFive">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-e1-title.jpg" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">翼博餐饮2023年会</div>
                        <div class="yb-new-content">年会燃情开幕，伴随着新春的喜悦，公司领导层及核心成员相聚一堂，共同放飞梦想，展示我们的激情豪迈，前行在2024年奋斗与创新的征程上。</div>
                        <div class="yb-new-time">2024-01-13</div>
                    </div>
                </div>
            </router-link>
            <router-link to="/honor/newOne">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-a1-title.jpg" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">中机六院成立73周年庆典</div>
                        <div class="yb-new-content">为庆祝中机六院创立73周年，翼博餐饮为中机六院举办了一场别开生面的庆祝活动。此次活动以美食为载体，将我们的祝福与敬意融入其中，共同庆祝这一重要的里程碑。</div>
                        <div class="yb-new-time">2024-01-12</div>
                    </div>
                </div>
            </router-link>
                <!-- dier -->
            <router-link to="/honor/newTwo">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-b1-title.jpg" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">冬至到，吃水饺</div>
                        <div class="yb-new-content">随着风的呼啸，寒意渐浓，大地悄然沐浴在银装素裹之中。今日，正值冬至，这个寒冷而温馨的日子，是岁月的交汇，是寄托思念的桥梁。我们为大家准备了热气腾腾、香气四溢的饺子。</div>
                        <div class="yb-new-time">2023-12-22</div>
                    </div>
                </div>
            </router-link>
                <!-- disan -->
            <router-link to="/honor/newThree">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-c1-title.jpg" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">“食”刻警惕，以“演”筑防</div>
                        <div class="yb-new-content">为了提升翼博餐饮全体员工的食品安全意识，保障就餐人员的身体健康，提高对集体性食物中毒事件的应急处置能力，公司开展了食物中毒应急演练。</div>
                        <div class="yb-new-time">2023-11-06</div>
                    </div>
                </div>
            </router-link>
                <!-- disi -->
            <router-link to="/honor/newFour">
                <div class="yb-new">
                    <!-- 新闻图片 -->
                    <div class="yb-new-img">
                        <img src="../../assets/goyb/new-d1-title.jpg" alt="">
                    </div>
                    <!-- 新闻标题 -->
                    <div class="yb-new-main">
                        <div class="yb-new-title">翼博餐饮第二项目部半年工作总结</div>
                        <div class="yb-new-content">杨总和全体员工一起对翼博餐饮第二项目部成立半年来的工作部署、工作重心、实际完成情况等逐月做了详细的回顾和心得分享。</div>
                        <div class="yb-new-time">2023-10-22</div>
                    </div>
                </div>
            </router-link>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
    return {
    
    }
    },
    methods: {
    
    }
  }
</script>

<style scoped>
*{
    box-sizing: border-box;
}
.yb-main{
    width: 100%;
    /* background-color: #ededed; */
}
/* 顶部banner css */
    .banner{
        width: 100%;
        height: 3rem;
        overflow: hidden;
    }
    .banner:hover img{
        transform: scale(1.2);
    }
    .banner img{
        transition: all .4s;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
    .yb-introduction{
        width: 65%;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
    }
    .yb-new{
        height: 1.3rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: .06rem;
        padding-right: .3rem;
        border-bottom: 1px solid rgb(157, 156, 156);
    }
    /* 鼠标移入显示阴影 */
    .yb-new:hover{
        box-shadow: 0px 0px 5px #888888;
    }
    .yb-new-img{
        width: 1.7rem;
        height: 1.18rem;
        /* background-color: green; */
    }
    .yb-new-img img{
        width: 100%;
        height: 100%;
    }
    .yb-new-main{
        width: 4.2rem;
        height: 0.8rem;
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        text-align: left;
    }
    .yb-new-title{
        font-size: .1rem;
        font-weight: 700;
    }
    .yb-new-content{
        font-size: .08rem;
        line-height: .15rem;
    }
    .yb-new-time{
        font-size: .08rem;
    }
</style>