import { render, staticRenderFns } from "./MyCp2.vue?vue&type=template&id=1befede0&scoped=true&"
import script from "./MyCp2.vue?vue&type=script&lang=js&"
export * from "./MyCp2.vue?vue&type=script&lang=js&"
import style0 from "./MyCp2.vue?vue&type=style&index=0&id=1befede0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1befede0",
  null
  
)

export default component.exports