<template>
  <!-- 顶部导航栏 -->
  <div class="yb-top">
      <div class="wrapper">
        <div class="yb-top1">
          <img src="@/assets/yb.png" alt="">
          <p>郑州翼博餐饮企业管理有限公司</p>
          <!-- <a href="#" class="btns" type="a" @click="showLogdialog()">内网登录</a> -->
          <!-- <router-link target="_blank" :to="{path:'/login'}" style="padding-right: 20px;">内网登录</router-link> -->
        </div>
        <ul class="box">
          <li><router-link to="/home">首页</router-link></li>

          <li><router-link to="/honor">走进翼博</router-link>
          <!-- <li @mouseover="show=i" @mouseout="show=-1"><router-link to="/honor">走进翼博</router-link> -->
            <!-- <transition name="fade"> -->
<div>
<!-- <div  class="sub" v-show="show==i"> -->
            <ul style="height: auto; ">
              <li><router-link to="/honor/myzs1">企业简介</router-link></li>
              <li><router-link to="/honor/myzs2">企业文化</router-link></li>
              <li><router-link to="/honor/myzs3">组织架构</router-link></li>
              <li><router-link to="/honor/myzs4">企业资质</router-link></li>
              <li><router-link to="/honor/myzs5">荣誉证书</router-link></li>
              <li><router-link to="/honor/myzs6">新闻动态</router-link></li>
            </ul>
</div>
<!-- </transition> -->
          </li>

          <li><router-link to="/cp">菜品展示</router-link>
            <ul style="height: auto;">
              <li><router-link to="/cp/mycp1">荤菜</router-link></li>
              <li><router-link to="/cp/mycp5">半荤菜</router-link></li>
              <li><router-link to="/cp/mycp2">素菜</router-link></li>
              <li><router-link to="/cp/mycp3">主食甜点</router-link></li>
              <li><router-link to="/cp/mycp4">精品小吃</router-link></li>
            </ul>
          </li>
          <li><router-link to="/hotelDining">酒店餐饮</router-link>
            <ul style="height: auto; margin-left: -0.05rem;">
              <li><router-link to="/business">商务餐</router-link></li>
              <li><router-link to="/buffet">自助餐</router-link></li>
            </ul>
          </li>
          <li><router-link to="/team">团队建设</router-link>
            <ul style="height: auto;">
              <li><router-link to="/team/Introduction">团队简介</router-link></li>
              <li><router-link to="/team/Activity">团建活动</router-link></li>
              <li><router-link to="/team/Training">团队培训</router-link></li>
            </ul>
          </li>
          <li><router-link to="/management">管理体系</router-link>
            <ul style="height: auto;width: 1rem;margin-left: -.15rem;">
              <li><router-link to="/sixs">6S管理</router-link></li>
              <li><router-link to="/safe">安全管理</router-link></li>
              <li><router-link to="/system">制度规范</router-link></li>
              <li><router-link to="/pdca">PDCA循环</router-link></li>
              <li><router-link to="/nutrition">营养管理</router-link></li>
              <li><router-link to="/informatization">信息化支撑</router-link></li>
            </ul>
          </li>
          <li><router-link to="/model">典型案例</router-link></li>
          <li><router-link to="/case">重大活动</router-link>
              <ul style="height: auto;margin-left: -0.5rem; width: 1.8rem;">
                <li><router-link to="/case/CaseSix">国机集团智能制造高峰论坛</router-link></li>
                <li><router-link to="/case/CaseZJLY70">中机六院成立70周年</router-link></li>
                <li><router-link to="/case/Case713">客户公司成立60周年</router-link></li>
                <li><router-link to="/case/CaseDIY">中秋月饼DIY</router-link></li>
                <li><router-link to="/case/Case38">女神节的祝福</router-link></li>
                <li><router-link to="/case/CaseHT">恒天重工双节问候</router-link></li>
                <li><router-link to="/case/Case713dz">冬至"饺"好运</router-link></li>
              </ul>
          </li>
          <!-- <li><router-link to="/">客户登录</router-link></li> -->
          <li><a href="http://yibocanyin.com:9001">内网登录</a></li>
        </ul>
      </div>
      <div class="pandaer">
        <!-- <p style="font-size: 38px; text-shadow:1px 1px #777;">人间烟火</p> <p style="font-size: 45px; color: rgb(197, 45, 45);text-shadow:1px 1px #777;">食在翼博</p> -->
        <p class="cc">羽</p>
        <p class="bb">翼</p>
        <p class="cc" style="padding-right: 40px;">初成</p>
        <p class="bb">博</p>
        <p class="cc">文约礼</p>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // show:-1 
    }
  },
    methods: {
  }
}
</script>

<style>
 .pandaer {
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .pandaer p{
  letter-spacing:30px

 }

 .cc {
  font-size: 38px; 
  text-shadow:1px 1px #777;
  font-weight: lighter;
 }

 .bb {
  font-size: 50px; 
  color: rgb(197, 45, 45);
  text-shadow:1px 1px #777;
  font-weight: lighter;
 }
</style>