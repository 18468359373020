<template>

  <div class="dl">
        <!-- <form action="/chaos/EvilEmail.html" method="post"> -->
        <form>
            <div class="loginBox">
                <div class="loginBoxCenter">
                    <p><label for="username">用户名：</label></p>
                    <!--autofocus 规定当页面加载时按钮应当自动地获得焦点。 -->
                    <!-- placeholder提供可描述输入字段预期值的提示信息-->
                    <p><input type="text"  name="text" class="loginInput" autofocus="autofocus" required="required" autocomplete="off" placeholder="请输入邮箱/手机号" v-model.trim="username"/></p>
                    <!-- required 规定必需在提交之前填写输入字段-->
                    <p><label for="password">密码：</label></p>
                    <p><input type="password"  name="password" class="loginInput" required="required" placeholder="请输入密码" v-model.trim="password" /></p>
                    <p><a class="forgetLink" href="#">忘记密码?</a></p>
                    <!-- <input id="remember" type="checkbox" name="remember" />
                    <label for="remember">记住登录状态</label> -->
                </div>
                <div class="loginBoxButtons">

                    <button type="button" class="loginCz" @click="reset">重置</button>

                    <button class="loginBtn" @click="login">登录</button>
                    <!-- <div> 新用户注册</div> -->
                </div>
            </div>
        </form>
    </div>

</template>

<script>
export default {

    data(){
        return {
            username: '',
            password: ''
        }
    },

    methods: {
        reset() {
            this.username= ''
            this.password= ''
        },
        login() {
      if (this.username === 'admin' && this.password === '666666') {
        // 登录成功
        // 1. 存储 token
        localStorage.setItem('token', 'Bearer xxxx')
        // 2. 跳转到后台主页
        this.$router.push('/myht')
      } else {
        // 登录失败
        localStorage.removeItem('token')
      }

    }
}
// methods: {
// 	login () {
//       this.$router.push('/myht')
//     },


//     methods: {
//     login () {
//       let _this = this;
//       if (this.loginForm.username === '' || this.loginForm.password === '') {
//         alert('账号或密码不能为空');
//       } else {
//         this.axios({
//           method: 'post',
//           url: '/myht',
//           data: _this.loginForm
//         }).then(res => {
//           console.log(res.data);
//           _this.userToken = 'Bearer ' + res.data.data.body.token;
//           // 将用户token保存到vuex中
//           _this.changeLogin({ Authorization: _this.userToken });
//           _this.$router.push('/myht');
//           alert('登陆成功');
//         }).catch(error => {
//           alert('账号或密码错误');
//           console.log(error);
//         });
//       }
//     }
//   }



}
</script>

<style>


    /* html {
        background-image: url(@/assets/login.jpg);
} */

        .dl {
            /* margin-top: 15%; */
            /* height: 100vh; */
            text-align: left;
            height: 100%;
            background-image: url(@/assets/login.jpg);
        }

        .dl form {
            margin:auto;
            position:absolute; 
            top: 30%;
            left:40%;
        }

        
        .loginBox {
            background-color: #F0F4F6;
            /*上divcolor*/
            border: 1px solid #BfD6E1;
            border-radius: 5px;
            color: #444;
            font: 14px 'Microsoft YaHei', '微软雅黑';
            margin: 0 auto;
            width: 388px
        }
        
        .loginBox .loginBoxCenter {
            border-bottom: 1px solid #DDE0E8;
            padding: 24px;
        }
        
        .loginBox .loginBoxCenter p {
            margin-bottom: 10px
        }
        
        .loginBox .loginBoxButtons {
            /*background-color: #F0F4F6;*/
            /*下divcolor*/
            border-top: 0px solid #FFF;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            line-height: 28px;
            overflow: hidden;
            padding: 20px 24px;
            vertical-align: center;
            filter: alpha(Opacity=80);
            -moz-opacity: 0.5;
            opacity: 0.5;
        }
        
        .loginBox .loginInput {
            border: 1px solid #D2D9dC;
            border-radius: 2px;
            color: #444;
            font: 12px 'Microsoft YaHei', '微软雅黑';
            padding: 8px 14px;
            margin-bottom: 8px;
            width: 310px;
        }
        
        .loginBox .loginInput:FOCUS {
            border: 1px solid #B7D4EA;
            box-shadow: 0 0 8px #B7D4EA;
        }
        
        .loginBox .loginBtn {
            background-image: -moz-linear-gradient(to bottom, blue, #85CFEE);
            border: 1px solid #98CCE7;
            border-radius: 20px;
            box-shadow: inset rgba(255, 255, 255, 0.6) 0 1px 1px, rgba(0, 0, 0, 0.1) 0 1px 1px;
            color: #444;
            /*登录*/
            cursor: pointer;
            float: right;
            font: bold 13px Arial;
            padding: 10px 50px;
        }

        .loginCz {
            background-image: -moz-linear-gradient(to bottom, blue, #85CFEE);
            border: 1px solid #98CCE7;
            border-radius: 20px;
            box-shadow: inset rgba(255, 255, 255, 0.6) 0 1px 1px, rgba(0, 0, 0, 0.1) 0 1px 1px;
            color: #444;
            /*重置*/
            cursor: pointer;
            float: left;
            font: bold 13px Arial;
            padding: 10px 50px;
        }
        
        .loginBox .loginBtn:HOVER {
            background-image: -moz-linear-gradient(to top, blue, #85CFEE);
        }
        
        .loginBox a.forgetLink {
            color: #ABABAB;
            cursor: pointer;
            float: right;
            font: 11px/20px Arial;
            text-decoration: none;
            vertical-align: middle;
            /*忘记密码*/
        }
        
        .loginBox a.forgetLink:HOVER {
            color: #000000;
            text-decoration: none;
            /*忘记密码*/
        }
        
        .loginBox input#remember {
            vertical-align: middle;
        }
        
        .loginBox label[for="remember"] {
            font: 11px Arial;
        }
</style>
