<template>
   <!-- 底部信息框 -->
  <div class="bottom-box">
    <div class="bottom-main">
    <!-- <div class="bottom-mess"> -->
      <ul class="bottom-about">
        <li style="font-size: 0.08rem; color: #c4c3c1;">联系我们</li>
        <li>地址:河南省郑州市市辖区郑东新区康平路与商都路交叉口</li>
        <li>市场开发:18838101158</li>
        <li>问题投诉:18595800500</li>
        <li>企业邮箱:yibocanyin@sina.com</li>
      </ul>
      <div class="ewm">
        <!-- <div class="ewm-img"></div> -->
        <!-- <div class="ewm-font">微信</div> -->
      </div>
      <div class="bottom-menu">
        <ul>
          <li class="bottom-menu-name bottom-menu-title"><router-link to="/honor">走进翼博</router-link></li>
          <li class="bottom-menu-name"><router-link to="/honor/myzs1">企业简介</router-link></li>
          <li class="bottom-menu-name"><router-link to="/honor/myzs2">企业文化</router-link></li>
          <li class="bottom-menu-name"><router-link to="/honor/myzs3">组织架构</router-link></li>
          <li class="bottom-menu-name"><router-link to="/honor/myzs4">企业资质</router-link></li>
          <li class="bottom-menu-name"><router-link to="/honor/myzs5">荣誉证书</router-link></li>
          <li class="bottom-menu-name"><router-link to="/honor/myzs6">新闻动态</router-link></li>
        </ul>
        <ul>
          <li class="bottom-menu-name bottom-menu-title"><router-link to="/cp">菜品展示</router-link></li>
          <li class="bottom-menu-name"><router-link to="/cp/mycp1">荤菜</router-link></li>
          <li class="bottom-menu-name"><router-link to="/cp/mycp5">半荤菜</router-link></li>
          <li class="bottom-menu-name"><router-link to="/cp/mycp2">素菜</router-link></li>
          <li class="bottom-menu-name"><router-link to="/cp/mycp3">主食甜点</router-link></li>
          <li class="bottom-menu-name"><router-link to="/cp/mycp4">精品小吃</router-link></li>
        </ul>
        <ul>
          <li class="bottom-menu-name bottom-menu-title"><router-link to="/hotelDining">酒店餐饮</router-link></li>
          <li class="bottom-menu-name"><router-link to="/business">商务餐</router-link></li>
          <li class="bottom-menu-name"><router-link to="/buffet">自助餐</router-link></li>
        </ul>
        <ul>
          <li class="bottom-menu-name bottom-menu-title"><router-link to="/team">团队建设</router-link></li>
          <li class="bottom-menu-name"><router-link to="/team/Introduction">团队简介</router-link></li>
          <li class="bottom-menu-name"><router-link to="/team/Activity">团建活动</router-link></li>
          <li class="bottom-menu-name"><router-link to="/team/Training">团队培训</router-link></li>
        </ul>
        <ul>
          <li class="bottom-menu-name bottom-menu-title"><router-link to="/management">管理体系</router-link></li>
          <li class="bottom-menu-name"><router-link to="/sixs">6S管理</router-link></li>
          <li class="bottom-menu-name"><router-link to="/safe">安全管理</router-link></li>
          <li class="bottom-menu-name"><router-link to="/system">制度规范</router-link></li>
          <li class="bottom-menu-name"><router-link to="/pdca">PDCA循环</router-link></li>
          <li class="bottom-menu-name"><router-link to="/nutrition">营养管理</router-link></li>
          <li class="bottom-menu-name"><router-link to="/informatization">信息化支撑</router-link></li>
        </ul>
        <ul>
          <li class="bottom-menu-name bottom-menu-title"><router-link to="/model">典型案例</router-link></li>
        </ul>
        <ul>
          <li class="bottom-menu-name bottom-menu-title"><router-link to="/case">重大活动</router-link></li>
          <li class="bottom-menu-name"><router-link to="/case/CaseSix">国机集团智能制造高峰论坛</router-link></li>
          <li class="bottom-menu-name"><router-link to="/case/CaseZJLY70">中机六院成立70周年</router-link></li>
          <li class="bottom-menu-name"><router-link to="/case/Case713">客户公司成立60周年</router-link></li>
          <li class="bottom-menu-name"><router-link to="/case/CaseDIY">中秋月饼DIY</router-link></li>
          <li class="bottom-menu-name"><router-link to="/case/Case38">女神节的祝福</router-link></li>
          <li class="bottom-menu-name"><router-link to="/case/CaseHT">恒天重工双节问候</router-link></li>
        </ul>
        <!-- <ul>
          <li class="bottom-menu-name bottom-menu-title">客户登录</li>
        </ul> -->
        <ul>
          <li class="bottom-menu-name bottom-menu-title"><a href="http://yibocanyin.com:9001">内网登录</a></li>
        </ul>
      </div>
    </div>
    <hr>
    <div class="bottom-bei">
      <ul class="bottom-bei-mess">
        <li><a href="#" style="color: white;">豫ICP备2023010810号</a>
        <!-- <img src="@/assets/公安.png" alt="">
          <a href="#" style="color: white;">豫公网安备000000</a> -->
          </li>
        <li></li>
        <li><p>Copyright 翼博公司 版权所有 All Rights Reserved</p></li>
      </ul>
    </div>
    <div class="back_top">
      <button id="top" @click="toTop" v-if="button_show" class="el-icon-upload2"></button>
    </div>
  <!-- </div> -->
  </div>
</template>

<script>
export default {
  data(){
      return{
        button_show:false
      }
  },
  methods:{
    toTop() {
      // document.documentElement.scrollTop = 0;
      // 页面滚动的动画效果
      window.scrollTo({
        top:0,//滚动到页面顶部
        behavior:'smooth'//平滑滚动
      });
    },
    // 添加按钮在页面滚动时显示或隐藏
    handleScroll(){
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop
      //当页面滑动的距离大于300px时元素显示,否则不显示
      if (scrollTop >= 300) {
        this.button_show = true
      }else{
        this.button_show = false
      }
    },
  },
  mounted(){
    window.addEventListener('scroll',this.handleScroll)
  },
  // 组件销毁前
  beforeDestroy(){
    window.removeEventListener('scroll',this.handleScroll)
  }
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
.bottom-box{
  margin-top: 15px !important;
  width: 100%;
  background-color: #00497d;
}
.bottom-main{
  width: 80%;
  /* background-color: blue; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  /* padding-top: 20px; */
  padding-top: 0.1rem;
}
.bottom-bei{
  width: 80%;
  height: 0.2rem;
  line-height: 0.15rem;
  /* background-color: orange; */
  margin: 0 auto;
}
li{
  list-style: none;
  /* padding-top: 5px; */
  font-size: 0.06rem;
  /* font-size: 12px; */
  cursor:pointer;
}
.bottom-about{
/* width: 1.8rem; */
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: space-evenly;
/* height: 162.3px; */
color: rgb(225, 224, 224);
}
hr{
  margin-top: 0.02rem !important;
  width: 80%;
  height: 1px;
  margin: 0 auto;
  background-color: white !important;
  border: none;
}
.bottom-bei-mess{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.04rem !important;
  color: white !important;
}
.bottom-menu{
  display: flex;
  justify-content: space-around;
}
.bottom-menu-name{
  /* height: 0.12rem; */
  font-size: 12px;
  padding: 5px 5px;
  color: white;
}
.bottom-menu-title{
  /* font-size: 0.08rem; */
  font-size: 14px;
  color: white;
}
.router-link-active {
        color: white;
    }
    a {
        color: white;
    }
/* 返回顶部 */
#top{
  position: fixed;
  bottom: 20px;/* 距离底部的距离 */
  right: 20px;/* 距离右侧的距离 */
  background-color: #007bff;/**按钮背景颜色 */
  color: #fff;/**按钮文本颜色 */
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}
#top:hover{
  background-color: #0056b3;/**鼠标悬停时的背景颜色 */
}
</style>