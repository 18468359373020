<template>
    <div>
        <div class="banner">
            <img src="../../assets/goyb.jpg" alt="">
        </div>
        <div class="title">
            商务餐
        </div>
        <hr class="title-hr">
        <div class="main2">
            <!-- <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">河南城建学院</div> -->
            <div class="content1">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/a02.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/swc/a03.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/a04.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/swc/a05.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/a06.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/swc/a07.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main1">
            <!-- <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">某研究所</div> -->
            <div class="content2">
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s ">
                    <img src="../../assets/swc/b01.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b02.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b03.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b04.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b05.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s ">
                    <img src="../../assets/swc/b06.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b07.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b08.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b09.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b10.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s ">
                    <img src="../../assets/swc/b11.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b12.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b13.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b14.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b15.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s ">
                    <img src="../../assets/swc/b16.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b17.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b18.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b19.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b20.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s ">
                    <img src="../../assets/swc/b21.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b22.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b23.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b24.jpg" alt="">
                </div>
                <div class="img wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/swc/b26.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
    mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
    var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
    })
    wow.init()
    })
},
}
</script>

<style scoped>
.banner{
    width: 100%;
    height: 3rem;
    overflow: hidden;
}
.banner:hover img{
    transform: scale(1.2);
}
.banner img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.main1,
.main2{
    width: 100%;
    /* height: 3rem; */
    background-color: #ededed;
    /* margin: 20px auto; */
    padding-top: 20px;
    padding-bottom: 20px;
}
.title1{
    font-size: .1rem;
    font-weight: 700;
    padding: 20px 0;
}
.content1{
    width: 70%;
    /* height: 2.66rem; */
    margin: 0 auto;
    display: flex;
    /* background-color: green; */
    flex-wrap: wrap;
}
.img1,.img2{
    width: 50%;
    height: 375px;
}
img{
    width: 100%;
    height: 100%;
}
.text1{
    width: 50%;
    /* height: 2.66rem; */
    /* background-color: yellow; */
}
/* 第二部分 */
.main2{
    background-color: #fff;
}
.content2{
    width: 70%;
    height: 7rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: space-between;
}
.content2 .img{
    width: 250px;
    height: 250px;
}
/* 标题 */
.title{
    font-size: .13rem;
    font-weight: 700;
    padding: .15rem 0;
}
.title-hr{
    width: 70%;
    margin: 0 auto;
}
</style>