<template>
  <div class="yy">
    <!-- 左侧盒子 -->
    <div class="yy-box1">
        <ul>
          <li><router-link to="/cp/mycp1">荤菜</router-link></li>
          <li><router-link to="/cp/mycp5">半荤菜</router-link></li>
          <li><router-link to="/cp/mycp2">素菜</router-link></li>
          <li><router-link to="/cp/mycp3">主食甜点</router-link></li>
          <li><router-link to="/cp/mycp4">精品小吃</router-link></li>
        </ul>
    </div>
    <div class="main">
      <div class="title1">主食</div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/01.jpg')"
          :preview-src-list="srcList1">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/02.jpg')"
          :preview-src-list="srcList2">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/03.jpg')"
          :preview-src-list="srcList3">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/04.jpg')"
          :preview-src-list="srcList4">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/05.jpg')"
          :preview-src-list="srcList5">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/06.jpg')"
          :preview-src-list="srcList6">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/07.jpg')"
          :preview-src-list="srcList7">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/08.jpg')"
          :preview-src-list="srcList8">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/09.jpg')"
          :preview-src-list="srcList9">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/10.jpg')"
          :preview-src-list="srcList10">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/11.jpg')"
          :preview-src-list="srcList11">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/12.jpg')"
          :preview-src-list="srcList12">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/13.jpg')"
          :preview-src-list="srcList13">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/14.jpg')"
          :preview-src-list="srcList14">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/15.jpg')"
          :preview-src-list="srcList15">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/16.jpg')"
          :preview-src-list="srcList16">
        </el-image>
      </div>
      <!-- 甜品 -->
      <div class="title1">甜品</div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/22.jpg')"
          :preview-src-list="srcList22">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/23.jpg')"
          :preview-src-list="srcList23">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/24.jpg')"
          :preview-src-list="srcList24">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/25.jpg')"
          :preview-src-list="srcList25">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/26.jpg')"
          :preview-src-list="srcList26">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/27.jpg')"
          :preview-src-list="srcList27">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/28.jpg')"
          :preview-src-list="srcList28">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/29.jpg')"
          :preview-src-list="srcList29">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/30.jpg')"
          :preview-src-list="srcList30">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/31.jpg')"
          :preview-src-list="srcList31">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/32.jpg')"
          :preview-src-list="srcList32">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/33.jpg')"
          :preview-src-list="srcList33">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/34.jpg')"
          :preview-src-list="srcList34">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/35.jpg')"
          :preview-src-list="srcList35">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/36.jpg')"
          :preview-src-list="srcList36">
        </el-image>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/zstp/37.jpg')"
          :preview-src-list="srcList37">
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        srcList1: [
        require('../../assets/cp/jbc/zstp/01-b.jpg')
        ],
        srcList2: [
        require('../../assets/cp/jbc/zstp/02-b.jpg')
        ],
        srcList3: [
        require('../../assets/cp/jbc/zstp/03-b.jpg')
        ],
        srcList4: [
        require('../../assets/cp/jbc/zstp/04-b.jpg')
        ],
        srcList5: [
        require('../../assets/cp/jbc/zstp/05-b.jpg')
        ],
        srcList6: [
        require('../../assets/cp/jbc/zstp/06-b.jpg')
        ],
        srcList7: [
        require('../../assets/cp/jbc/zstp/07-b.jpg')
        ],
        srcList8: [
        require('../../assets/cp/jbc/zstp/08-b.jpg')
        ],
        srcList9: [
        require('../../assets/cp/jbc/zstp/09-b.jpg')
        ],
        srcList10: [
        require('../../assets/cp/jbc/zstp/10-b.jpg')
        ],
        srcList11: [
        require('../../assets/cp/jbc/zstp/11-b.jpg')
        ],
        srcList12: [
        require('../../assets/cp/jbc/zstp/12-b.jpg')
        ],
        srcList13: [
        require('../../assets/cp/jbc/zstp/13-b.jpg')
        ],
        srcList14: [
        require('../../assets/cp/jbc/zstp/14-b.jpg')
        ],
        srcList15: [
        require('../../assets/cp/jbc/zstp/15-b.jpg')
        ],
        srcList16: [
        require('../../assets/cp/jbc/zstp/16-b.jpg')
        ],
        srcList22: [
        require('../../assets/cp/jbc/zstp/22-b.jpg')
        ],
        srcList23: [
        require('../../assets/cp/jbc/zstp/23-b.jpg')
        ],
        srcList24: [
        require('../../assets/cp/jbc/zstp/24-b.jpg')
        ],
        srcList25: [
        require('../../assets/cp/jbc/zstp/25-b.jpg')
        ],
        srcList26: [
        require('../../assets/cp/jbc/zstp/26-b.jpg')
        ],
        srcList27: [
        require('../../assets/cp/jbc/zstp/27-b.jpg')
        ],
        srcList28: [
        require('../../assets/cp/jbc/zstp/28-b.jpg')
        ],
        srcList29: [
        require('../../assets/cp/jbc/zstp/29-b.jpg')
        ],
        srcList30: [
        require('../../assets/cp/jbc/zstp/30-b.jpg')
        ],
        srcList31: [
        require('../../assets/cp/jbc/zstp/31-b.jpg')
        ],
        srcList32: [
        require('../../assets/cp/jbc/zstp/32-b.jpg')
        ],
        srcList33: [
        require('../../assets/cp/jbc/zstp/33-b.jpg')
        ],
        srcList34: [
        require('../../assets/cp/jbc/zstp/34-b.jpg')
        ],
        srcList35: [
        require('../../assets/cp/jbc/zstp/35-b.jpg')
        ],
        srcList36: [
        require('../../assets/cp/jbc/zstp/36-b.jpg')
        ],
        srcList37: [
        require('../../assets/cp/jbc/zstp/37-b.jpg')
        ],
      }
    }
  }
</script>

<style scoped>
.main{
  width: 67%;
  /* height: 2.7rem; */
  margin-top: 20px !important;
  background-color: #ededed;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}
.img-box{
  width: 320px;
  height: 100%;
  background-color: white;
}
.title1{
  width: 100%;
  padding: 20px 0;
  font-size: .1rem;
}
</style>