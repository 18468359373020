<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">翼博餐饮中标郑州恒达智控新区餐厅服务商</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>2024年4月27日，郑州恒达智控科技股份有限公司在1号会议室组织招标，我公司脱颖而出，成功中标郑州恒达智控新区餐厅服务商。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-h5.jpg" alt=""></div>
            <p>恒达智控位于郑州市经开区，主要从事煤炭智能化开采控制系统及核心零部件的研发、生产及销售，包括电液控制系统、智能集成管控系统、液压控制系统、智能供液系统等煤炭开采智能化控制系统产品，是河南省专精特新中小企业，曾荣获河南省技术发明一等奖、中国煤炭工业协会科学技术奖、以及郑州经开区多项荣誉表彰。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-h1.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-h2.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-h4.jpg" alt=""></div>
            <p>翼博餐饮将竭尽所能，为恒达智控的广大职工和科技工作者提供高质量的餐饮服务，助力恒达智控再攀科技高峰。</p>
            <!-- <div class="new_img1"><img src="../../assets/goyb/new-h3.jpg" alt=""></div> -->
        </div>
        <hr>
        <div class="new_next">
        <router-link to="/honor/new2">
            <div class="new_right">上一篇:守护健康，共筑美味</div>
        </router-link>
        <router-link to="/honor/newSeven">
            <div class="new_right">下一篇:六十一载砥砺奋进，共谱辉煌新篇章</div>
        </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    font-weight: 700;
}
.new_img1{
    width: 800px;
    height: 400px;
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    /* height: 2rem; */
    /* margin-top: .2rem; */
    /* background-color: pink; */
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
  </style>