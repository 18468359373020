import { render, staticRenderFns } from "./Training.vue?vue&type=template&id=6783b47f&scoped=true&"
import script from "./Training.vue?vue&type=script&lang=js&"
export * from "./Training.vue?vue&type=script&lang=js&"
import style0 from "./Training.vue?vue&type=style&index=0&id=6783b47f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6783b47f",
  null
  
)

export default component.exports