<template>
  <!-- 轮播图 -->
  <div class="swiper-demo">
    <ding-bu></ding-bu>

    <div class="swiper-container swiper1">
      <div class="swiper-wrapper">
        <div class="swiper-slide"><img src="../assets/1.jpg" alt="" /></div>
        <div class="swiper-slide"><img src="../assets/2.jpg" alt="" /></div>
        <div class="swiper-slide"><img src="../assets/3.jpg" alt="" /></div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>

      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>

      <!-- 如果需要滚动条 -->
      <!-- <div class="swiper-scrollbar"></div> -->
    </div>
    <!--走进翼博 -->
    <div class="home-food">
      <div class="home-food-main">
        <div class="go-yb-img wow animate__animated animate__fadeInUp animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
          <img src="../assets/goyb.jpg" alt="" />
        </div>
        <div class="go-yb" >
          <div class="go-yb-title  wow animate__animated animate__fadeInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
            <h1 class="go-yb-title-h1 ">走进翼博</h1>
          </div>
          <div class="go-yb-content  wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
              <div class="go-yb-content-text">郑州翼博餐饮企业管理有限公司成立于2014年，注册资金2100万元，是一家专注团餐服务的餐饮企业。业务涵盖大中专院校师生餐厅、企事业单位职工餐厅、各类产业园区职工餐厅等多个领域。
              曾荣获全国优秀餐饮企业、AAA信用等级企业、全国高校后勤优秀服务企业、中国校园团餐联盟企业、重合同守信用企业、诚信经营示范单位、全国标准化食堂诚信示范单位、全国团膳质量诚信服务双十佳单位。</div>
              <div class="go-yb-content-btn"><router-link to="/honor" style="color: white;">查看更多</router-link></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 美食 -->
    <div class="home-food">
      <div class="home-food-main1">
        <div class="home-food-title  wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
          <h1 class="go-yb-title-h1 title2">菜品展示</h1>
        </div>
        <div class="home-food-img">
          <div class="view view-sixth wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s">
              <img src="../assets/cp/jbc/hc/02.jpg" alt="">
              <div class="mask">
                  <h2 style="margin-top: .4rem; font-size: .12rem;">
                      荤菜
                  </h2>
                  <h2 style="margin-top: .2rem;" class="more">
                    <li class="bottom-menu-name"><router-link to="/cp/mycp1" style="color: white;">查看更多</router-link></li>
                  </h2>
              </div>
          </div>
          <div class="view view-sixth wow animate__animated animate__fadeInLeft  animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
              <img src="../assets/cp/jbc/bhc/16.jpg" alt="">
              <div class="mask">
                <h2 style="margin-top: .4rem; font-size: .12rem;">
                      半荤菜
                  </h2>
                  <h2 style="margin-top: .2rem;" class="more">
                    <li class="bottom-menu-name"><router-link to="/cp/mycp5" style="color: white;">查看更多</router-link></li>
                  </h2>
              </div>
          </div>
          <div class="view view-sixth wow animate__animated animate__bounceIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
              <img src="../assets/cp/jbc/sc/18.jpg" alt="">
              <div class="mask">
                <h2 style="margin-top: .4rem; font-size: .12rem;">
                      素菜
                  </h2>
                  <h2 style="margin-top: .2rem;" class="more">
                    <li class="bottom-menu-name"><router-link to="/cp/mycp2" style="color: white;">查看更多</router-link></li>
                  </h2>
              </div>
          </div>
          <div class="view view-sixth wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
              <img src="../assets/cp/jbc/zstp/11.jpg" alt="">
              <div class="mask">
                <h2 style="margin-top: .4rem; font-size: .12rem;">
                      主食甜品
                  </h2>
                  
                  <h2 style="margin-top: .2rem;" class="more">
                    <li class="bottom-menu-name"><router-link to="/cp/mycp3" style="color: white;">查看更多</router-link></li>
                  </h2>
              </div>
          </div>
          <div class="view view-sixth wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s">
              <img src="../assets/cp/jbc/jpxc/01.jpg" alt="">
              <div class="mask">
                <h2 style="margin-top: .4rem; font-size: .12rem;">
                      精品小吃
                  </h2>
                  <h2 style="margin-top: .2rem;" class="more">
                    <li class="bottom-menu-name"><router-link to="/cp/mycp4" style="color: white;">查看更多</router-link></li>
                  </h2>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 团队建设 -->
    <div class="home-food">
      <div class="home-food-main a">
        <div class="go-yb" >
          <div class="go-yb-title  wow animate__animated animate__fadeInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
            <h1 class="go-yb-title-h1 ">团队建设</h1>
          </div>
          <div class="go-yb-content  wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
              <div class="go-yb-content-text">翼博餐饮由一群充满激情和创造力的人组成，他们拥有丰富的经验和技能。为了增强团队凝聚力和建立良好的协助关系，我们经常组织各种团建活动。通过这些活动，我们能够更好地了解彼此，增进信任和理解，提高团队合作能力。
除此之外，我们还注重团队成员的个人成长和发展， 提供了各种培训机会，包括安全培训、技能培训和礼仪培训等方面的内容。我们鼓励团队成员积极参与培训，不断提升自己的专业知识和技能，更好地应对工作中的挑战。</div>
              <div class="go-yb-content-btn"><router-link to="/team" style="color: white;">查看更多</router-link></div>
          </div>
        </div>
        <div class="go-yb-img wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
          <img src="../assets/training/13.jpg" alt="" />
        </div>
      </div>
    </div>
    <!-- logo墙 -->
    <div class="logo">
      <ul class="logo-main">
        <li><img src="../assets/logo/logo1.png" alt=""></li>
        <li><img src="../assets/logo/logo22.png" alt=""></li>
        <li><img src="../assets/logo/logo3.png" alt=""></li>
        <li><img src="../assets/logo/logo4.png" alt=""></li>
        <li><img src="../assets/logo/logo5.png" alt=""></li>
        <li><img src="../assets/logo/logo7.jpg" alt=""></li>
        <li><img src="../assets/logo/logo6.png" alt=""></li>
        <li class="logo-font"><p>中原区区直机关</p><p>事务管理局</p></li>
        <li><img src="../assets/logo/logo8.png" alt=""></li>
      </ul>
      <ul class="logo-main">
        <li><img src="../assets/logo/a01.jpg" alt=""></li>
        <li><img src="../assets/logo/a02.jpg" alt=""></li>
        <li><img src="../assets/logo/a03.jpg" alt=""></li>
        <li><img src="../assets/logo/a04.jpg" alt=""></li>
        <li><img src="../assets/logo/a05.jpg" alt=""></li>
        <li><img src="../assets/logo/a06.jpg" alt=""></li>
        <li><img src="../assets/logo/a07.jpg" alt=""></li>
        <li><img src="../assets/logo/a08.jpg" alt=""></li>
        <li><img src="../assets/logo/a09.png" alt=""></li>
      </ul>
    </div>
    <di-bu></di-bu>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import { WOW } from 'wowjs'

import DingBu from "@/components/DingBu.vue";
import DiBu from "@/components/DiBu.vue";
export default {
  components: { DingBu, DiBu },

  data() {
    return {
      banner: [],
    };
  },

  mounted() {
    new Swiper(".swiper1", {
      // direction: 'vertical', // 垂直切换选项
      loop: true, // 循环模式选项

      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // 如果需要滚动条
      scrollbar: {
        el: ".swiper-scrollbar",
      },

      autoplay: {
        autoplay: true, // 自动切换
        disableOnInteraction: false, // 点击之后重新计算
      },
    });

    new Swiper(".swiper2", {
      // direction: 'vertical', // 垂直切换选项
      loop: true, // 循环模式选项

      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // 如果需要滚动条
      scrollbar: {
        el: ".swiper-scrollbar",
      },

      effect: "fade",

      speed: 2000,

      autoplay: {
        autoplay: true, // 自动切换
        disableOnInteraction: false, // 点击之后重新计算
      },
    });
    // 走进翼博 动画效果
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  },
  
  methods:{
    
  }
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.yb-top {
  width: 100%;
  /* height: 130px; */
  height: 100%;
  background-image: url(../assets/t1.png);
}

.app .yb-top {
  /* background: #fff; */
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.yb-top1 {
  /* width: 100%; */
  width: 25%;
  height: 50px;
}
.yb-top1 img {
  float: left;
  padding-top: 23px;
  padding-left: 20px;
}

.yb-top1 p {
  float: left;
  padding-top: 35px;
  padding-left: 10px;
  font-family: "Lucida Console", "Courier New", monospace;
  font-size: 25px;
  font-weight: bold;
  /* color: rgb(217,12,28); */
}

.yb-top1 a {
  float: right;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.yb-top .box {
  width: 65%;
  height: 53px;
  line-height: 53px;
  font-size: 0;
  text-align: center;
  padding-top: 10px;

  /* list-style-type:none;
    white-space:nowrap */
}
.yb-top .box li {
  display: inline-block;
  font-size: 14px;
}

.yb-top .box a {
  display: inline-block;
  line-height: 1;
  padding: 0 25px;
  font-size: 22px;
  /* border-right: 2px solid #666666; */
  color: #b11212;
}

.yb-top .box a:hover {
  color: rgb(150, 153, 153);
}

.box li a {
  font-size: 17px;
}

.box li ul {
  width: 8%;
  /* height: 200px; */
  /* margin-left: -50px; */
  background-color: #fff;
  display: none;
  /* 设置初始为不显示 */
  position: absolute;
  margin: 0;
  padding: 0;
}

.box li:hover ul {
  display: block;
  z-index: 9999;
  /* 当一级菜单的li获取hover后将二级菜单的ul显示出来 */
}

.swiper1 {
  width: 100%;
  /* height: 935px; */
  height: 100%;
  margin: 0 auto;
  /* margin-top: 130px;  */
}

.swiper2 {
  width: 100%;
  /* height: 620px; */
  height: 100%;
  margin: 0 auto;
}

.yb-js {
  position: relative;

  animation: example1 1s ease-out 0s backwards;
}

@keyframes example1 {
  0% {
    top: -50px;
    /* transform: translate(-100px);
    opacity: 0; */
  }

  100% {
    top: 0;
    /* transform: translate(0);
    opacity: 1; */
  }
}


/* ----------------------------------- */
/* 盒子一 */
.yb-box2-1 {
  position: relative;

  animation: example2 1s ease-out 0s backwards;
}

@keyframes example2 {
  0% {
    transform: translate(-100px);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.yb-box2-3 {
  position: relative;

  animation: example3 1.5s ease-out 0s backwards;
}

@keyframes example3 {
  0% {
    right: -400px;
  }

  100% {
    right: 0;
  }
}

.yb-box2-4 {
  position: relative;

  animation: example4 2s ease-out 0s backwards;
}

@keyframes example4 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* 盒子三 */
.yb-box4-1 {
  position: relative;

  animation: example9 1s ease-out 0s backwards;
}

@keyframes example9 {
  0% {
    transform: translate(-100px);
    opacity: 0;
  }

  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.yb-box4-3 {
  position: relative;

  animation: example10 1.5s ease-out 0s backwards;
}

@keyframes example10 {
  0% {
    right: -400px;
  }

  100% {
    right: 0;
  }
}

.yb-box4-4 {
  position: relative;

  animation: example11 2s ease-out 0s backwards;
}

@keyframes example11 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.yb-box2 {
  width: 100%;
  height: 440px;
  display: flex;
  justify-content: center;
  text-align: left;
}

.yb-box2-1 {
  margin-right: 50px;
}

.yb-box2-2 {
  margin-left: 50px;
}

.yb-box2-3 {
  margin-top: 30px;
  font-weight: bold;
  font-size: 20px;
}

.yb-box2-3 h5 {
  font: normal 36px/50px "æ¥·ä½“";
}

.yb-box2-3 h6 {
  font: normal 48px/60px "æ¥·ä½“";
}

.yb-box2-4 {
  margin-top: 25px;
  /* color: #8d8c8c; */
  width: 620px;
  font-size: 16px;
  line-height: 36px;
}

.hz2 {
  margin-top: 50px;
  background-color: rgb(246, 242, 241);
}

.yb-box3 {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.yb-box3-1 {
  margin-right: 50px;
}

.yb-box3-2 {
  margin-top: 30px;
  font-weight: bold;
  font-size: 20px;
}

.yb-box3-3 {
  margin-top: 25px;
  color: #8d8c8c;
}

.hz3 {
  margin-top: 50px;
}

.yb-box4 {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.yb-box4-2 {
  margin-left: 50px;
}

.yb-box4-3 {
  margin-top: 30px;
  font-weight: bold;
  font-size: 20px;
}

.yb-box4-4 {
  margin-top: 25px;
  color: #8d8c8c;
}

.yb-box5 {
  width: 100%;
  height: 100%;
  margin-top: 50px;
  /* display: flex; */
  text-align: center;
  justify-content: center;
}

.yb-box5-1 {
  font-family: Cursive;
  font-size: 60px;
  color: darkgreen;
  position: relative;
  animation: example12 1s ease-out 0s backwards;
}

@keyframes example12 {
  0% {
    top: -50px;
  }

  100% {
    top: 0;
  }
}

.yb-box5-2 {
  color: #8d8c8c;
  margin-top: 50px;
}

.yb-box6 {
  /* width: 1680px; */

  /* height: 383px; */
  height: 100%;
  margin: auto;
  font-size: 14px;
}

.bored {
  background-color: rgb(43, 139, 197);
}

.yb-box6-1 {
  width: 1170px;
  height: 263px;
  margin: 0 auto;
}

.yb-box6-1-1 {
  width: 344px;
  height: 188px;
  float: left;
}

.yb-box6-1-1 h2 {
  border-bottom: 1px solid #fff;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 11px;
  font-size: 16px;
  font-weight: normal;
  line-height: 44px;
  width: 344px;
  height: 45px;
  color: #fff;
  background: url(../assets/箭头.png) no-repeat 87px center;
}

.yb-box6-1-1 p {
  font-size: 14px;
  /* margin-top: 20px; */
  color: #fff;
  text-align: left;
  line-height: 28px;
}

.yb-box6-1-2 {
  width: 344px;
  height: 188px;
  float: right;
}

.yb-box6-1-2 h2 {
  border-bottom: 1px solid #fff;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 11px;
  font-size: 16px;
  font-weight: normal;
  line-height: 44px;
  width: 344px;
  height: 45px;
  color: #fff;
  background: url(../assets/箭头.png) no-repeat 87px center;
}

.yb-box6-1-2 p {
  font-size: 14px;
  /* margin-top: 20px; */
  color: #fff;
  text-align: left;
  line-height: 28px;
}

.yb-box6-2 {
  width: 100%;
  height: 120px;
  background-color: rgb(0, 73, 125);
}

.bored2 {
  width: 1150px;
  height: 100%;
  margin: 0 auto;
}

.yb-box6-2-1 {
  width: 100%;
  height: 60px;
}

.yb-box6-2-1 p {
  width: 356px;
  height: 60px;
  float: left;
  line-height: 60px;
  /* margin-top: 60px; */
}

.yb-box6-2-1 a {
  text-decoration: none;
  color: #fff;
}

.wuyu {
  float: right;
  width: 470px;
  height: 60px;
  line-height: 60px;
  color: #fff;
}

.yb-box6-2-2 {
  width: 100%;
  height: 60px;
  color: #fff;
  line-height: 60px;
}
/* 走进翼博首页*/
.home-food{
  width: 100%;
}
.home-food-main{
  width: 80%;
  /* height: 500px; */
  /* background-color: green; */
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}
.go-yb-img{
  overflow: hidden;
  width: 3.9rem;
  height: 2.5rem;
  /* height: 500px; */
  /* background-color: blue; */
}
/* 为img添加放大效果 */
.go-yb-img:hover img{
  transform: scale(1.2);
}
/* 为img添加过渡效果 */
.go-yb-img img{
  transition: all .4s;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 转换为块级元素,宽度为100% */

.go-yb{
  width: 3rem;
  /* height: 500px; */
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
}
.go-yb-title{
  width: 3rem;
  height: 90px;
  line-height: 90px;
  text-align: center;
  /* background-color: red; */
}
.go-yb-title-h1{
  color: darkgreen;
  width: .6rem;
  height: .35rem;
  font-size: .14rem;
  padding: 5px 10px;
  border-bottom: 4px solid darkgreen;
  /* margin-left: 10px; */
}
.go-yb-content{
  width: 3rem;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* background-color: gray; */
}
.go-yb-content-text{
  width: 2.5rem;
  text-indent:2em;
  /* background-color: pink; */
  line-height: .16rem;
  font-size: 0.09rem;
  text-align: left !important;
  margin-top: 0px;
}
.go-yb-content-btn{
  width: .55rem;
  height: .15rem;
  line-height: .15rem;
  color: white;
  font-size: .07rem;
  background-color: darkgreen;
  border-radius: .7rem;
  cursor: pointer;
}
.home-food-img{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}
.home-food-title{
  width: 100%;
  /* height: 2rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.title2{
  line-height: .45rem;
}
.home-food-main1{
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 10px;
}
/* logo墙 */
.logo{
  width: 100%;
  /* height: 300px; */
  background-color: #00497d;
  margin-bottom: -18px;
  margin-top: 18px;
  padding-top: 20px;
}
.logo-main{
  width: 80%;
  /* height: 150px; */
  /* background-color: green; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.logo-main li{
  width: 0.8rem;
  height: 80px;
  background-color: white;
}
.logo-font{
  font-size: 16px;
  color: #2f3284;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: KaiTi;
}
.logo-main li img{
  width: 100%;
  height: 100%;
}
/* 菜品展示 */
.view{
    width: 1.5rem;
    height: 1.172rem;
    background-color: #666;
    overflow: hidden;
    position: relative;
    text-align: center;
    /* box-shadow: 1px 1px 2px #e6e6e6; */
    cursor: default;
}
.view img{
    transition: all 0.2s ease-in;
    display: block;
    position: relative;
}
.view .mask{
    width: 1.5rem;
    height: 1.172rem;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transform: translate();
}
.view h2{
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px 4px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0;
}
.view-sixth img{
    transition: all 0.4s ease-in-out 0.5s;
    /* opacity: 1; */
}
.view-sixth .mask{
    background-color: rgba(0,0,0, 0.5);
    opacity: 0;
    transition: all 0.3s ease-in 0.4s;
}
.view-sixth h2{
    opacity: 0;
    background: transparent;
    margin: 20px 40px 0px 40px;
    transform: scale(10);
    transition: all 0.3s ease-in-out 0.1s;
}
.view-sixth:hover .mask{
    opacity: 1;
    transition-delay: 0s;
}
.view-sixth:hover img{
    transition-delay: 0s;
}
.view-sixth:hover h2{
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.1s;
}
.more{
  cursor: pointer;
  font-size: .08rem !important;
}
.a{
  justify-content: center;
}
</style>