<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">羽翼初成&nbsp;&nbsp;&nbsp;&nbsp;博文约礼</h2>
    <hr>
    <div class="main">
        <!-- 灯笼 -->
        <div class="jian">
            <img src="../../assets/goyb/2.png" alt="">
        </div>
        <div class="content">
            <p class="a2">2024年1月13日</p>
            <p class="a2">年会燃情开幕</p>
            <p class="a2">伴随着新春的喜悦</p>
            <p class="a2">公司领导层及核心成员</p>
            <p class="a2">相聚一堂</p>
            <p class="a2">共同放飞梦想</p>
            <p class="a2">展示我们的激情豪迈</p>
            <p class="a2">前行在2024年奋斗与创新的征程上</p>
            <p class="a1">保持热爱&nbsp;&nbsp;&nbsp;&nbsp;奔赴远方</p>
            <p>首先，翼博餐饮企业管理有限公司总经理杨天义上台致辞！他感慨到：公司十年的成长，有挫折，有成功，但一路走来，企业和团队都收获颇丰。杨总对辛勤工作的家人们致以崇高的敬意！杨总表示，未来我们面临的竞争环境会更加激烈，人们对食品安全的要求越来越高，国家的监管也会越来越严，甲方对我们专业化的管理需求会越来越多，另一方面各个单位对职工福利会更加重视，团餐市场也会越来越大机会越来越多，但机会只给那些有准备的人。我们还要苦练内功，扎实做好每一项工作，日新日进，精益求精。只要我们继续上下同欲，团结一心，未来十年，公司一定能够取得更加辉煌的成就，公司员工也能获得更大的成长空间。</p>
            <div class="new_img1"><img src="../../assets/goyb/new5-e1.jpg" alt=""></div>
            <p>公司副总经理杨天龙为大家做年度工作汇报，汇报前，杨副总先是跟团队分享了一部动画短片《鹬》。通过动画片，与大家一起感悟成长，正视挑战，感恩师长。希望家人们在追求卓越的道路上，始终保持胜不骄败不馁的斗志，怀揣梦想，勇于突破自我，勇于挑战未知，善于发现机遇，与团队们并肩前行共同成长。</p>
            <div class="new_img1"><img src="../../assets/goyb/new5-e3.jpg" alt=""></div>
            <p class="a1">感恩遇见&nbsp;&nbsp;&nbsp;&nbsp;卓越前行</p>
            <p>在刚刚过去的2023年，公司取得了令人瞩目的成绩。新项目成功签约并高质量运营，为公司的业务拓展打下了坚实的基础。同时，为了更好地推进公司所有项目的运营管理标准化管理，系统研发中心也应运而生。随着系统研发中心工作的稳步推进，公司官网顺利上线，成为公司对外形象展示的另一张名片，同时也承担了对内传达公司文化的重要职责。项目管理系统也按照既定计划正式上线，陆续实现公司项目运营的智能化管理，为公司的日常运营提供了强大的支持，使得各项工作流程更加规范、高效。在品牌建设方面，公司成立了子公司——河南厨之本餐饮企业管理服务有限公司，同步推进了自主品牌翼帆食刻的商标注册，为公司的品牌发展战略奠定了基础。菜品研发团队也在2023年底成功组建。这些成果的取得，离不开全体翼博人的辛勤付出和团队协作。</p>
            <div class="new_img1"><img src="../../assets/goyb/new5-e4.jpg" alt=""></div>
            <p>这一年里，我们还有很多创新性的措施落地执行。所有项目线上监督食材验收+食品留样，为每个项目部的食品安全保驾护航；项目美食每日共享，成为了各项目团队之间对于美食制作的交流与合作平台；闭餐管理+总部巡店的线上管控措施加持，使得公司总部对所有项目的整体管控更加得心应手。</p>
            <div class="new_img1"><img src="../../assets/goyb/new5-e5.jpg" alt=""></div>
            <p>在过去的2023年，团队每个人都经历了很多，也收获了很多。大家纷纷在年会上分享自己的收获和心得。</p>
            <div class="new_img1"><img src="../../assets/goyb/new5-e6.jpg" alt=""></div>
            <p class="a1">迎接新挑战&nbsp;&nbsp;&nbsp;&nbsp;再创新辉煌</p>
            <p>在2024年的年度规划中，杨副总在公司战略规划、食品安全管理、系统研发方向、菜品研发目标、团队能力提升计划、利润共享机制等重点工作上为团队逐一作了分享。参会人员都认真聆听，对公司的发展规划信心满满。</p>
            <div class="new_img1"><img src="../../assets/goyb/new5-e7.jpg" alt=""></div>
            <p class="a1">感谢相伴&nbsp;&nbsp;&nbsp;&nbsp;欢乐同行</p>
            <p>年会中场，公司还为参会人员准备了游戏和抽奖环节，大家都积极踊跃的参与其中，三等奖、二等奖、一等奖、特等奖不断被幸运儿一一抽取。</p>
            <div class="new_img1"><img src="../../assets/goyb/new5-e8.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new5-e9.jpg" alt=""></div>
            <p class="a1">大合唱</p>
            <p>最后，杨副总把家人们日常的工作场景制作成了小视频，并配以《真心英雄》献给了在场的所有人员，大家一边欣赏着自己，一边不约而同的起立跟着音乐一起激情歌唱。因为，此时此刻，大家都是彼此心目中的真心英雄。</p>
            <p class="a2">至此，翼博餐饮2023年度总结会议圆满结束</p>
            <p class="a2">向翼博家人致敬！</p>
            <p class="a2">向真心英雄致敬！</p>
            <p class="a2">羽翼初成，感恩有您！</p>
            <div class="new_img1"><img src="../../assets/goyb/new5-e10.jpg" alt=""></div>
        </div>
        <hr>
        <div class="new_next">
            <router-link to="/honor/newSix">
                <div class="new_right">上一篇:关注员工健康，确保食品安全</div>
            </router-link>
            <router-link to="/honor/newOne">
                <div class="new_right">下一篇:中机六院成立73周年庆典</div>
            </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.jian{
    width: 70%;
    /* background-color: pink; */
    margin: 0 auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    color: red;
    font-size: .12rem;
    padding: .05rem 0;
}
.content .a2{
    text-align: center;
    color: #454545;
}
.new_img1{
    width: 800px;
    /* height: 400px; */
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}

  </style>