<template>

  <div>
    <div class="banner">
        <img src="../../assets/goyb.jpg" alt="">
    </div>
    <!-- 上盒子 -->
    <!-- <div class="yy-box1">
        <ul>
            <li><router-link to="/case/CaseSix">国机集团智能制造高峰论坛</router-link></li>
            <li><router-link to="/case/CaseZJLY70">中机六院成立70周年</router-link></li>
            <li><router-link to="/case/Case713">客户公司成立60周年</router-link></li>
            <li><router-link to="/case/CaseDIY">中秋月饼DIY</router-link></li>
            <li><router-link to="/case/Case38">女神节的祝福</router-link></li>
        </ul>
    </div> -->
    <h2 class="title">砥砺奋进60载 风华正茂谱新篇</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>在客户公司成立60周年之际，郑州翼博餐饮企业管理有限公司总经理杨天义先生高度重视，亲临现场指导项目团队连夜为客户公司现场制作生日大蛋糕，以表达翼博餐饮对客户公司成立60周年的庆祝</p>
            <p>翼博餐饮行政总厨通过不同的食材为客户公司制作了极具寓意的8道祝福菜肴摆放在生日蛋糕周围。‍‍‍‍‍‍‍‍‍‍‍‍‍‍‍</p>
            <p>第一道菜：【牡丹燕菜】此菜为河南名菜，代表翼博餐饮作为河南本土企业的服务商与客户公司共庆同贺。</p>
            <p>第二道菜：【忆苦思甜】外形如地雷，用巧克力制作，味苦；内有豌豆黄，味甜。寓意客户公司经历风风雨雨，苦尽甘来。</p>
            <p>第三道菜：【国色天香】牡丹是国花，寓意太平盛世，一花甲天下，客户公司六十周年庆期间正值牡丹花期，代表普天同庆之意。</p>
            <p>第四道菜：【富贵上上签】寓意所庆之日，吉时吉利。</p>
            <p>第五道菜：【一帆风顺】代表客户公司在未来的日子里一帆风顺，顺风顺水。</p>
            <p>第六道菜：【雄鹰展翅】寓意客户公司作为军工典范，牢记使命，智胜未来。</p>
            <p>第七道菜：【如鱼似水】寓意中国军工发展离不开各行各业协同发展，与民同兴共建。</p>
            <p>第八道菜：【再谱华章】外形似书卷，寓意客户公司在科技兴业上永创新高。</p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-left: -0.625rem;">
                    <img src="../../assets/713-60/02.jpg" alt="">
                </div>
                <div class="img2 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-left: 0.625rem;">
                    <img src="../../assets/713-60/03.jpg" alt="">
                </div>
                <div class="img3 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-left: -0.625rem;">
                    <img src="../../assets/713-60/04.jpg" alt="">
                </div>
                <div class="img4 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-left: 0.625rem;">
                    <img src="../../assets/713-60/05.jpg" alt="">
                </div>
                <div class="img5 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-left: -0.625rem;">
                    <img src="../../assets/713-60/06.jpg" alt="">
                </div>
                <div class="img6 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-left: 0.625rem;">
                    <img src="../../assets/713-60/01.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
 import { WOW } from 'wowjs'
export default {
    mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  },
}
</script>

<style scoped>
.banner{
    width: 100%;
    height: 3rem;
    overflow: hidden;
}
.banner:hover img{
    transform: scale(1.2);
}
.banner img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}

/* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .18rem;
}
/* 图片 */
.img-all{
    width: 70%;
    height: 9.2rem;
    /* background-color: blue; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.img-all .img1,
.img-all .img2,
.img-all .img3,
.img-all .img4,
.img-all .img5,
.img-all .img6{
    width: 100%;
    height: 2.2rem;
    /* background-color: yellow; */
    overflow: hidden;
}
.img-all div:hover img{
    transform: scale(1.2);
}
.img-all img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
</style>