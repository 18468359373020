<template>
    <div class="glht">
      <el-container>
        <div class="top" style="height: 30px;">
        <h2 style="text-align: left;background-color: lightblue;">
          <span style="margin-left: 10px;">翼博餐饮管理平台</span>
        </h2>
      </div>
        <el-header>
          <router-link to="/myht/cggl">采购管理</router-link>
          <router-link to="/myht/xmgl">项目管理</router-link>
          <router-link to="/myht/kcgl">库存管理</router-link>
          <router-link to="/myht/szgl">收支管理</router-link>
          <router-link to="/myht/rlzy">人力资源</router-link>
          <router-link to="/myht/xtgl">系统管理</router-link>
        </el-header>
          <el-container>
            <el-aside width="200px">
              <ul>
                <li><router-link to="/myht/cggl/cg1"><span>采购入库</span></router-link></li>

                <li @click="show1=!show1"><router-link to="/myht/cggl/cg2"><span>供应商管理</span></router-link></li>
                <!-- <transition name="draw">
                    <ul class="xbox" v-show="show1===false">
                      <li><router-link to="/myht/cggl/cg2/gys1"><p>录入新供应商</p></router-link></li>
                      <li><router-link to="/myht/cggl/cg2/gys2"><p>查询修改供应商信息</p></router-link></li>
                    </ul>
                  </transition> -->

                <li @click="show2=!show2"><router-link to="/myht/cggl/cg3"><span>供应商员工管理</span></router-link></li>
                <!-- <transition name="draw">
                    <ul class="xbox" v-show="show2===false">
                      <li><router-link to="/myht/cggl/cg3/gysyg1"><p>录入新供应商员工</p></router-link></li>
                      <li><router-link to="/myht/cggl/cg3/gysyg2"><p>查询修改供应商员工信息</p></router-link></li>
                    </ul>
                  </transition> -->

                <li @click="show3=!show3"><router-link to="/myht/cggl/cg4"><span>原材料管理</span></router-link></li>
                <!-- <transition name="draw">
                    <ul class="xbox" v-show="show3===false">
                      <li><router-link to="/myht/cggl/cg4/ycl1"><p>录入新原材料</p></router-link></li>
                      <li><router-link to="/myht/cggl/cg4/ycl2"><p>查询修改原材料信息</p></router-link></li>
                    </ul>
                  </transition> -->

                <li @click="show4=!show4"><router-link to="/myht/cggl/cg5"><span>原材料分类管理</span></router-link></li>
                <transition name="draw">
                    <ul class="xbox" v-show="show4===false">
                      <li><router-link to="/myht/cggl/cg5/yclfl1"><p>原材料分类信息显示</p></router-link></li>
                      <li><router-link to="/myht/cggl/cg5/yclfl2"><p>增加新的子类</p></router-link></li>
                      <li><router-link to="/myht/cggl/cg5/yclfl3"><p>查询修改子类信息</p></router-link></li>
                      <li><router-link to="/myht/cggl/cg5/yclfl4"><p>增加新的大类</p></router-link></li>
                      <li><router-link to="/myht/cggl/cg5/yclfl5"><p>查询修改大类信息</p></router-link></li>
                    </ul>
                  </transition>
              </ul> 
            </el-aside>
            <!-- <el-main>
              <div>采购管理</div>
            </el-main> -->
            <router-view></router-view>
          </el-container>
  </el-container>
    </div>
  </template>
  
  <script>
  export default {
  data() {
    return {
        show1:true ,
        show2:true,
        show3:true,
        show4:true
    }
  },
  methods: {

  }
  }
  </script>
  
  <style>
.draw-enter-active,.draw-leave-active {
		transition: all .3s ease;         
        max-height: 500px; 
}

.draw-enter,.draw-leave-to{
         height: 0;          
         opacity: 0;
}

.xbox a{
  background-color: #b6a3a3;
  font-size: 12px;
}


  .glht {
      position : absolute;
      width : 100%;
      height : 100%;
  }
  
  .el-header {
      background-color: #B3C0D1;
      color: #333;
      text-align: left;
      line-height: 60px;
    }
  
    .el-header a {
      margin-left: 15px;
    }
    
    .el-aside {
      background-color: #D3DCE6;
      color: #333;
      text-align: center;
    }
  
    .el-aside ul li {
      line-height: 60px;
    }

    .el-aside ul li span {
      display: block;
      text-align: left;
      margin-left: 35px;
    }

    .el-aside ul li p {
      display: block;
      text-align: left;
      margin-left: 50px;
    }
  
    .el-aside ul {
      border-top: 1px solid #333;
    }
  
    .el-aside ul li a {
      display: block;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid #333;
    }
  
    .el-aside ul li a:hover {
      background-color: beige;
    }
    
    .el-main {
      background-color: #E9EEF3;
      color: #333;
      text-align: center;
      line-height: 160px;
    }
  
    .el-container {
      height: 100%;
    }
  </style>