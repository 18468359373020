<template>
    <div class="yy">
      <!-- 左侧盒子 -->
      <div class="yy-box1">
          <ul>
            <li><router-link to="/cp/mycp1">荤菜</router-link></li>
            <li><router-link to="/cp/mycp5">半荤菜</router-link></li>
            <li><router-link to="/cp/mycp2">素菜</router-link></li>
            <li><router-link to="/cp/mycp3">主食甜点</router-link></li>
            <li><router-link to="/cp/mycp4">精品小吃</router-link></li>
          </ul>
      </div>
      <div class="main">
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/d.png')"
            :preview-src-list="srcList25">
          </el-image>
          <span class="title b">山东手工空心面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/02.jpg')"
            :preview-src-list="srcList2">
          </el-image>
          <span class="title b">酸汤大刀面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/03.jpg')"
            :preview-src-list="srcList3">
          </el-image>
          <span class="title b">桂林米粉</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/04.jpg')"
            :preview-src-list="srcList4">
          </el-image>
          <span class="title b">鸡汤面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/05.jpg')"
            :preview-src-list="srcList5">
          </el-image>
          <span class="title b">襄县焖面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/06.jpg')"
            :preview-src-list="srcList6">
          </el-image>
          <span class="title b">牛肉拉面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/07.jpg')"
            :preview-src-list="srcList7">
          </el-image>
          <span class="title b">牛杂面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/08.jpg')"
            :preview-src-list="srcList8">
          </el-image>
          <span class="title b">排骨米线</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/09.jpg')"
            :preview-src-list="srcList9">
          </el-image>
          <span class="title b">手工面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/10.jpg')"
            :preview-src-list="srcList10">
          </el-image>
          <span class="title b">牛肉汤</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/11.jpg')"
            :preview-src-list="srcList11">
          </el-image>
          <span class="title b">合记烩面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/12.jpg')"
            :preview-src-list="srcList12">
          </el-image>
          <span class="title b">羊杂汤</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/13.jpg')"
            :preview-src-list="srcList13">
          </el-image>
          <span class="title b">麻辣烫</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/14.jpg')"
            :preview-src-list="srcList14">
          </el-image>
          <span class="title b">牛肉丸子汤</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/15.jpg')"
            :preview-src-list="srcList15">
          </el-image>
          <span class="title b">特色卤面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/16.jpg')"
            :preview-src-list="srcList16">
          </el-image>
          <span class="title b">肉丝炝锅面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/17.jpg')"
            :preview-src-list="srcList17">
          </el-image>
          <span class="title b">热干面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/18.jpg')"
            :preview-src-list="srcList18">
          </el-image>
          <span class="title b">土鸡面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/19.jpg')"
            :preview-src-list="srcList19">
          </el-image>
          <span class="title b">羊肉泡馍</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/20.jpg')"
            :preview-src-list="srcList20">
          </el-image>
          <span class="title b">鸡汤混沌</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/21.jpg')"
            :preview-src-list="srcList21">
          </el-image>
          <span class="title b">方城烩面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/22.jpg')"
            :preview-src-list="srcList22">
          </el-image>
          <span class="title b">豆腐菜</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/23.jpg')"
            :preview-src-list="srcList23">
          </el-image>
          <span class="title b">番茄牛肉面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/24.jpg')"
            :preview-src-list="srcList24">
          </el-image>
          <span class="title b">大排面</span>
        </div>
        
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/01.jpg')"
            :preview-src-list="srcList1">
          </el-image>
          <span class="title b">葱油拌面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/b.png')"
            :preview-src-list="srcList26">
          </el-image>
          <span class="title b">菌香牛肉拌面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/a.png')"
            :preview-src-list="srcList27">
          </el-image>
          <span class="title b">鸡丝拌面</span>
        </div>
        <div class="img-box a">
          <el-image
            style="width: 100%; height: 85%"
            :src="require('../../assets/cp/jbc/jpxc/c.png')"
            :preview-src-list="srcList28">
          </el-image>
          <span class="title b">肉沫拌面</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      data() {
        return {
          srcList1: [
          require('../../assets/cp/jbc/jpxc/01-b.jpg')
          ],
          srcList2: [
          require('../../assets/cp/jbc/jpxc/02-b.jpg')
          ],
          srcList3: [
          require('../../assets/cp/jbc/jpxc/03-b.jpg')
          ],
          srcList4: [
          require('../../assets/cp/jbc/jpxc/04-b.jpg')
          ],
          srcList5: [
          require('../../assets/cp/jbc/jpxc/05-b.jpg')
          ],
          srcList6: [
          require('../../assets/cp/jbc/jpxc/06-b.jpg')
          ],
          srcList7: [
          require('../../assets/cp/jbc/jpxc/07-b.jpg')
          ],
          srcList8: [
          require('../../assets/cp/jbc/jpxc/08-b.jpg')
          ],
          srcList9: [
          require('../../assets/cp/jbc/jpxc/09-b.jpg')
          ],
          srcList10: [
          require('../../assets/cp/jbc/jpxc/10-b.jpg')
          ],
          srcList11: [
          require('../../assets/cp/jbc/jpxc/11-b.jpg')
          ],
          srcList12: [
          require('../../assets/cp/jbc/jpxc/12-b.jpg')
          ],
          srcList13: [
          require('../../assets/cp/jbc/jpxc/13-b.jpg')
          ],
          srcList14: [
          require('../../assets/cp/jbc/jpxc/14-b.jpg')
          ],
          srcList15: [
          require('../../assets/cp/jbc/jpxc/15-b.jpg')
          ],
          srcList16: [
          require('../../assets/cp/jbc/jpxc/16-b.jpg')
          ],
          srcList17: [
          require('../../assets/cp/jbc/jpxc/17-b.jpg')
          ],
          srcList18: [
          require('../../assets/cp/jbc/jpxc/18-b.jpg')
          ],
          srcList19: [
          require('../../assets/cp/jbc/jpxc/19-b.jpg')
          ],
          srcList20: [
          require('../../assets/cp/jbc/jpxc/20-b.jpg')
          ],
          srcList21: [
          require('../../assets/cp/jbc/jpxc/21-b.jpg')
          ],
          srcList22: [
          require('../../assets/cp/jbc/jpxc/22-b.jpg')
          ],
          srcList23: [
          require('../../assets/cp/jbc/jpxc/23-b.jpg')
          ],
          srcList24: [
          require('../../assets/cp/jbc/jpxc/24-b.jpg')
          ],
          srcList25: [
          require('../../assets/cp/jbc/jpxc/d1.png')
          ],
          srcList26: [
          require('../../assets/cp/jbc/jpxc/b1.png')
          ],
          srcList27: [
          require('../../assets/cp/jbc/jpxc/a1.png')
          ],
          srcList28: [
          require('../../assets/cp/jbc/jpxc/c1.png')
          ],
        }
      }
    }
  </script>
  
  <style scoped>
  .main{
    width: 70%;
    /* height: 2.7rem; */
    background-color: #ededed;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
  }
  .img-box{
    width: 320px;
    height: 250px;
    background-color: white;
  }
  .title{
    font-size: .08rem;
    line-height: .15rem;
  }
  .a:hover{
    background-color: red;
    color: white;
  }
  </style>