<template>
    <div class="yy">
      <!-- 左侧盒子 -->
      <div class="yy-box1">
          <ul>
            <li><router-link to="/gy/gy1">组织架构</router-link></li>
            <li><router-link to="/gy/gy2">联系我们</router-link></li>
          </ul>
      </div>

      <!-- 右侧盒子 -->
      <div class="yy-box2">
          <div class="yy-box2-1">
              <div class="gywm">
                <div><img src="@/assets/gs.png" alt=""><p>公司名称：郑州翼博餐饮企业管理有限公司</p></div>
                <div><img src="@/assets/dh.png" alt=""><p>业务咨询：xxxx-xxxxxxxx</p></div>
                <div><img src="@/assets/fw.png" alt=""><p>质量服务：xxxx-xxxxxxxx</p></div>
                <div><img src="@/assets/dz.png" alt=""><p>地址：中国·郑州</p></div>
              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
.gywm img {
    float: left;
    margin-left: 22px;
    /* margin-top: 10px; */
}

.gywm p {
    text-align: left;
    margin-left: 60px;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
}

      
  </style>