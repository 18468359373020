<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">“食”刻警惕&nbsp;&nbsp;&nbsp;&nbsp;以“演”筑防</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>为了提升郑州翼博餐饮企业管理有限公司员工的食品安全意识，保障就餐人员的身体健康，提高对集体性食物中毒事件的应急处置能力。11月6日，公司开展了食物中毒应急演练。演练由公司副总杨天龙担任此次总指挥。</p>
            <p>本次演练假设时间：午餐正在进行时，日常巡检人员发现某员工手扶餐桌，表情痛苦，头部低沉，上吐下泻，疑似食物中毒。巡检人员发现后立即向大堂经理报告，确认情况后，大堂经理向项目经理汇报，随即启动食物中毒应急预案，对该员工进行初期施救、催吐，并同步联系120急救进行送医救治。工作小组其他成员对该员工食用过的所有食物、呕吐物、当餐食品留样、当餐原材料、检疫检验报告进行收集和封存。演练过程中，各工作小组各司其职、协调联动，高效有序开展应急处置工作。</p>
            <p class="a1">任务部署</p>
            <div class="new_img1"><img src="../../assets/goyb/new3-c1.jpg" alt=""></div>
            <p class="a1">封存证物</p>
            <div class="new_img1"><img src="../../assets/goyb/new3-c2.jpg" alt=""></div>
            <p class="a1">封存证物</p>
            <div class="new_img1"><img src="../../assets/goyb/new3-c6.jpg" alt=""></div>
            <p class="a1">小心搀扶当事人</p>
            <div class="new_img1"><img src="../../assets/goyb/new3-c3.jpg" alt=""></div>
            <p class="a1">确保紧急通道畅通</p>
            <div class="new_img1"><img src="../../assets/goyb/new3-c4.jpg" alt=""></div>
            <p class="a1">送至急救车辆</p>
            <div class="new_img1"><img src="../../assets/goyb/new3-c5.jpg" alt=""></div>
            <p>演练结束后，公司副总经理杨天龙带领大家认真回顾总结了演练过程，既肯定了演练成果，同时也指出了存在的不足。通过此次演练，切实提升了翼博餐饮全体员工的食品安全意识和应急处置能力。</p>
            <p class="a1">总结演练过程</p>
            <div class="new_img1"><img src="../../assets/goyb/new3-c7.jpg" alt=""></div>
        </div>
        <hr>
        <div class="new_next">
        <router-link to="/honor/newTwo">
            <div class="new_right">上一篇:冬至到，吃水饺</div>
        </router-link>
        <router-link to="/honor/newFour">
            <div class="new_right">下一篇:翼博餐饮第二项目部半年工作总结</div>
        </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    color: red;
    /* font-weight: 700; */
}
.new_img1{
    width: 800px;
    height: 400px;
    margin: 5px auto;
}
/* 翻篇 */
.new_next{
    width: 100%;
    /* height: 2rem; */
    /* margin-top: .2rem; */
    /* background-color: pink; */
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
  </style>