<template>
  <div class="yy">
    <!-- 左侧盒子 -->
    <div class="yy-box1">
        <ul>
          <li><router-link to="/cp/mycp1">荤菜</router-link></li>
          <li><router-link to="/cp/mycp5">半荤菜</router-link></li>
          <li><router-link to="/cp/mycp2">素菜</router-link></li>
          <li><router-link to="/cp/mycp3">主食甜点</router-link></li>
          <li><router-link to="/cp/mycp4">精品小吃</router-link></li>
        </ul>
    </div>
    <div class="main">
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/01.jpg')"
          :preview-src-list="srcList1">
        </el-image>
        <span class="title b">白菜油豆腐</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/02.jpg')"
          :preview-src-list="srcList2">
        </el-image>
        <span class="title b">豉油菜心</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/03.jpg')"
          :preview-src-list="srcList3">
        </el-image>
        <span class="title b">豉油有机花菜</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/04.jpg')"
          :preview-src-list="srcList4">
        </el-image>
        <span class="title b">醋溜土豆丝</span>
      </div>
      
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/06.jpg')"
          :preview-src-list="srcList6">
        </el-image>
        <span class="title b">豆角杭茄</span>
      </div>
      
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/08.jpg')"
          :preview-src-list="srcList8">
        </el-image>
        <span class="title b">粉丝娃娃菜</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/09.jpg')"
          :preview-src-list="srcList9">
        </el-image>
        <span class="title b">腐竹木耳</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/10.jpg')"
          :preview-src-list="srcList10">
        </el-image>
        <span class="title b">干煸豆角</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/11.jpg')"
          :preview-src-list="srcList11">
        </el-image>
        <span class="title b">干煸莲条</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/12.jpg')"
          :preview-src-list="srcList12">
        </el-image>
        <span class="title b">红烧茄子</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/13.jpg')"
          :preview-src-list="srcList13">
        </el-image>
        <span class="title b">黄心菜烧豆腐</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/15.jpg')"
          :preview-src-list="srcList15">
        </el-image>
        <span class="title b">椒盐白玉菇</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/16.jpg')"
          :preview-src-list="srcList16">
        </el-image>
        <span class="title b">卤水千张</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/17.jpg')"
          :preview-src-list="srcList17">
        </el-image>
        <span class="title b">麻婆豆腐</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/18.jpg')"
          :preview-src-list="srcList18">
        </el-image>
        <span class="title b">麻油小白菜</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/33.jpg')"
          :preview-src-list="srcList19">
        </el-image>
        <span class="title b">炒贡菜</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/20.jpg')"
          :preview-src-list="srcList20">
        </el-image>
        <span class="title b">烧平菇</span>
      </div>
      
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/22.jpg')"
          :preview-src-list="srcList22">
        </el-image>
        <span class="title b">蒜苗焖子</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/23.jpg')"
          :preview-src-list="srcList23">
        </el-image>
        <span class="title b">蒜茸豆苗</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/24.jpg')"
          :preview-src-list="srcList24">
        </el-image>
        <span class="title b">蒜蓉金针菇</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/25.jpg')"
          :preview-src-list="srcList25">
        </el-image>
        <span class="title b">西芹腐竹</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/26.jpg')"
          :preview-src-list="srcList26">
        </el-image>
        <span class="title b">香菇菜心</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/28.jpg')"
          :preview-src-list="srcList28">
        </el-image>
        <span class="title b">香辣薯条</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/29.jpg')"
          :preview-src-list="srcList29">
        </el-image>
        <span class="title b">小白菜炒油皮</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/30.jpg')"
          :preview-src-list="srcList30">
        </el-image>
        <span class="title b">蟹黄豆腐</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/31.jpg')"
          :preview-src-list="srcList31">
        </el-image>
        <span class="title b">鱼香茄子</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/32.jpg')"
          :preview-src-list="srcList32">
        </el-image>
        <span class="title b">蒸时蔬</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/34.jpg')"
          :preview-src-list="srcList33">
        </el-image>
        <span class="title b">松仁玉米粒</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        srcList1: [
        require('../../assets/cp/jbc/sc/01-big.jpg')
        ],
        srcList2: [
        require('../../assets/cp/jbc/sc/02-b.jpg')
        ],
        srcList3: [
        require('../../assets/cp/jbc/sc/03-b.jpg')
        ],
        srcList4: [
        require('../../assets/cp/jbc/sc/04-b.jpg')
        ],

        srcList6: [
        require('../../assets/cp/jbc/sc/06-b.jpg')
        ],
        
        srcList8: [
        require('../../assets/cp/jbc/sc/08-b.jpg')
        ],
        srcList9: [
        require('../../assets/cp/jbc/sc/09-b.jpg')
        ],
        srcList10: [
        require('../../assets/cp/jbc/sc/10-b.jpg')
        ],
        srcList11: [
        require('../../assets/cp/jbc/sc/11-b.jpg')
        ],
        srcList12: [
        require('../../assets/cp/jbc/sc/12-b.jpg')
        ],
        srcList13: [
        require('../../assets/cp/jbc/sc/13-b.jpg')
        ],
        srcList15: [
        require('../../assets/cp/jbc/sc/15-b.jpg')
        ],
        srcList16: [
        require('../../assets/cp/jbc/sc/16-b.jpg')
        ],
        srcList17: [
        require('../../assets/cp/jbc/sc/17-b.jpg')
        ],
        srcList18: [
        require('../../assets/cp/jbc/sc/18-b.jpg')
        ],
        srcList19: [
        require('../../assets/cp/jbc/sc/33-b.jpg')
        ],
        srcList20: [
        require('../../assets/cp/jbc/sc/20-b.jpg')
        ],
        
        srcList22: [
        require('../../assets/cp/jbc/sc/22-b.jpg')
        ],
        srcList23: [
        require('../../assets/cp/jbc/sc/23-b.jpg')
        ],
        srcList24: [
        require('../../assets/cp/jbc/sc/24-b.jpg')
        ],
        srcList25: [
        require('../../assets/cp/jbc/sc/25-b.jpg')
        ],
        srcList26: [
        require('../../assets/cp/jbc/sc/26-b.jpg')
        ],
        srcList28: [
        require('../../assets/cp/jbc/sc/28-b.jpg')
        ],
        srcList29: [
        require('../../assets/cp/jbc/sc/29-b.jpg')
        ],
        srcList30: [
        require('../../assets/cp/jbc/sc/30-b.jpg')
        ],
        srcList31: [
        require('../../assets/cp/jbc/sc/31-b.jpg')
        ],
        srcList32: [
        require('../../assets/cp/jbc/sc/32-b.jpg')
        ],
        srcList33: [
        require('../../assets/cp/jbc/sc/34-b.jpg')
        ],
      }
    }
  }
</script>

<style scoped>
.main{
  width: 70%;
  /* height: 2.7rem; */
  margin-top: 20px !important;
  background-color: #ededed;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}
.img-box{
  width: 320px;
  height: 250px;
  background-color: white;
}
.title{
  font-size: .08rem;
  line-height: .15rem;
}
.a:hover{
  background-color: red;
  color: white;
}
</style>