<template>

    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <div class="title">
        <h2 class="title1">冬至&nbsp;&nbsp;"饺"&nbsp;&nbsp;好运</h2>
        <p class="title2">——职工餐厅冬至水饺保障纪实</p>
      </div>
    <hr>
    <div class="main">
        <div class="content">
            <p>“冬至大如年，人间小团圆，愿你三冬暖，愿你春不寒。”</p>
            <p>在河南，冬至当天都有吃饺子的习俗。结合餐厅平日就餐人数数据的评估，预计当天就餐人数将近1000人，按照人均30个饺子测算，职工餐厅至少保障在冬至之前，要加工出来不低于30000个纯手工水饺。</p>
            <p>时间紧、任务重！为确保就餐员工在冬至当天都能品尝到职工餐厅的纯手工水饺，职工餐厅提前一周对冬至的水饺保障进行了部署。从12月18日开始，职工餐厅全体员工在中午闭餐后都会加班2个小时，提前开始包饺子。当天包好的饺子，后厨师傅会将其迅速放入冰箱进行速冻，以保持饺子的新鲜。</p>
            <p>在馅料准备上，职工餐厅准备了猪肉茴香、韭菜鸡蛋，确保能满足大部分职工的需求。同时，为了尊重少数民族的饮食习惯，我们还特意为回族同事们准备了牛肉馅水饺。</p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a1.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a2.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="content">
            <p>冬至当天，职工餐厅还为部分爱好美食的职工们，准备了一场主题为【冬至“饺”好运】的水饺DIY活动。旨在让美食爱好者们零距离的参与到职工餐厅的美食制作过程中，体验自己动手包饺子的那份快乐。</p>
            <p>为了让活动现场更有节日氛围，职工餐厅在LED大屏上精心设计制作了活动背景，并插入了欢快的音乐，让大家不仅仅在味觉上有所体验，更让当天参加活动的职工们在视觉、听觉上有种沉浸式的体验。</p>
            <p>冬至当天，餐厅员工在早餐闭餐后，迅速布置活动场地，打扫卫生，清洁桌椅，铺上台布，并将准备好的面团、馅料、案板、擀面杖、筷子、托盘等井井有条的分为10组，方便参加活动的职工能迅速进入状态。同时，餐厅还为参加活动的美食家们准备好了一次性口罩、手套、头套、袖套和围裙，以确保制作水饺环节的食品安全。8点40分，所有工作准备就绪，只等待美食家们的到来。</p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a3.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a4.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a5.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a6.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="content">
            <p>上午9点左右，美食家们陆续到来，冬至的水饺DIY活动在轻松、欢快的氛围中拉开序幕。大家纷纷展示自己的包饺子技艺，各种形状和风格的饺子在大家的手中诞生。餐厅的面点师傅们也在活动现场，耐心讲解，亲自示范，让大家在提升技艺的同时也体验到了包饺子的乐趣，感受着冬至的温馨和团圆。</p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a7.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a8.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="content">
            <p>10点45分左右，所领导来到职工餐厅活动现场，和大家一起包饺子，共庆冬至。大家欢快的忙碌着，揉面团、擀面皮、包饺子，每个人脸上都洋溢着无比的幸福，活动现场欢声笑语、其乐融融。</p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a9.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a10.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a11.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="content">
            <p>活动现场的氛围温馨而有趣。桌子上摆满了一盘盘包好的饺子，有麦穗状的，有元宝状的，层层叠叠，美不胜收。从饺子皮的擀制到包饺子的技巧，每个步骤都成为了一个欢快的学习过程。每个人都在轻松的氛围中互相交流，分享制作的心得，享受这个轻松而又充满创意的过程。</p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a12.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a13.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a14.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="content">
            <p>临近活动结束，餐厅安排专人将大家亲手包制的欢乐水饺煮好，让大家能够在最佳状态品味到自己所包制的美味饺子。这样，不仅是为了保证饺子的烹饪质量，更是为了营造一种特别的体验，让大家能够亲自感受到他们努力付出的成果，也在不经意间为大家增添了一份共同奋斗的美好回忆。</p>
            <p>DIY水饺活动在11点15分左右完美收官。活动结束后，餐厅员工迅速对现场进行了整理，保证午餐的按时正常供应。</p>
            <p>为了提高冬至大家的用餐体验，餐厅还特意准备了各式小菜，包括风味面筋、爽口萝卜、菠菜木耳和炸辣椒，小菜的质地和口感也为整个用餐过程增添了层次感。考虑到南北方冬至的饮食差异化，我们还准备了汤圆。热气腾腾的清汤搭配着甜美的汤圆，为大家带来了一份意外的惊喜。</p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img2 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/713dz/a15.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="content">
            <p>在这个冬至，愿职工餐厅的努力能够为大家带来一份快乐与温暖，伴随着大家走过寒冷的冬日，为新的一年注入更多的激情与希望。</p>
            <p>感谢您一直以来对职工餐厅的支持与陪伴，让我们共同期待着更多美好时刻的到来。</p>
        </div>
    </div>
    </div>
  </template>
  
  <script>
 import { WOW } from 'wowjs'
export default {
    mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  },
}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: 20px 0px;
    position: relative;
    height: 70px;
}
.title1{
    font-size: .13rem;
}
.title2{
    font-size: 22px;
    position: absolute;
    top: 15px;
    left: 1000px;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .18rem;
}
/* 图片 */
.img-all{
    width: 850px;
    /* height: 9.3rem; */
    /* background-color: blue; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.img-all .img1{
    width: 850px;
    height: 450px;
    /* background-color: yellow; */
    overflow: hidden;
    margin: 10px 0;
}
.img-all .img2{
    width: 850px;
    height: 650px;
    /* background-color: yellow; */
    overflow: hidden;
    margin: 10px 0;
}
  </style>