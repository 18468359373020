<template>
  <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <div class="main1">
        <div class="content" style="padding-top: 20px;">
          <p>我们通过各种营养计算，来帮助客户更好的了解和管理他们的饮食营养摄入，让客户更加全面的了解自己的饮食情况，并采取相应的措施来改善自己的营养摄入。我们致力于为客户提供准确、实用的营养信息，以帮助他们实现健康的生活方式。</p>
        </div>
      </div>
      <div class="main1">
        <div class="title1">菜品营养</div>
          <div class="content">
              <p>详细列出了各种菜品的营养成分，包括能量、蛋白质、脂肪、维生素、纤维素等。客户可以通过查看菜品营养表来了解每一道菜提供的具体营养含量，从而做出更好的菜品选择。</p>
          </div>
          <div class="content1">
                <div class="img1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/yygl/a06.png" alt="">
                </div>
                <div class="img2 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/yygl/a02.png" alt="">
                </div>
                <div class="img3  wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/yygl/a03.png" alt="">
                </div>
                <div class="img4 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s">
                    <img src="../../assets/yygl/a04.png" alt="">
                </div>
                <div class="img5 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2.5s" data-wow-iteration="1s">
                    <img src="../../assets/yygl/a05.png" alt="">
                </div>
            </div>
      </div>
      <div class="main2">
          <div class="title1">营养管理</div>
          <div class="content">
              <p>结合智能化售餐设施，记录了每个人近期所摄入的各种营养元素的含量。客户可以通过小程序了解自己的饮食习惯是否平衡，并根据需要进行相应的调整。此外，还可以帮助客户追踪他们的营养摄入目标的实现情况，以便及时做出改变。</p>
          </div>
            <div class="content2">
                <div class="imga1 wow animate__animated animate__fadeInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b01.png" alt="">
                </div>
                <div class="imga1 wow animate__animated animate__fadeInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b02.png" alt="">
                </div>
                <div class="imga1 wow animate__animated animate__fadeInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b03.png" alt="">
                </div>
                <div class="img_box imga2 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b04.jpg" alt="">
                </div>
                <div class="img_box imga3 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2.5s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b08.png" alt="">
                </div>
                <div class="img_box imga4 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="3s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b06.png" alt="">
                </div>
                <div class="img_box imga5 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="3.5s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b10.png" alt="">
                </div>
                <div class="img_box imga6 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="4s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b11.png" alt="">
                </div>
                <div class="img_box imga7 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="4.5s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b09.png" alt="">
                </div>
                <div class="img_box imga8 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="5s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b07.png" alt="">
                </div>
                <div class="img_box imga9 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="5.5s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b05.png" alt="">
                </div>
                <div class="img_box imga10 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="6s" data-wow-iteration="1s">
                  <img src="../../assets/yygl/b12.png" alt="">
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  mounted() {
  //第一种写法，可以设置wow中属性
  this.$nextTick(() => { // 在dom渲染完后,再执行动画
  var wow = new WOW({
      boxClass: 'wow',///动画元件css类（默认为wow）
      animateClass: 'animated',//动画css类（默认为animated） 
      offset: 0,//到元素距离触发动画（当默认为0） 
      mobile: true, //在移动设备上触发动画（默认为true） 
      live: true//对异步加载的内容进行操作（默认为true）
  })
  wow.init()
  })
},
}
</script>

<style scoped>
.banner{
  width: 100%;
  height: 3rem;
  overflow: hidden;
}
.banner:hover img{
  transform: scale(1.2);
}
.banner img{
  transition: all .4s;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.main1,
.main2{
  width: 100%;
  background-color: #ededed;
  padding-bottom: 20px;
}
.main2{
  background-color: #fff;
}
.title1{
  font-size: .1rem;
  font-weight: 700;
  padding: 20px 0;
  /* padding-bottom: 20px; */
}
.content1{
  width: 70%;
  height: 1.4rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}
.content3{
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.content{
  width: 65%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .18rem;
}
.img1,.img2,.img3,.img4,.img5,.img6{
  position: absolute;
  width: 100%;
  height: 270px;
  /* background-color: pink;
  border: 1px solid black; */
}
.img2{
  top: .2rem;
  left: .2rem;
}
.img3{
  top: .4rem;
  left: .4rem;
}
.img4{
  top: .6rem;
  left: .6rem;
}
.img5{
  top: .8rem;
  left: .8rem;
}
.img6{
  top: 1rem;
  left: 1rem;
}
img{
  width: 100%;
  height: 100%;
}
.text1{
  width: 50%;
  /* height: 2.66rem; */
  /* background-color: yellow; */
}
.content2{
  width: 70%;
  height: 3.3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}
.imga1{
  width: 436px;
  height: 630px;
}
.img_box{
  width: 622px;
  height: 358px;
  position: absolute;
}
.imga2{
  transform: rotate(20deg);
}
.imga3{
  right: 0px;
  transform: rotate(-20deg);
}
.imga4{
  transform: rotate(10deg);
  left: .8rem;
}
.imga5{
  transform: rotate(-20deg);
  bottom: 0px;
  left: 0px;
}
.imga6{
  transform: rotate(10deg);
  bottom: 0px;
  right: .8rem;
}
.imga7{
  transform: rotate(-10deg);
  bottom: 0px;
  left: .8rem;
}
.imga8{
  transform: rotate(20deg);
  bottom: 0px;
  right: 0px;
}
.imga9{
  right: .8rem;
  top: 0rem;
  transform: rotate(-10deg);
}
.imga10{
  width: 810px;
  height: 469px;
  margin: 0 auto;
  top: .5rem;
  left: 1.3rem;
}
</style>