<template>
  <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <div class="main1">
          <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">实时的信息显示</div>
          <div class="content1">
              <div class="img_all img_a1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/a02.jpg" alt="">
              </div>
              <div class="img_all img_a2 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/a07.jpg" alt="">
              </div>
              <div class="img_all img_a5 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/a06.jpg" alt="">
              </div>
              <div class="img_all img_a6 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/a03.jpg" alt="">
              </div>
              <div class="img_all img_a7 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/a01.jpg" alt="">
              </div>
          </div>
      </div>
      <!-- 第二部分 -->
      <div class="main2">
          <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">精准的成本核算</div>
          <div class="content1">
              <div class="img_all img_b1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/b01.jpg" alt="">
              </div>
              <div class="img_all img_b2 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/b02.jpg" alt="">
              </div>
              <div class="img_all img_b3 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/b03.jpg" alt="">
              </div>
              <div class="img_all img_b4 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/b04.jpg" alt="">
              </div>
              <div class="img_all img_b5 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/b05.jpg" alt="">
              </div>
              <div class="img_all img_b6 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="3s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/b06.jpg" alt="">
              </div>
              <div class="img_all img_b7 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="3.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/b07.jpg" alt="">
              </div>
          </div>
      </div>
      <!-- 第三部分 -->
      <div class="main1">
          <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">严密的供应链管控</div>
          <div class="content1">
              <div class="img_all1 img_c1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/c02.jpg" alt="">
              </div>
              <div class="img_all1 img_c2 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/c05.jpg" alt="">
              </div>
              <div class="img_all1 img_c3 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/c01.jpg" alt="">
              </div>
              <div class="img_all1 img_c4 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/c06.jpg" alt="">
              </div>
              <div class="img_all1 img_c5 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2.5s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/c03.jpg" alt="">
              </div>
              <div class="img_all1 img_c6 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="3s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/c07.jpg" alt="">
              </div>
              <div class="img_all1 img_c8 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="4s" data-wow-iteration="1s">
                <img src="../../assets/xxhzc/c08.jpg" alt="">
              </div>
          </div>
      </div>
      <!-- 第四部分 -->
      <div class="main2">
        <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">透明的制餐过程</div>
        <div class="content1 content3">
          <div class="bg1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
            <img src="../../assets/xxhzc/e01.gif" alt="">
          </div>
        </div>
      </div>
      <!-- 第五部分 -->
      <div class="main1">
        <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">多渠道沟通客户</div>
        <div class="content1 content3">
          <div class="text">热线电话、公司网站、Email、微信群、公众号、小程序，实时接受客户反馈。</div>
          <div class="bg wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
            <div class="yuan01"></div>
            <div class="yuan02"></div>
            <div class="yuan03"></div>
            <div class="yuan04"></div>
            <div class="yuan05"></div>
            <div class="yuan06"></div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  mounted() {
  //第一种写法，可以设置wow中属性
  this.$nextTick(() => { // 在dom渲染完后,再执行动画
  var wow = new WOW({
      boxClass: 'wow',///动画元件css类（默认为wow）
      animateClass: 'animated',//动画css类（默认为animated） 
      offset: 0,//到元素距离触发动画（当默认为0） 
      mobile: true, //在移动设备上触发动画（默认为true） 
      live: true//对异步加载的内容进行操作（默认为true）
  })
  wow.init()
  })
},
}
</script>

<style scoped>
.banner{
  width: 100%;
  height: 3rem;
  overflow: hidden;
}
.banner:hover img{
  transform: scale(1.2);
}
.banner img{
  transition: all .4s;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.main1,
.main2{
  width: 100%;
  /* height: 3rem; */
  background-color: #ededed;
  /* margin: 20px auto; */
  padding-top: 20px;
  padding-bottom: 20px;
}
.title1{
  font-size: .1rem;
  font-weight: 700;
  padding: 20px 0;
}
.content1{
  width: 70%;
  height: 3.66rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  /* background-color: pink; */
}
img{
  width: 100%;
  height: 100%;
}
.img_all{
  width: 670px;
  height: 375px;
  position: absolute;
}
.img_a1{
  background-color: #fff;
}
.img_a2{
  right: 0;
  top: 0;
  background-color: blue;
}
.img_a5{
  left: 0;
  bottom: 0;
  background-color: yellow;
}
.img_a6{
  right: 0;
  bottom: 0;
  background-color: gray;
}
.img_a7{
  width: 865px;
  height: 479px;
  left: 1.3rem;
  top: .6rem;
  background-color: green;
}
/* 第二部分 */
.main2{
  background-color: #fff;
}
.img_b1{
  width: 581px;
  height:521px ;
  left: 2rem;
  background-color: blue;
}
.img_b2{
  top: 0;
  right: 0;
  width: 677px;
  height:431px ;
  background-color: blueviolet;
  transform: rotate(-20deg);
}
.img_b3{
  width: 677px;
  height:431px ;
  background-color: rgb(0, 255, 81);
  transform: rotate(20deg);
}
.img_b4{
  width: 677px;
  height:431px ;
  bottom: 0;
  left: 1.7rem;
  background-color: rgb(133, 201, 155);
}
.img_b5{
  width: 400px;
  height:573px ;
  bottom: -.5rem;
  left: -.2rem;
  background-color: rgb(111, 189, 38);
  transform: rotate(-20deg);
}
.img_b6{
  width: 400px;
  height:573px ;
  bottom: -.5rem;
  right: -.2rem;
  background-color: rgb(129, 189, 38);
  transform: rotate(20deg);
}
.img_b7{
  width: 1026px;
  height:574px ;
  background-color: black;
  left: .8rem;
  top: .3rem;
}
/* 第三部分 */
.img_all1{
  width: 670px;
  height: 375px;
  position: absolute;
}
.img_c1{
  background-color: blue;
  transform: rotate(-20deg);
}
.img_c2{
  top: 0;
  right: 0;
  background-color: rgb(0, 255, 128);
  transform: rotate(20deg);
}
.img_c3{
  bottom: 0;
  right: 0;
  background-color: rgb(255, 0, 170);
  transform: rotate(-20deg);
}
.img_c4{
  bottom: 0;
  left: 0;
  background-color: rgb(255, 0, 21);
  transform: rotate(20deg);
}
.img_c5{
  top: 0;
  left: -.5rem;
  background-color: rgb(208, 255, 0);
  transform: rotate(15deg);
}
.img_c6{
  top: 0;
  right: -.5rem;
  background-color: rgb(43, 0, 255);
  transform: rotate(-15deg);
}
/* .img_c7{
  bottom: 0;
  right: .5rem;
  background-color: rgb(0, 255, 136);
  transform: rotate(20deg);
} */
.img_c8{
  width: 944px;
  height: 535px;
  top: .4rem;
  left: 1rem;
  background-color: rgb(9, 255, 0);
}
/* 第四部分 */
.content3{
  height: 3.4rem;
}
.bg{
  width: 1286px;
  height: 647px;
  position: relative;
  background-image: url(../../assets//xxhzc/d01.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.bg1{
  width: 1286px;
  height: 647px;
  background-color: pink;
}
.text{
  width: 100%;
  padding-bottom: 20px;
  /* padding-top: 10px; */
  font-size: .09rem;
  text-align: center;
}
.yuan01{
  position: absolute;
  width: 125px;
  height: 125px;
  border:10px dashed #cfcccc;
  border-radius: 50%;
  top: .56rem;
  left: .22rem;
  animation: rotate 2s linear infinite; /* 添加动画 */
}
.yuan02{
  position: absolute;
  width: 125px;
  height: 125px;
  border:10px dashed #cfcccc;
  border-radius: 50%;
  top: .19rem;
  left: 1.59rem;
  animation: rotate 2s linear infinite; /* 添加动画 */
}
.yuan03{
  position: absolute;
  width: 190px;
  height: 190px;
  border:13px dashed #cfcccc;
  border-radius: 50%;
  top: .13rem;
  left: 3.9rem;
  animation: rotate 2s linear infinite; /* 添加动画 */
}
.yuan04{
  position: absolute;
  width: 120px;
  height: 120px;
  border:10px dashed #cfcccc;
  border-radius: 50%;
  top: 1.13rem;
  left: 5.23rem;
  animation: rotate 2s linear infinite; /* 添加动画 */
}
.yuan05{
  position: absolute;
  width: 120px;
  height: 120px;
  border:10px dashed #cfcccc;
  border-radius: 50%;
  top: 2.56rem;
  left: 4.87rem;
  animation: rotate 2s linear infinite; /* 添加动画 */
}
.yuan06{
  position: absolute;
  width: 210px;
  height: 210px;
  border:15px dashed #cfcccc;
  border-radius: 50%;
  top: 2.1rem;
  left: 1.12rem;
  animation: rotate 2s linear infinite; /* 添加动画 */
}
@keyframes rotate {
  from {
    transform: rotate(0deg); /* 初始角度为0度 */
  }
  to {
    transform: rotate(360deg); /* 旋转到360度 */
  }
}
</style>