<template>
  <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <div class="main1">
          <div class="title1">PDCA循环</div>
          <div class="content">
              <p>PDCA循环是美国质量管理专家沃特·阿曼德·休哈特（Walter A. Shewhart）首先提出的，由戴明采纳、宣传，获得普及，所以又称为戴明循环。PDCA循环的含义是将管理工作分为四个阶段——计划（Plan）、执行（Do）、检查（Check）和处理（Action）。在管理活动中，依次循环进行各个阶段：（1）作出计划；（2）计划实施；（3）检查实施效果；（4）将成功的经验纳入标准，对不成功的问题留待下一循环去解决。这一工作方法既是质量管理的基本方法，也是企业管理各项工作的一般规律。</p>
          </div>
          <div class="pdca-img">
            <div class="first"></div>
            <div class="secend"></div>
            <div class="third"></div>
            <div class="fourth">
              <div class="circle1 img">
                <img src="../../assets/pdca/a01.png" alt="">
              </div>
              <div class="circle2 img">
                <img src="../../assets/pdca/a01.png" alt="">
              </div>
              <div class="circle3 img">
                <img src="../../assets/pdca/a01.png" alt="">
              </div>
            </div>
          </div>
      </div>
      <div class="main2">
            <div class="title1">计划（Plan）</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/pdca/b01.png" alt="">
                </div>
                <div class="img1 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/pdca/b02.png" alt="">
                </div>
            </div>
        </div>
        <div class="main1">
            <div class="title1">执行（Do）</div>
            <div class="content1">
                <div class="img2 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/pdca/c01.png" alt="">
                </div>
                <div class="img2 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/pdca/c02.png" alt="">
                </div>
                <div class="img2 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/pdca/c03.png" alt="">
                </div>
                <div class="img2 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/pdca/c04.png" alt="">
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="title1">检查（Check）</div>
            <div class="content3">
              <div class="img3 wow animate__animated animate__bounceInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                  <img src="../../assets/aqgl/c02.jpg" alt="">
              </div>
              <div class="img3 wow animate__animated animate__bounceInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                  <img src="../../assets/aqgl/c03.jpg" alt="">
              </div>
              <div class="img3 wow animate__animated animate__bounceInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                  <img src="../../assets/aqgl/c04.jpg" alt="">
              </div>
            </div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/pdca/d01.png" alt="">
                </div>
                <div class="img1 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/pdca/d02.png" alt="">
                </div>
            </div>
        </div>
        <div class="main1">
            <div class="title1">处理（Action）</div>
            <div class="content1">
                <div class="img2 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/pdca/e02.png" alt="">
                </div>
                <div class="img2 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/pdca/e01.jpg" alt="">
                </div>
                <div class="img2 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/pdca/e03.jpg" alt="">
                </div>
                <div class="img2 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/pdca/e04.jpg" alt="">
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  mounted() {
  //第一种写法，可以设置wow中属性
  this.$nextTick(() => { // 在dom渲染完后,再执行动画
  var wow = new WOW({
      boxClass: 'wow',///动画元件css类（默认为wow）
      animateClass: 'animated',//动画css类（默认为animated） 
      offset: 0,//到元素距离触发动画（当默认为0） 
      mobile: true, //在移动设备上触发动画（默认为true） 
      live: true//对异步加载的内容进行操作（默认为true）
  })
  wow.init()
  })
},
}
</script>

<style scoped>
.banner{
  width: 100%;
  height: 3rem;
  overflow: hidden;
}
.banner:hover img{
  transform: scale(1.2);
}
.banner img{
  transition: all .4s;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.main1,
.main2{
  width: 100%;
  background-color: #ededed;
  padding-bottom: 20px;
}
.main2{
  background-color: #fff;
}
.title1{
  font-size: .1rem;
  font-weight: 700;
  padding: 20px 0;
}
.content1{
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.content3{
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.img3{
    width: 30%;
    height: 2.8rem;
    background-color: pink;
}
.content{
  width: 70%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .18rem;
}
.img1,.img2{
  width: 50%;
  height: 375px;
}
img{
  width: 100%;
  height: 100%;
}
.text1{
  width: 50%;
  /* height: 2.66rem; */
  /* background-color: yellow; */
}
/* pdca循环动画 */
.pdca-img{
  width: 990px;
  height: 3rem;
  margin: 0 auto;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.first{
  width: 1.3rem;
  height: 0.6rem;
  position: absolute;
  left: 3.9rem;
  top: 0.9rem;
  /* background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%); */
  background-color: #264a8b;
}
.secend{
  width: 2.6rem;
  height: 0.6rem;
  position: absolute;
  left: 2.6rem;
  top: 1.4rem;
  /* background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%); */
  background-color: #264a8b;
}
.third{
  width: 3.9rem;
  height: 0.6rem;
  position: absolute;
  left: 1.3rem;
  top: 1.9rem;
  /* background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%); */
  background-color: #264a8b;
}
.fourth{
  width: 5.2rem;
  height: 0.6rem;
  position: absolute;
  left: 0rem;
  top: 2.4rem;
  /* background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%); */
  background-color: #264a8b;
}
.circle1{
  width: 170px;
  height: 170px;
  position: absolute;
  top: -.9rem;
  left: -1.4rem;
  transform-origin: center;
  animation: rotate 9s linear infinite;
}
.circle2{
  width: 170px;
  height: 170px;
  position: absolute;
  top: -.9rem;
  left: -1.4rem;
  transform-origin: center;
  animation: rotate 9s linear infinite;
  animation-delay: 3s;
}
.circle3{
  width: 170px;
  height: 170px;
  position: absolute;
  top: -.9rem;
  left:-1.4rem;
  transform-origin: center;
  animation: rotate 9s linear infinite;
  animation-delay: 6s;
}
/* 使用@keyframes 定义一个名为rotate的关键帧 */
@keyframes rotate{
  0%{
    transform:  translate(0,0) rotate(0deg);
  }
  20%{
    transform:  translate(1.55rem,0rem) rotate(360deg);
  }
  40%{
    transform:  translate(2.8rem,-0.5rem) rotate(720deg);
  }
  60%{
    transform:  translate(4rem,-1rem) rotate(1080deg);
  }
  80%{
    transform:  translate(5.6rem,-1.5rem) rotate(1440deg);
  }
  100%{
    transform:  translate(7.2rem,-1.5rem) rotate(1800deg);
  }
}
  /* 将关键帧运用在图片上 */
/* .img{
  transform-origin: center;
  animation: rotate 6s linear infinite;
} */
</style>