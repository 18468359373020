<template>

  <div>
    <div class="banner">
        <img src="../../assets/goyb.jpg" alt="">
    </div>
    <!-- 上盒子 -->
    <!-- <div class="yy-box1">
        <ul>
          <li><router-link to="/case/CaseSix">国机集团智能制造高峰论坛</router-link></li>
          <li><router-link to="/case/CaseZJLY70">中机六院成立70周年</router-link></li>
          <li><router-link to="/case/Case713">客户公司成立60周年</router-link></li>
          <li><router-link to="/case/CaseDIY">中秋月饼DIY</router-link></li>
          <li><router-link to="/case/Case38">女神节的祝福</router-link></li>
        </ul>
    </div> -->
    <h2 class="title">恒天重工双节问候</h2>
  <hr>
  <div class="main">
      <div class="content">
          <p>为了感谢恒天重工员工的支持和厚爱，今年恰逢国庆中秋双喜临门，翼博餐饮恒天重工项目部为当天来餐厅就餐的每位客人准备了一个问候月饼礼袋——月饼、可乐、国旗，略表我们对客人的节日祝福和对祖国繁荣富强的祝愿。</p>
      </div>
      <div class="img">
          <div class="img-all">
              <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                  <img src="../../assets/htsj/04.jpg" alt="">
              </div>
              <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                  <img src="../../assets/htsj/03.jpg" alt="">
              </div>
              <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                  <img src="../../assets/htsj/02.jpg" alt="">
              </div>
              <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                  <img src="../../assets/htsj/01.jpg" alt="">
              </div>
          </div>
      </div>
  </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  mounted() {
  //第一种写法，可以设置wow中属性
  this.$nextTick(() => { // 在dom渲染完后,再执行动画
    var wow = new WOW({
      boxClass: 'wow',///动画元件css类（默认为wow）
      animateClass: 'animated',//动画css类（默认为animated） 
      offset: 0,//到元素距离触发动画（当默认为0） 
      mobile: true, //在移动设备上触发动画（默认为true） 
      live: true//对异步加载的内容进行操作（默认为true）
    })
    wow.init()
  })
},
}
</script>

<style scoped>
.banner{
    width: 100%;
    height: 3rem;
    overflow: hidden;
}
.banner:hover img{
    transform: scale(1.2);
}
.banner img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.yy-box1{
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f6f6f6;
}

/* 标题 */
.title{
  margin: .2rem 0;
  font-size: .13rem;
}
hr{
  width: 60%;
  margin: 0 auto;
}
.main{
  width: 60%;
  /* background-color: pink; */
  margin: .1rem auto;
}
.content p{
  text-indent:2em;
  text-align: left;
  font-size: .1rem;
  line-height: .18rem;
}
/* 图片 */
.img-all{
  width: 850px;
  /* height: 9.3rem; */
  /* background-color: blue; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.img-all .img1{
  width: 850px;
  height: 450px;
  overflow: hidden;
  margin: 10px 0;
}
/* .img-all .img3{
  width: 850px;
  height:300px;
  overflow: hidden;
} */
.img-all .img-ji{
  width: 850px;
  height: 473px;
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

.img-all .img1:hover img{
  transform: scale(1.2);
}
.img-all img{
  transition: all .4s;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
</style>