<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">翼博餐饮第二项目部半年工作总结</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>10月22日，翼博餐饮第二项目部半年工作总结大会圆满落幕。</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d1.jpg" alt=""></div>
            <p>此次大会由翼博餐饮副总经理杨天龙主持。杨总和全体员工一起对近半年来每个月的工作部署、工作重心、实际完成情况等逐月做了详细的回顾和心得分享。</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d2.jpg" alt=""></div>
            <p>4月份，翼博餐饮进驻本项目职工餐厅不足一个月的情况下，圆满承办了甲方六十周年生日庆典，展示了公司应急统筹能力、协调组织能力和高效执行力。同时，早会制度于当月启动，通过每日早会总结前一日问题、部署当天重点工作，每项工作闭环管理；明确全体员工每日工作目标，提高了工作效率。5月份，公司着手严抓本项目部6S管理落地情况，每日输出6S闭餐现场汇报，重点关注食品安全管控、原材料存放规范、现场环境卫生状况和各种标准化执行情况。6月份，公司对该项目执行轮值经理制度，通过各模块班组长的轮值，促使各班组长站在公司立场和甲方就餐员工的角度去发现问题解决问题，多角度提升就餐体验。</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d3.jpg" alt=""></div>
            <p>7月份，第二项目部周例会制度及项目夜间巡检制度常态化。通过周例会，同步甲方反馈问题点、解决班组现场问题、研究菜谱更新等具体事宜，复盘工作、制定改善计划。夜间巡检的常态化则进一步加强了食品安全、生产安全的监管力度。8月份，翼博餐饮启动了项目经理培养计划，以第二项目部这个平台，挖掘并培养踏实、负责、有目标、懂创新的潜在人才着重培养人才；通过工作经验的积累和分享，提升员工的专业技能与综合实力，打造每个岗位的核心竞争力。9月份，系统研发团队入驻第二项目部，开始搭建第二项目部管理系统。通过在管理过程中引入信息系统，提高公司的管理效率与决策能力。同时，菜品研发的筹备工作也同步展开，为公司的菜品创新与品质提升打下了坚实的基础。</p>
            <p>杨总还结合第二、第三季度连续6个月甲方员工满意度调查结果，对“菜品质量评分、菜品份量评分、服务态度评分、卫生状况评分”的得分情况做了客观的点评。既肯定了近半年来团队取得的优异成绩，也剖析了运营过程中的短板。</p>
            <p>大家看到员工满意度调查走势不断走高时，都无比的欣慰。好的结果，离不开大家对甲方员工就餐体验的敏感度和反应速度。</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d4.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new4-d5.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new4-d6.jpg" alt=""></div>
            <p>会议期间，杨总向大家分享甲方员工对餐厅菜品赞不绝口的视频截图时，全体员工都无比动容，激动不已！大家表示金杯银杯不如甲方员工的口碑，金奖银奖不如甲方员工的夸奖。</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d7.jpg" alt=""></div>
            <p>杨总还分享了几张日常工作中抓拍的现场照片，让大家通过大屏幕“仰视”一下自己，欣赏一下自己努力工作时的飒爽英姿。</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d8.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new4-d9.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new4-d10.jpg" alt=""></div>
            <p>翼博餐饮第二项目部的每一点进步，离不开甲方全体员工对我们的支持、理解和包容。也离不开该项目团队的上下同欲、群策群力、相互学习、团结一心的奋斗精神，他们用实际行动诠释了翼博餐饮的企业精神“专心、专注、专业”。</p>
            <p>此次会议，翼博餐饮还对第二项目部近半年运营过程中表现突出的班组及个人进行了表彰和相关人事任命，同时颁发了总经理杨天义签发的荣誉证书。杨总在现场向获奖员工表示祝贺，对他们后期的工作寄予了更大的希望，同时鼓励全员以“优秀”为榜样，让“优秀”成为一种习惯。对于新任命的干部，杨总强调了他们在管理岗位上的责任，并明确管理方向和管理目标，他期待这些干部能够在新的岗位上发挥更大的能量，取得更辉煌的成绩。</p>
            <p class="a1">优秀班组奖</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d11.jpg" alt=""></div>
            <p class="a1">优秀员工奖</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d12.jpg" alt=""></div>
            <p class="a1">优秀员工提名奖</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d13.jpg" alt=""></div>
            <p class="a1">老当益壮奖</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d14.jpg" alt=""></div>
            <p class="a1">翼博鲁班奖</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d15.jpg" alt=""></div>
            <p class="a1">最佳导师奖</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d16.jpg" alt=""></div>
            <p class="a1">千里马奖</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d17.jpg" alt=""></div>
            <p class="a1">铿锵玫瑰奖</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d18.jpg" alt=""></div>
            <p class="a1">杰出奉献奖</p>
            <div class="new_img1"><img src="../../assets/goyb/new4-d19.jpg" alt=""></div>
            <p>会议最后，杨总向全员现场传达了甲方保障部领导对我们未来工作的期望和下一步重点工作部署，同时对项目全体员工近半年的努力表示感谢，期待我们在接下来的工作中，保持团结合作、持续创新，共同书写翼博餐饮美好未来的新篇章！</p>
        </div>
        <hr>
        <div class="new_next">
            <!-- <div class="new_right">上一篇:</div> -->
            <router-link to="/honor/newThree">
                <div class="new_right">上一篇:“食”刻警惕，以“演”筑防</div>
            </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    color: red;
}
.new_img1{
    width: 800px;
    height: 400px;
    margin: 5px auto;
}
/* 翻篇 */
.new_next{
    width: 100%;
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
  </style>