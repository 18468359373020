<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">翼博餐饮成功中标郑州海为酒店餐厅服务商，携手共创餐饮新篇章</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>近日，知名餐饮服务公司翼博餐饮宣布，公司已成功中标，成为郑州海为酒店餐厅的服务商，这标志着双方将携手开启一段全新的合作旅程。据悉，翼博餐饮将于2024年7月23日正式入驻位于郑州市二七区的郑州海为酒店，为这家四星级商务型酒店提供高品质的餐饮服务。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-j1.png" alt=""></div>
            <p>海为酒店是一家综合性中高端酒店，其餐饮部分主打豫菜，并结合市场流行菜系，打造独特的融合餐厅。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-j2.png" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-j7.png" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-j8.png" alt=""></div>
            <p>酒店一楼设有一个500平方米的多功能厅，主要用于婚宴、会议用餐，可同时接待30-32桌宾客；二楼是350平方米的自助餐厅，可同时容纳120人就餐，提供客房配套早餐；三楼设有15个餐厅包间，总面积600平方米，可同时容纳146人就餐，后厨及操作间面积达260平方米。酒店全年不间断营业，后厨负责供应早、中、晚餐，包括但不限于自助早餐（含明档）、自助正餐（午、晚自助餐）、零点、各类宴席包桌（如婚宴、满月宴、团体包席等）、盒饭、面点等。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-j6.png" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-j4.png" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-j5.png" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-j9.png" alt=""></div>
            <p>作为业内的佼佼者，翼博餐饮长期以来致力于提供高标准、个性化的餐饮服务解决方案，赢得了众多客户的信赖与好评。公司表示，将充分利用自身在餐饮管理、菜品研发、服务创新等方面的优势，为郑州海为酒店的宾客带来更加丰富多彩的餐饮体验。同时，翼博餐饮也将积极响应市场需求，不断创新与改进，确保服务品质始终走在行业前列。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-j3.png" alt=""></div>
            <p>对于此次合作，双方均表现出高度的期待与信心。翼博餐饮表示非常珍视与郑州海为酒店的合作机会，将全力以赴，以最佳状态迎接每一位宾客的到来，共同书写餐饮服务的新篇章。双方期待通过此次合作，能够共同创造新的商业辉煌，实现双赢甚至多赢的局面。翼博餐饮也将秉持高质量的餐饮服务，并持续创新和改进，以满足顾客日益增长的需求和期望。</p>
        </div>
        <hr>
        <div class="new_next">
        <router-link to="/honor/new5">
            <div class="new_right">上一篇:翼博餐饮集成信息化系统盛大上线</div>
        </router-link>
        <router-link to="/honor/new4">
            <div class="new_right">下一篇:翼博餐饮成功注册“翼帆食刻”商标，开启品牌发展新篇章</div>
        </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    font-weight: 700;
}
.new_img1{
    width: 800px;
    height: 400px;
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    /* height: 2rem; */
    /* margin-top: .2rem; */
    /* background-color: pink; */
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
  </style>