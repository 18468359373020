<template>
    <div>
        <div class="banner">
            <img src="../../assets/goyb.jpg" alt="">
        </div>
        <!-- 标题 -->
        <div class="title">
            团队简介
        </div>
        <hr class="title-hr">
        <div class="main_boss">
            <div class="boss">
                <img src="../../assets/tdjj/01.png" alt="">
            </div>
            <div class="text">
                <h2 class="text-all" style="font-size: .14rem;">
                    杨天义
                </h2>
                <h2 class="text-all" style="font-size: .1rem;">
                    翼博餐饮总经理
                </h2>
                <p class="text-all">高级餐饮职业经理人</p>
                <p class="text-all">食品安全管理师</p>
                <p class="text-all">健康管理师</p>
            </div>
        </div>
        <div class="main_boss_s">
            <div class="boss_left">
                <div class="boss_left_img">
                    <img src="../../assets/tdjj/02.png" alt="">
                </div>
                <div class="text_left">
                    <h2 class="text-all" style="font-size: .14rem;">
                        杨天龙
                    </h2>
                    <h2 class="text-all" style="font-size: .1rem;">
                        翼博餐饮副总经理
                    </h2>
                    <h2 class="text-all" style="font-size: .1rem;">
                        财务中心总监
                    </h2>
                    <h2 class="text-all" style="font-size: .1rem;">
                        产品研发中心总监
                    </h2>
                    <p class="text-all">人力资源管理专业</p>
                    <p class="text-all">高级餐饮职业经理人</p>
                    <p class="text-all">高级物流师</p>
                </div>
            </div>
            <div class="boss_right">
                <div class="boss_left_img">
                    <img src="../../assets/tdjj/03.png" alt="">
                </div>
                <div class="text_left">
                    <h2 class="text-all" style="font-size: .14rem;">
                        武杨
                    </h2>
                    <h2 class="text-all" style="font-size: .1rem;">
                        翼博餐饮副总经理
                    </h2>
                    <h2 class="text-all" style="font-size: .1rem;">
                        供应链管理中心总监
                    </h2>
                    <p class="text-all">高级餐饮职业经理人</p>
                    <p class="text-all">食品安全管理师</p>
                </div>
            </div>
        </div>
        <div class="title1">
            明星项目经理
        </div>
        <div class="main1">
            <div class="view view-sixth">
                <img src="../../assets/tdjj/06.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮副总经理
                    </h2>
                    <h2 class="text-all" style="margin-top: -.1rem;">
                        兼项目经理
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        武姚
                    </h2>
                    <p>高级餐饮职业经理人</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/08.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮副总经理
                    </h2>
                    <h2 class="text-all" style="margin-top: -.1rem;">
                        兼项目经理
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        魏长江
                    </h2>
                    <p>高级餐饮职业经理人</p>
                    <p>食品安全管理师</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/12.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮明星项目经理
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        王永闯
                    </h2>
                    <p>高级餐饮职业经理人</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/13.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮明星项目经理
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        吕胜利
                    </h2>
                    <p>高级餐饮职业经理人</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/15.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮明星项目经理
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        王西杰
                    </h2>
                    <p>二级中式烹饪师</p>
                </div>
            </div>
        </div>
        <div class="title1">
            明星厨师
        </div>
        <div class="main1">
            <div class="view view-sixth">
                <img src="../../assets/tdjj/09.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .8rem;">
                        翼博餐饮行政总厨
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        菜品研发中心总监
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        吴付德
                    </h2>
                    <p>河南豫菜大师</p>
                    <p>国家一级厨师</p>
                    <p>中国青年御膳大师</p>
                    <p>国家高级公共营养师</p>
                    <p>中国饭店协会名厨委员会委员</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/10.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮团餐厨师长
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        郑增辉
                    </h2>
                    <p>河南团餐名厨</p>
                    <p>三级中式烹调师</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/11.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮明星厨师
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        张文举
                    </h2>
                    <p>河南烹饪名师</p>
                    <p>国家高级技师</p>
                    <p>国家高级公共营养师</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/19.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮明星厨师
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        李昌成
                    </h2>
                    <p>菜品研发中心面点教练</p>
                    <p>第二项目部面点主厨</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/18.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮明星厨师
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        陈强
                    </h2>
                    <p>菜品研发中心面食教练</p>
                    <p>第二项目部面食主厨</p>
                </div>
            </div>
        </div>
        <div class="title1">
            管理支撑团队
        </div>
        <div class="main1">
            <div class="view view-sixth">
                <img src="../../assets/tdjj/04.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮人力部负责人
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        梁玉灵
                    </h2>
                    <p>高级人力资源管理师</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/05.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮财务部负责人
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        牛玉珂
                    </h2>
                    <p>财务管理专业</p>
                    <p>管理学学士</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/14.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮项目会计
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        封秋侠
                    </h2>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/16.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮产品研发中心
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        前端工程师
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        孙帅奇
                    </h2>
                    <p>工科学士</p>
                </div>
            </div>
            <div class="view view-sixth">
                <img src="../../assets/tdjj/17.png" alt="">
                <div class="mask">
                    <h2 style="margin-top: .9rem;">
                        翼博餐饮产品研发中心
                    </h2><h2 style="margin-top: -.1rem;">
                        后端工程师
                    </h2>
                    <h2 style="margin-top: -.1rem;">
                        耿亚乐
                    </h2>
                    <p>工科学士</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
    mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  },
}
</script>

<style scoped>
.banner{
    width: 100%;
    height: 3rem;
    overflow: hidden;
}
.banner:hover img{
    transform: scale(1.2);
}
.banner img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.title{
    font-size: .13rem;
    font-weight: 700;
    padding: .15rem 0;
}
.title-hr{
    width: 70%;
    margin: 0 auto;
}
.main{
    width: 70%;
    /* height: 7.4rem; */
    height: 5.55rem;
    /* background-color: pink; */
    margin: .1rem auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
}
.title1{
    width: 70%;
    height: .2rem;
    line-height: .2rem;
    margin: 0 auto;
    font-size: .09rem;
    font-weight: 700;
}
.main1{
    width: 70%;
    height: 345px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.view{
    width: 1.35rem;
    height: 1.8rem;
    background-color: #666;
    overflow: hidden;
    position: relative;
    text-align: center;
    /* box-shadow: 1px 1px 2px #e6e6e6; */
    cursor: default;
}
.view img{
    transition: all 0.2s ease-in;
    display: block;
    position: relative;
}
.view .mask,.view .content{
    width: 1.35rem;
    height: 1.8rem;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transform: translate();
}
.view h2{
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 17px;
    padding: 10px 4px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0;
}
.view p{
    /* font-style: italic; */
    font-size: .07rem;
    position: relative;
    color: #fff;
    /* padding: 10px 20px 20px; */
    text-align: center;
}
.view-sixth img{
    transition: all 0.4s ease-in-out 0.5s;
    /* opacity: 1; */
}
.view-sixth .mask{
    background-color: rgba(0,0,0, 0.5);
    opacity: 0;
    transition: all 0.3s ease-in 0.4s;
}
.view-sixth h2{
    opacity: 0;
    background: transparent;
    margin: 20px 40px 0px 40px;
    transform: scale(10);
    transition: all 0.3s ease-in-out 0.1s;
}
.view-sixth p{
    opacity: 0;
    transform: scale(10);
    transition: all 0.3s ease-in-out 0.2s;
}
.view-sixth:hover .mask{
    opacity: 1;
    transition-delay: 0s;
}
.view-sixth:hover img{
    transition-delay: 0s;
}
.view-sixth:hover h2{
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.1s;
}
.view-sixth:hover p{
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.2s;
}
/* boss */
.main_boss{
    width: 70%;
    height: 3.14rem;
    background-color: #666666;
    margin: 0 auto;
    margin-top: 20px;
    position: relative ;
}
.boss{
    position: absolute;
    width: 453px;
    height: 600px;
    left: 1.7rem;
    /* background-color: blue; */
}
.text{
    /* background-color: yellow; */
    position: absolute;
    text-align: left;
    bottom: .02rem;
    left: 4.06rem;
}
.text-all{
    padding: 5px 5px;
    font-size: .08rem;
    color: white;
}
/* 副总经理 */
.main_boss_s{
    width: 70%;
    height: 2.46rem;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.boss_left{
    width: 49.2%;
    height: 2.46rem;
    background-color: #666666;
    position: relative;
}
.boss_right{
    width: 49.2%;
    height: 2.46rem;
    background-color: #666666;
    position: relative;
}
.boss_left_img{
    position: absolute;
    width: 350px;
    height: 467px;
    left: .2rem;
    top: 3px;
}
.text_left{
    position: absolute;
    bottom: 0;
    left: 2rem;
    text-align: left;
}
</style>