<template>
  <div class="di" style="width: 100%;">
    <!-- 日期 -->
    <MyDate></MyDate>


    <!-- form表单添加 -->
    <div class="an">
      <el-button type="primary" @click="dialogVisible = true">新增</el-button>

      <MySearch></MySearch>
      <!-- <div style="display: inline-block"> 搜索：</div>
      <el-input v-model="search" style="display: inline-block;width: 1300px" placeholder="请输入搜索内容"></el-input> -->
      <!-- <el-button @click="tables">搜索</el-button> -->

          <el-dialog title="新增数据" :visible.sync="dialogVisible" width="50%" @close="onDialogClosed">
      <el-form ref="form" :model="addForm" label-width="100px" :rules="formRules">
        <el-row>
        <el-col :span="12">
        <el-form-item label="编号" prop="id">
          <el-input v-model="addForm.id"></el-input>
        </el-form-item>
      </el-col>
   
      <el-col :span="12">
        <el-form-item label="名称" prop="mcateId">
          <el-input v-model="addForm.mcateId"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
        <el-form-item label="批次号" prop="code">
          <el-input v-model.number="addForm.code"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="标准" prop="brand">
          <el-input v-model="addForm.brand"></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="standard">
          <el-input v-model="addForm.standard"></el-input>
        </el-form-item>

        
        <el-form-item label="规格容量" prop="unit">
          <el-input v-model="addForm.pch"></el-input>
        </el-form-item>
        <el-form-item label="入库价格" prop="type">
          <el-input v-model="addForm.rksl"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="lowerLimit">
          <el-input v-model="addForm.jg"></el-input>
        </el-form-item>
        <el-form-item label="项目" prop="upperLimit">
          <el-input v-model="addForm.gid"></el-input>
        </el-form-item>
        <el-form-item label="失效期" prop="date">
          <el-input v-model="addForm.kcqw"></el-input>
        </el-form-item>
        <el-form-item label="供应商编码   " prop="cgy">
          <el-input v-model="addForm.cgy"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddNewUser">确 定</el-button>
      </span>
    </el-dialog>
    </div>




      <!-- 修改用户的对话框 -->
      <el-dialog title="修改数据" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
      <el-form ref="editFormRef" :model="editForm" label-width="100px" :rules="editFormRules">
        <el-row>
        <el-col :span="12">
        <el-form-item label="编号" prop="id">
          <el-input v-model="editForm.id"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="名称" prop="mcateId">
          <el-input v-model="editForm.mcateId"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
        <el-form-item label="批次号" prop="code">
          <el-input v-model.number="editForm.code"></el-input>
        </el-form-item>
        <el-form-item label="品牌" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="标准" prop="brand">
          <el-input v-model="editForm.brand"></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="standard">
          <el-input v-model="editForm.standard"></el-input>
        </el-form-item>
        <el-form-item label="规格容量" prop="unit">
          <el-input v-model="editForm.pch"></el-input>
        </el-form-item>
        <el-form-item label="入库价格" prop="type">
          <el-input v-model="editForm.rksl"></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="lowerLimit">
          <el-input v-model="editForm.jg"></el-input>
        </el-form-item>
        <el-form-item label="项目" prop="upperLimit">
          <el-input v-model="editForm.gid"></el-input>
        </el-form-item>
        <el-form-item label="失效期" prop="date">
          <el-input v-model="editForm.kcqw"></el-input>
        </el-form-item>
        <el-form-item label="供应商编码   " prop="cgy">
          <el-input v-model="editForm.cgy"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUserInfo">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 列表显示 -->
  <el-table :data="tables" border style="width: 100%">
    
    <el-table-column prop="id" label="编号" width="80"></el-table-column>

    <el-table-column prop="mcateId" label="名称" width="100"></el-table-column>

    <el-table-column prop="code" label="批次号" width="100"></el-table-column>

    <el-table-column prop="name" label="品牌" width="100"></el-table-column>

    <el-table-column prop="brand" label="标准" width="100"></el-table-column>

    <el-table-column prop="standard" label="计量单位" width="80"></el-table-column>

    <el-table-column prop="unit" label="规格容量" width="100"></el-table-column>

    <el-table-column prop="type" label="入库价格" width="100"></el-table-column>

    <el-table-column prop="lowerLimit" label="数量" width="100"></el-table-column>

    <el-table-column prop="upperLimit" label="项目" width="120"></el-table-column>

    <el-table-column prop="date" label="失效期" width="250"></el-table-column>

    <el-table-column prop="gid" label="供应商编码" width="100"></el-table-column>

    <el-table-column prop="kcqw" label="库存区位" width="120"></el-table-column>

    <el-table-column prop="cgy" label="采购员" width="100"></el-table-column>

    <el-table-column fixed="right" label="操作" width="120">
      

        <template #default="scope">
          <el-button type="text" size="small" @click="showEditDialog(scope.row.id)">修改</el-button>

          <el-button @click.prevent="onRemove(scope.row.id)" type="text" size="small">移除</el-button>
        </template>
        </el-table-column>
  </el-table>


  <MyPager></MyPager>
</div>
</template>

<script>
import userApi from '@/api/user'
import MyDate from '../MyDate.vue'
import MyPager from '../MyPager.vue'
import MySearch from '../MySearch.vue'

  export default {
    components: { MyDate , MyPager , MySearch},

    data() {
      // 自定义验证规则对象
      // 声明校验批次号的函数
      let checkCode = (rule, value, cb) => {
        if(!Number.isInteger(value)) {
          return cb(new Error('请填写整数！'))
        }
        if(value > 100 || value < 1) {
          return cb(new Error('必须在1 到 100 之间'))
        }
        cb()
      }

      return {

        searchModel:{
          id:1,
        },

        //列表数据显示
        tables:[],


        // 查询到的用户信息对象
      editForm: {},
    editDialogVisible:false, //隐藏修改表单
     // 修改表单的验证规则对象
     editFormRules: {
      mcateId: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          // { validator: checkEmail, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入批次号', trigger: 'blur' },
          // { validator: checkMobile, trigger: 'blur' }
        ]
      },



        dialogVisible:false, //隐藏添加表单

        addForm: {
          id: '',
          mcateId: '',
          code: '',
          jg: '',
          gid: '',
          kcqw: '',
          cgy: '',
          index: 0
        },


        // 表单的验证规则对象
        formRules: {
          id: [
            { required: true, message: '编号是必填项', trigger: 'blur' },
            { min: 1, max: 5, message: '长度在 1 到 5 个字符', trigger: 'blur' }
          ],
          mcateId: [
          { required: true, message: '名称是必填项', trigger: 'blur' },
          ],
          code: [
            { required: true, message: '批次号是必填项', trigger: 'blur' }, 
            {validator: checkCode, trigger: 'blur'},
          ],
        },

        // 搜索
        // search: '',

        // onInputChange(){},
        // input:'',
        tableData: [
        //   {
        //   id: '1',
        //   date: '图书馆',
        //   name: '111',
        //   pch: '1111',
        //   // address: '河南省郑州市中原区中原西路高庄',
        //   pp: '1111埃杜阿多11',
        //   bz: '111',
        //   jldw: '111',
        //   ggrl: '111',
        //   rkjg: '111',
        //   sl: '11',
        //   xm: '11',
        //   gid: '11',
        //   kcqw: '11',
        //   cgy: '11',
        // },

       ],
      }
    },

    created() {

    this.getUserList();
  },
  
  methods: {
    // 修改
        // 展示编辑用户的对话框
    //     async showEditDialog(id) {
    //   console.log(id)
    // },
    // 展示编辑用户的对话框
    async showEditDialog(id) {
      // console.log(id)
      const {data: res} = await this.axios.get('/material/' + id)
      if (res.code !== 200) {
        return this.$message.error('查询用户信息失败！')
      }
      this.editForm = res.data
      this.editDialogVisible = true
    },

    // 监听修改用户对话框的关闭
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },

    // 修改用户信息并且提交
    editUserInfo () {
      this.$refs.editFormRef.validate(async valid => {
        console.log(valid)
        if (!valid) return
        // 发起修改用户信息的数据请求
        const {data: res} = await this.axios.put('/material/update' , {
          // email: this.editForm.email,
          // mobile: this.editForm.mobile
          id: this.editForm.id,
          mcateId: this.editForm.mcateId,
          code: this.editForm.code,
          name: this.editForm.name,
          
        })
        if (res.code !== 200) {
          return this.$message.error('更新用户信息失败！')
        }
        // 关闭对话框
        this.editDialogVisible = false
        // 更新数据列表
        this.getUserList()
        // 提示修改成功
        this.$message.success('更新用户信息成功！')
        console.log(res);
      })
    },




    // 获取数据
    getUserList(){
      userApi.getUserList(this.searchModel).then(response => {
        this.tables = response.data
      })
    },

    // 监听对话框关闭的事件
    onDialogClosed() {
      // 拿到 Form 组件的引用，调用 resetFields 函数，即可重置表单
      this.$refs.form.resetFields()
    },
    // 用户点击了添加按钮
    onAddNewUser() {
      // 预验证
      this.$refs.form.validate(async valid => {
        if(!valid) return
        // 需要执行添加的业务逻辑
        const { data: res } = await this.axios.post('/material/add',this.addForm)
        // console.log(res);
        if(res.code !== 200) return this.$message.error('添加失败');
        this.$message.success('添加成功!');
        this.dialogVisible = false
        this.getUserList()
      })
    },
    // 删除数据
    async onRemove(id) {
       const confirmResult = await this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        
        //判断是否点击了确认按钮
        if(confirmResult !== 'confirm') return this.$message.info('取消了删除！')


        const { data: res } = await this.axios.delete('/material/' + id)
        if(res.code !== 200) return this.$message.error('删除失败！')
        this.$message.success('删除成功！')
        this.getUserList()
    }

  },


  }
  
</script>

<style>
.riqi{
  text-align: left;
  margin: 10px 10px 10px 10px;
}

.an {
  text-align: left;
  margin: 10px 10px 10px 10px;
}
</style>