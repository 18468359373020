<template>
  <div class="block">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfor.pageNum"
      :page-sizes="[1,2,5,10]"
      :page-size="queryInfor.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {

    data() {
      return {
        //获取用户列表的参数对象
        queryInfor: {
          query: '',
          // 当前的页数
          pageNum: 1,
          // 当前每页显示多少条数据
          pageSize: 2
        },
        userlist: [],        
        total: 0
      };
    },

    created(){
    // this.getData(this.pagesize,this.currentPage)
    this.getList()
  },

   methods: {
    async getList() {
      const { data: res } = await this.axios.get('/material/page',{
        params: this.queryInfor
      })
      // console.log(res);
      if (res.code !== 200) {
        return this.$message.error('获取数据失败！')
      }
      this.userlist = res.data.records
      console.log(this.userlist);
      this.total = res.data.total
      console.log(res);
    },

    //每页显示的条数
    handleSizeChange(newSize){
      // console.log(newSize);
      this.queryInfor.pageSize = newSize
      this.getList()
      // this.pagesize = val
      // this.getData(val,1)
      // this.currentPage = 1
    },
    //显示第几页
    handleCurrentChange(newPage){
      // console.log(newPage);
      this.queryInfor.pageNum = newPage
      this.getList()
      // this.currentPage = val
      // this.getData(this.pagesize,val)
    },

   },


  }
</script>

<style>

</style>