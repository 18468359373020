<template>
  <div class="yy">
    <!-- 左侧盒子 -->
    <div class="yy-box1">
        <ul>
          <li><router-link to="/cp/mycp1">荤菜</router-link></li>
          <li><router-link to="/cp/mycp5">半荤菜</router-link></li>
          <li><router-link to="/cp/mycp2">素菜</router-link></li>
          <li><router-link to="/cp/mycp3">主食甜点</router-link></li>
          <li><router-link to="/cp/mycp4">精品小吃</router-link></li>
        </ul>
    </div>
    <div class="main">
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/01.jpg')"
          :preview-src-list="srcList1">
        </el-image>
        <span class="title b">白菜烩鸡丸</span>
      </div>
      <!-- <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/02.jpg')"
          :preview-src-list="srcList2">
        </el-image>
        <span class="title b">白菜鸡肉丸</span>
      </div> -->
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/03.jpg')"
          :preview-src-list="srcList3">
        </el-image>
        <span class="title b">菠菜根烧丸子</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/04.jpg')"
          :preview-src-list="srcList4">
        </el-image>
        <span class="title b">茶树菇焖鸡</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/05.jpg')"
          :preview-src-list="srcList5">
        </el-image>
        <span class="title b">大烩菜</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/06.jpg')"
          :preview-src-list="srcList6">
        </el-image>
        <span class="title b">粉皮炖羊杂</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/07.jpg')"
          :preview-src-list="srcList7">
        </el-image>
        <span class="title b">粉皮炖猪肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/08.jpg')"
          :preview-src-list="srcList8">
        </el-image>
        <span class="title b">粉皮焖鸡</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/09.jpg')"
          :preview-src-list="srcList9">
        </el-image>
        <span class="title b">黄豆芽炖酥肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/10.jpg')"
          :preview-src-list="srcList10">
        </el-image>
        <span class="title b">萝卜干炒肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/14.jpg')"
          :preview-src-list="srcList14">
        </el-image>
        <span class="title b">五花肉炖菜</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/15.jpg')"
          :preview-src-list="srcList15">
        </el-image>
        <span class="title b">香菇蒸滑鸡</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/16.jpg')"
          :preview-src-list="srcList16">
        </el-image>
        <span class="title b">腰果虾仁</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/14.jpg')"
          :preview-src-list="srcList17">
        </el-image>
        <span class="title b">虾仁鸡汁萝卜</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/21.jpg')"
          :preview-src-list="srcList18">
        </el-image>
        <span class="title b">蒜苗炒鸡蛋</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/07.jpg')"
          :preview-src-list="srcList19">
        </el-image>
        <span class="title b">番茄鸡蛋</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/sc/05.jpg')"
          :preview-src-list="srcList20">
        </el-image>
        <span class="title b">大葱鸡蛋</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        srcList1: [
        require('../../assets/cp/jbc/bhc/01-big.jpg')
        ],
        // srcList2: [
        // require('../../assets/cp/jbc/bhc/02-big.jpg')
        // ],
        srcList3: [
        require('../../assets/cp/jbc/bhc/03-b.jpg')
        ],
        srcList4: [
        require('../../assets/cp/jbc/bhc/04-b.jpg')
        ],
        srcList5: [
        require('../../assets/cp/jbc/bhc/05-b.jpg')
        ],
        srcList6: [
        require('../../assets/cp/jbc/bhc/06-b.jpg')
        ],
        srcList7: [
        require('../../assets/cp/jbc/bhc/07-b.jpg')
        ],
        srcList8: [
        require('../../assets/cp/jbc/bhc/08-b.jpg')
        ],
        srcList9: [
        require('../../assets/cp/jbc/bhc/09-b.jpg')
        ],
        srcList13: [
        require('../../assets/cp/jbc/bhc/13-b.jpg')
        ],
        srcList14: [
        require('../../assets/cp/jbc/bhc/14-b.jpg')
        ],
        srcList15: [
        require('../../assets/cp/jbc/bhc/15-b.jpg')
        ],
        srcList16: [
        require('../../assets/cp/jbc/bhc/16-b.jpg')
        ],
        srcList17: [
        require('../../assets/cp/jbc/sc/14-b.jpg')
        ],
        srcList18: [
        require('../../assets/cp/jbc/sc/21-b.jpg')
        ],
        srcList19: [
        require('../../assets/cp/jbc/sc/07-b.jpg')
        ],
        srcList20: [
        require('../../assets/cp/jbc/sc/05-b.jpg')
        ],
      }
    }
  }
</script>

<style scoped>
.main{
  width: 70%;
  /* height: 2.7rem; */
  margin-top: 20px !important;
  background-color: #ededed;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}
.img-box{
  width: 320px;
  height: 250px;
  background-color: white;
}
.title{
  font-size: .08rem;
  line-height: .15rem;
}
.a:hover{
  background-color: red;
  color: white;
}
</style>