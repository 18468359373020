<template>
  <div>
    <!-- <div style="display: inline-block"> 搜索：</div>
      <el-input v-model="search" style="display: inline-block;width: 1300px" placeholder="请输入搜索内容">
      </el-input> -->
      <div style="margin-top: 15px;">
  <el-input placeholder="请输入内容" v-model="queryInfor.query" class="input-with-select" clearable>
    
    <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
  </el-input>
</div>
  </div>
</template>

<script>
export default {

    data(){
        return {
            
        }
    },

    
  computed: {
     
    },

}
</script>

<style>

  .input-with-select {
   
    width: 430px;
  }
</style>