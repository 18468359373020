<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">追求卓越&nbsp;&nbsp;&nbsp;&nbsp;共庆辉煌<br><p style="font-size: .1rem;padding-top: .1rem;padding-left: .9rem;">——中机六院成立73周年庆典</p></h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>2024年1月12日，为庆祝中机六院创立73周年，六院职工餐厅举办了一场别开生面、寓意深远的活动。六院职工餐厅作为陆源物业为中机六院后勤保障服务的一分子，紧跟六院发展步伐，坚持以人为本用心服务，守住六院人的胃，让大家有更充沛的精力为六院的发展建新功、立新业！此次活动以美食为载体，将我们的祝福与敬意融入其中，共同庆祝这一重要的里程碑。</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a1.jpg" alt=""></div>
            <p>整个展台精心布置，设计独特，充满浓厚的庆典氛围。卡通龙代表龙年，双龙在天，国泰民安。展台上，我们精心烹制的几道佳肴摆放得错落有致，寓意丰富，色香味俱全。在展台的中心位置，一道名为“鱼跃龙门”的佳肴尤为引人注目。金黄色的菜肴上点缀着西兰花的绿色，宛如一幅美丽的画卷，呈现出美味与视觉的双重享受。它寓意着中机六院在2024年业绩如日中天，再创辉煌。</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a2.jpg" alt=""></div>
            <p>在其右边的菜品，名为“禅宗少林”，寓意中机六院扎根河南，立足本土，稳固筑牢中原大院，行业强院。</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a3.jpg" alt=""></div>
            <p>在其左边的菜品名为“牡丹燕菜”，牡丹是国花，寓意国之重企，遍地生花</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a4.jpg" alt=""></div>
            <p>这三道菜相互呼应，形成稳定的三足鼎立之势，寓意着脚踏实地、稳步向前。</p>
            <p>展台上的其他特色佳肴还有：</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a5.jpg" alt=""></div>
            <p class="a1">鸿运喜当头（美好生活，心之所向）</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a6.jpg" alt=""></div>
            <p class="a1">石斑鱼生（铸力打造，设计强院）</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a7.jpg" alt=""></div>
            <p class="a1">一帆风顺相思牛肉（建功立业，一帆风顺）</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a8.jpg" alt=""></div>
            <p class="a1">富贵人参大拼（一路坦荡，百花争鸣）</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a9.jpg" alt=""></div>
            <p class="a1">沙拉三文鱼（职责不同，目标一致，尽显风彩）</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a10.jpg" alt=""></div>
            <p class="a1">麻汁菠菜团（团结一致，奋勇争先）</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a11.jpg" alt=""></div>
            <p class="a1">青椒酱冬笋（努力奋进，汇集成林）</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a12.jpg" alt=""></div>
            <p class="a1">锦绣樱桃萝卜（强强联合，百花齐放）</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a13.jpg" alt=""></div>
            <p class="a1">黄金时萝贺牛方（锦绣添花，独树一帜）</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a14.jpg" alt=""></div>
            <p class="a1">蓝珊庆飞虾（捷报频传，再创佳绩）</p>
            <p>待开餐之后，餐厅内热闹非凡，家人们如潮水般涌入，每个人的脸上都洋溢着喜悦和期待的笑容。璀璨的灯光洒落，映照着欢快的面庞，仿佛每个人的心情都被点亮。为了给每位家人带来一份特别的惊喜，我们精心准备了小巧精致的蛋糕。这些小蛋糕不仅口感细腻、美味可口，更是承载着我们对每位家人的关怀与祝福。当家人们品尝着这美味的甜点时，他们的脸上露出了满足和幸福的笑容，仿佛这一刻所有的烦恼都烟消云散。</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a15.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new1-a16.jpg" alt=""></div>
            <p>此次活动，我们以美食为载体，将深深的祝福与敬意融入其中。美食家活动丰硕的成果也在展台上一一呈现，这是我们倾心打造的美食专辑。它详尽地展示了各种烹饪技巧和美食制作过程。通过这份专辑，大家可以跟着学习如何制作一道道美味佳肴。作为后勤保障的一分子，我们不仅要展现出专心、专注、专业的态度， 更要践行陆源物业的服务宗旨，全心全意为大家服务，积极落实实事。为了与大家共同构建和谐家园，我们需要深入一线，积极参与，真正实现“食堂是我家，共建靠大家”的服务理念。</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a17.jpg" alt=""></div>
            <p>筹备此次活动的过程，我们面临了诸多挑战。首先，场地的布置是一项艰巨的任务。我们需要确保场地美观且符合庆典氛围，为了达到最佳效果，团队多次调整布置方案，确保每一个细节都完美呈现。</p>
            <p>食堂为了让大家能更多的了解中餐的食刻文化，特意在展台展示了：糖艺、面塑、水果雕，这也代表餐厅作为中机六院的一份子用专业的技能向六院贺礼，苦练内功做六院后勤保障的践行者。</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a18.jpg" alt=""></div>
            <p>这次准备过程不仅展现了我们对餐饮艺术的追求，也体现了我们对细节的关注和精益求精的态度。正是由于我们这样的“专业、专注、专心”，客户单位对我们的工作给予了高度的评价和认可。我们深知，客户的满意是我们最大的追求。因此，我们也会继续保持这样的态度和努力，不断提升我们的专业水平和服务质量，希望能为更多的客户带来美好的庆典体验。</p>
            <div class="new_img1"><img src="../../assets/goyb/new1-a19.jpg" alt=""></div>
        </div>
        <hr>
        <div class="new_next">
            <!-- <div class="new_right">上一篇:</div> -->
            <router-link to="/honor/newFive">
                <div class="new_right">上一篇:翼博餐饮2023年会</div>
            </router-link>
            <router-link to="/honor/newTwo">
                <div class="new_right">下一篇:冬至到，吃水饺</div>
            </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    color: red;
}
.new_img1{
    width: 800px;
    height: 400px;
    margin: 5px auto;
}
/* 翻篇 */
.new_next{
    width: 100%;
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
  </style>