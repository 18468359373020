<template>
  <div class="yy">
    <!-- 左侧盒子 -->
    <div class="yy-box1">
        <ul>
          <li><router-link to="/cp/mycp1"  class="router-link-active">荤菜</router-link></li>
          <li><router-link to="/cp/mycp5">半荤菜</router-link></li>
          <li><router-link to="/cp/mycp2">素菜</router-link></li>
          <li><router-link to="/cp/mycp3">主食甜点</router-link></li>
          <li><router-link to="/cp/mycp4">精品小吃</router-link></li>
        </ul>
    </div>
    <div class="main">
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/01.jpg')"
          :preview-src-list="srcList1">
        </el-image>
        <span class="title b">川香麻花鸡</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/02.jpg')"
          :preview-src-list="srcList2">
        </el-image>
        <span class="title b">白斩鸡</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/03.jpg')"
          :preview-src-list="srcList3">
        </el-image>
        <span class="title b">葱烧鱿鱼</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/04.jpg')"
          :preview-src-list="srcList4">
        </el-image>
        <span class="title b">大脸鸡排</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/05.jpg')"
          :preview-src-list="srcList5">
        </el-image>
        <span class="title b">大盘鸡</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/06.jpg')"
          :preview-src-list="srcList6">
        </el-image>
        <span class="title b">东坡肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/07.jpg')"
          :preview-src-list="srcList7">
        </el-image>
        <span class="title b">豆豉蒸排骨</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/08.jpg')"
          :preview-src-list="srcList8">
        </el-image>
        <span class="title b">番茄焖牛腩</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/09.jpg')"
          :preview-src-list="srcList9">
        </el-image>
        <span class="title b">风味鳕鱼快</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/10.jpg')"
          :preview-src-list="srcList10">
        </el-image>
        <span class="title b">干炸带鱼</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/11.jpg')"
          :preview-src-list="srcList11">
        </el-image>
        <span class="title b">锅包肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/12.jpg')"
          :preview-src-list="srcList12">
        </el-image>
        <span class="title b">黑椒牛柳</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/13.jpg')"
          :preview-src-list="srcList13">
        </el-image>
        <span class="title b">黑椒牛仔粒</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/14.jpg')"
          :preview-src-list="srcList14">
        </el-image>
        <span class="title b">回锅肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/15.jpg')"
          :preview-src-list="srcList15">
        </el-image>
        <span class="title b">茴香大虾</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/47.jpg')"
          :preview-src-list="srcList16">
        </el-image>
        <span class="title b">扒皮鱼</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/17.jpg')"
          :preview-src-list="srcList17">
        </el-image>
        <span class="title b">金汤肥牛</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/18.jpg')"
          :preview-src-list="srcList18">
        </el-image>
        <span class="title b">金汤鱼</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/19.jpg')"
          :preview-src-list="srcList19">
        </el-image>
        <span class="title b">扣碗小酥肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/20.jpg')"
          :preview-src-list="srcList20">
        </el-image>
        <span class="title b">卤水大骨头</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/21.jpg')"
          :preview-src-list="srcList21">
        </el-image>
        <span class="title b">卤水鸡腿</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/22.jpg')"
          :preview-src-list="srcList22">
        </el-image>
        <span class="title b">萝卜炖牛腩</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/23.jpg')"
          :preview-src-list="srcList23">
        </el-image>
        <span class="title b">麻辣鸡腿</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/24.jpg')"
          :preview-src-list="srcList24">
        </el-image>
        <span class="title b">麻辣鸭腿</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/25.jpg')"
          :preview-src-list="srcList25">
        </el-image>
        <span class="title b">麻辣猪手</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/26.jpg')"
          :preview-src-list="srcList26">
        </el-image>
        <span class="title b">毛血旺</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/27.jpg')"
          :preview-src-list="srcList27">
        </el-image>
        <span class="title b">梅干菜瘦肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/28.jpg')"
          :preview-src-list="srcList28">
        </el-image>
        <span class="title b">闷罐肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/29.jpg')"
          :preview-src-list="srcList29">
        </el-image>
        <span class="title b">焖锅肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/30.jpg')"
          :preview-src-list="srcList30">
        </el-image>
        <span class="title b">农家小炒肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/31.jpg')"
          :preview-src-list="srcList31">
        </el-image>
        <span class="title b">青椒鸡片</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/32.jpg')"
          :preview-src-list="srcList32">
        </el-image>
        <span class="title b">清蒸鱼块</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/34.jpg')"
          :preview-src-list="srcList34">
        </el-image>
        <span class="title b">手抓排骨</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/35.jpg')"
          :preview-src-list="srcList35">
        </el-image>
        <span class="title b">四喜丸子</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/37.jpg')"
          :preview-src-list="srcList37">
        </el-image>
        <span class="title b">糖醋里脊</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/38.jpg')"
          :preview-src-list="srcList38">
        </el-image>
        <span class="title b">土耳其烤肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/40.jpg')"
          :preview-src-list="srcList40">
        </el-image>
        <span class="title b">五香鸡爪</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/41.jpg')"
          :preview-src-list="srcList41">
        </el-image>
        <span class="title b">香水鱼</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/42.jpg')"
          :preview-src-list="srcList42">
        </el-image>
        <span class="title b">湘味腰花</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/43.jpg')"
          :preview-src-list="srcList43">
        </el-image>
        <span class="title b">蒸小酥肉</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/hc/44.jpg')"
          :preview-src-list="srcList44">
        </el-image>
        <span class="title b">油焖大虾</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/11.jpg')"
          :preview-src-list="srcList45">
        </el-image>
        <span class="title b">时蔬虾球</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/12.jpg')"
          :preview-src-list="srcList46">
        </el-image>
        <span class="title b">土豆炖鸡块</span>
      </div>
      <div class="img-box a">
        <el-image
          style="width: 100%; height: 85%"
          :src="require('../../assets/cp/jbc/bhc/13.jpg')"
          :preview-src-list="srcList47">
        </el-image>
        <span class="title b">土豆红烧肉</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        srcList1: [
        require('../../assets/cp/jbc/hc/01-big.jpg')
        ],
        srcList2: [
        require('../../assets/cp/jbc/hc/02-big.jpg')
        ],
        srcList3: [
        require('../../assets/cp/jbc/hc/03-b.jpg')
        ],
        srcList4: [
        require('../../assets/cp/jbc/hc/04-b.jpg')
        ],
        srcList5: [
        require('../../assets/cp/jbc/hc/05-b.jpg')
        ],
        srcList6: [
        require('../../assets/cp/jbc/hc/06-b.jpg')
        ],
        srcList7: [
        require('../../assets/cp/jbc/hc/07-b.jpg')
        ],
        srcList8: [
        require('../../assets/cp/jbc/hc/08-b.jpg')
        ],
        srcList9: [
        require('../../assets/cp/jbc/hc/09-b.jpg')
        ],
        srcList10: [
        require('../../assets/cp/jbc/hc/10-b.jpg')
        ],
        srcList11: [
        require('../../assets/cp/jbc/hc/11-b.jpg')
        ],
        srcList12: [
        require('../../assets/cp/jbc/hc/12-b.jpg')
        ],
        srcList13: [
        require('../../assets/cp/jbc/hc/13-b.jpg')
        ],
        srcList14: [
        require('../../assets/cp/jbc/hc/14-b.jpg')
        ],
        srcList15: [
        require('../../assets/cp/jbc/hc/15-b.jpg')
        ],
        srcList16: [
        require('../../assets/cp/jbc/hc/47-b.jpg')
        ],
        srcList17: [
        require('../../assets/cp/jbc/hc/17-b.jpg')
        ],
        srcList18: [
        require('../../assets/cp/jbc/hc/18-b.jpg')
        ],
        srcList19: [
        require('../../assets/cp/jbc/hc/19-b.jpg')
        ],
        srcList20: [
        require('../../assets/cp/jbc/hc/20-b.jpg')
        ],
        srcList21: [
        require('../../assets/cp/jbc/hc/21-b.jpg')
        ],
        srcList22: [
        require('../../assets/cp/jbc/hc/22-b.jpg')
        ],
        srcList23: [
        require('../../assets/cp/jbc/hc/23-b.jpg')
        ],
        srcList24: [
        require('../../assets/cp/jbc/hc/24-b.jpg')
        ],
        srcList25: [
        require('../../assets/cp/jbc/hc/25-b.jpg')
        ],
        srcList26: [
        require('../../assets/cp/jbc/hc/26-b.jpg')
        ],
        srcList27: [
        require('../../assets/cp/jbc/hc/27-b.jpg')
        ],
        srcList28: [
        require('../../assets/cp/jbc/hc/28-b.jpg')
        ],
        srcList29: [
        require('../../assets/cp/jbc/hc/29-b.jpg')
        ],
        srcList30: [
        require('../../assets/cp/jbc/hc/30-b.jpg')
        ],
        srcList31: [
        require('../../assets/cp/jbc/hc/31-b.jpg')
        ],
        srcList32: [
        require('../../assets/cp/jbc/hc/32-b.jpg')
        ],
        srcList34: [
        require('../../assets/cp/jbc/hc/34-b.jpg')
        ],
        srcList35: [
        require('../../assets/cp/jbc/hc/35-b.jpg')
        ],
        srcList37: [
        require('../../assets/cp/jbc/hc/37-b.jpg')
        ],
        srcList38: [
        require('../../assets/cp/jbc/hc/38-b.jpg')
        ],
        // srcList39: [
        // require('../../assets/cp/jbc/hc/39-b.jpg')
        // ],
        srcList40: [
        require('../../assets/cp/jbc/hc/40-b.jpg')
        ],
        srcList41: [
        require('../../assets/cp/jbc/hc/41-b.jpg')
        ],
        srcList42: [
        require('../../assets/cp/jbc/hc/42-b.jpg')
        ],
        srcList43: [
        require('../../assets/cp/jbc/hc/43-b.jpg')
        ],
        srcList44: [
        require('../../assets/cp/jbc/hc/44-b.jpg')
        ],
        srcList45: [
        require('../../assets/cp/jbc/bhc/11-b.jpg')
        ],
        srcList46: [
        require('../../assets/cp/jbc/bhc/10-b.jpg')
        ],
        srcList47: [
        require('../../assets/cp/jbc/bhc/12-b.jpg')
        ],
      }
    }
  }
</script>

<style scoped>
.main{
  width: 70%;
  /* height: 2.7rem; */
  margin-top: 20px !important;
  background-color: #ededed;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
}
.img-box{
  width: 320px;
  height: 250px;
  background-color: white;
}
.title{
  font-size: .08rem;
  line-height: .15rem;
}
.a:hover{
  background-color: red;
  color: white;
}
</style>