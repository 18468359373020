<template>
  <div class="yy">
    <!-- 上盒子 -->
    <div class="yy-box1">
        <!-- <div class="yy-box1-1">走进翼博</div> -->
        <ul>
            <li><router-link to="/honor/myzs1">企业简介</router-link></li>
            <li><router-link to="/honor/myzs2">企业文化</router-link></li>
            <li><router-link to="/honor/myzs3">组织架构</router-link></li>
            <li><router-link to="/honor/myzs4">企业资质</router-link></li>
            <li><router-link to="/honor/myzs5">荣誉证书</router-link></li>
            <li><router-link to="/honor/myzs6">新闻动态</router-link></li>
        </ul>
    </div>

    <!-- 下盒子 -->
    <div class="qyzz">
        <img src="@/assets/ryzs/1.png" alt="">
        <img src="@/assets/ryzs/2.jpg" alt="">
        <img src="@/assets/ryzs/3.jpg" alt="">
        <img src="@/assets/ryzs/4.jpg" alt="">
        <img src="@/assets/ryzs/5.png" alt="">
        <img src="@/assets/ryzs/6.jpg" alt="">
        <img src="@/assets/ryzs/7.jpg" alt="">
        <img src="@/assets/ryzs/8.jpg" alt="">
        <img src="@/assets/ryzs/9.jpg" alt="">
        <img src="@/assets/ryzs/10.jpg" alt="">
        <img src="@/assets/ryzs/01.png" alt="" style="width:50%">
    </div>
  </div>
</template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
  *{
    box-sizing: border-box;
}
  .qyzz  {
    width: 60%;
    margin: 0 auto;
  }
  </style>