// 导入 Vue 和 VueRouter
import Vue from "vue";
import VueRouter from "vue-router";

import pathArr from "./pathArr";

import MyLogin from '@/view/Login/MyLogin.vue'  //登录页

import MyHt from '@/view/Backstage/MyHt.vue'  //后台


//二级
import MyCggl from '@/components/Ht/MyCggl.vue'
import MyKcgl from '@/components/Ht/MyKcgl.vue'
import MyRlzy from '@/components/Ht/MyRlzy.vue'
import MySzgl from '@/components/Ht/MySzgl.vue'
import MyXmgl from '@/components/Ht/MyXmgl.vue'
import MyXtgl from '@/components/Ht/MyXtgl.vue'



//三级
import MyCg1 from '@/components/Ht/Cggl/MyCg1.vue'
import MyCg2 from '@/components/Ht/Cggl/MyCg2.vue'
import MyCg3 from '@/components/Ht/Cggl/MyCg3.vue'
import MyCg4 from '@/components/Ht/Cggl/MyCg4.vue'
import MyCg5 from '@/components/Ht/Cggl/MyCg5.vue'


import MyXm1 from '@/components/Ht/Xmgl/MyXm1.vue'
import MyXm2 from '@/components/Ht/Xmgl/MyXm2.vue'
import MyXm3 from '@/components/Ht/Xmgl/MyXm3.vue'


import MyKc1 from '@/components/Ht/Kcgl/MyKc1.vue'
import MyKc2 from '@/components/Ht/Kcgl/MyKc2.vue'
import MyKc3 from '@/components/Ht/Kcgl/MyKc3.vue'
import MyKc4 from '@/components/Ht/Kcgl/MyKc4.vue'
import MyKc5 from '@/components/Ht/Kcgl/MyKc5.vue'
import MyKc6 from '@/components/Ht/Kcgl/MyKc6.vue'


import MySz1 from '@/components/Ht/Szgl/MySz1.vue'
import MySz2 from '@/components/Ht/Szgl/MySz2.vue'
import MySz3 from '@/components/Ht/Szgl/MySz3.vue'
import MySz4 from '@/components/Ht/Szgl/MySz4.vue'


import MyRl1 from '@/components/Ht/Rlzy/MyRl1.vue'
import MyRl2 from '@/components/Ht/Rlzy/MyRl2.vue'
import MyRl3 from '@/components/Ht/Rlzy/MyRl3.vue'
import MyRl4 from '@/components/Ht/Rlzy/MyRl4.vue'
import MyRl5 from '@/components/Ht/Rlzy/MyRl5.vue'


import MyXt1 from '@/components/Ht/Xtgl/MyXt1.vue'
import MyXt2 from '@/components/Ht/Xtgl/MyXt2.vue'





// 四级



import MyYclfl1 from '@/components/Ht/Cggl/yclfl/MyYclfl1'
import MyYclfl2 from '@/components/Ht/Cggl/yclfl/MyYclfl2'
import MyYclfl3 from '@/components/Ht/Cggl/yclfl/MyYclfl3'
import MyYclfl4 from '@/components/Ht/Cggl/yclfl/MyYclfl4'
import MyYclfl5 from '@/components/Ht/Cggl/yclfl/MyYclfl5'



// 导入需要的组件
// import CsHome from '@/components/tabs/CsHome.vue'
// import CsMovie from '@/components/tabs/CsMovie.vue'
// import CsAbout from '@/components/tabs/CsAbout.vue'
import MyHome from '@/components/MyHome.vue'
import MyHonor from '@/components/MyHonor.vue'

import MyCp from '@/components/MyCp.vue'
import MyGy from '@/components/MyGy.vue'


// 子组件
import MyZs1 from '@/components/Honor/MyZs1.vue'
import MyZs2 from '@/components/Honor/MyZs2.vue'
import MyZs3 from '@/components/Honor/MyZs3.vue'
import MyZs4 from '@/components/Honor/MyZs4.vue'
import MyZs5 from '@/components/Honor/MyZs5.vue'
import MyZs6 from '@/components/Honor/MyZs6.vue'
import newOne from '@/components/Honor/newOne.vue'
import newTwo from '@/components/Honor/newTwo.vue'
import newThree from '@/components/Honor/newThree.vue'
import newFour from '@/components/Honor/newFour.vue'
import newFive from '@/components/Honor/newFive.vue'
import newSix from '@/components/Honor/newSix.vue'
import newSeven from '@/components/Honor/newSeven.vue'
import new1 from '@/components/Honor/new1.vue'
import new2 from '@/components/Honor/new2.vue'
import new3 from '@/components/Honor/new3.vue'
import new4 from '@/components/Honor/new4.vue'
import new5 from '@/components/Honor/new5.vue'
import new6 from '@/components/Honor/new6.vue'
import new7 from '@/components/Honor/new7.vue'

// 团队建设
import MyTeam from '@/components/MyTeam.vue'  //主页面
import Introduction from '@/components/team/Introduction.vue'
import Activity from '@/components/team/Activity.vue'
import Training from '@/components/team/Training.vue'

import MyCp1 from '@/components/Cp/MyCp1.vue' //全荤菜
import MyCp2 from '@/components/Cp/MyCp2.vue'
import MyCp3 from '@/components/Cp/MyCp3.vue'
import MyCp4 from '@/components/Cp/MyCp4.vue'
import MyCp5 from '@/components/Cp/MyCp5.vue'

import MyGy1 from '@/components/Gy/MyGy1.vue'
import MyGy2 from '@/components/Gy/MyGy2.vue'
// import MyHonor from '@/view/Honor/MyHonor.vue'
// 典型案例
import myModel from '@/components/model/index.vue'
import model from '@/components/model/model.vue'
// 酒店餐饮
import hotelDining from '@/components/hotelDining/index.vue'
import business from '@/components/hotelDining/business.vue'
import buffet from '@/components/hotelDining/buffet.vue'
// 管理体系
import management from '@/components/management/index.vue'
import sixs from '@/components/management/6s.vue'
import informatization from '@/components/management/informatization.vue'
import nutrition from '@/components/management/nutrition.vue'
import pdca from '@/components/management/pdca.vue'
import safe from '@/components/management/safe.vue'
import system from '@/components/management/system.vue'
// 重大活动
import myCase from '@/components/MyCase.vue'
import Case713 from '@/components/Case/Case713.vue'
import Case713dz from '@/components/Case/Case713dz.vue'
import CaseHT from '@/components/Case/CaseHT.vue'
import CaseSix from '@/components/Case/CaseSix.vue'
import CaseZJLY70 from '@/components/Case/CaseZJLY70.vue'
import Case38 from '@/components/Case/Case38.vue'
import CaseDIY from '@/components/Case/CaseDIY.vue'

// 调用 Vue.use() 函数，吧 VueRouter 安装为 Vue 的插件
Vue.use(VueRouter)

// 创建路由的实例对象
const router = new VueRouter({
    // // routes 是一个数组，作用：定义 hash 地址 与 组件 之间的关系
    routes: [
    //     // 重定向
    //     // { path: '/', component: CsHome },
    //     // // 路由规则
        { path: '/', component: MyHome },
        { path: '/home', component: MyHome },
        { 
            path: '/honor', 
            component: MyHonor,
            redirect: "/honor/myzs1",
            children: [
                {  path: '' , component: MyZs1 },
                {  path: 'myzs1' , component: MyZs1 },
                {  path: 'myzs2' , component: MyZs2 },
                {  path: 'myzs3' , component: MyZs3 },
                {  path: 'myzs4' , component: MyZs4 },
                {  path: 'myzs5' , component: MyZs5 },
                {  path: 'myzs6' , component: MyZs6 },
                {  path: 'newOne', component: newOne},
                {  path: 'newTwo', component: newTwo},
                {  path: 'newThree', component:newThree},
                {  path: 'newFour',  component:newFour},
                {  path: 'newFive', component:newFive},
                {  path: 'newSix', component:newSix},
                {  path: 'newSeven', component:newSeven},
                {  path: 'new1',component:new1},
                {  path: 'new2',component:new2},
                {  path: 'new3',component:new3},
                {  path: 'new4',component:new4},
                {  path: 'new5',component:new5},
                {  path: 'new6',component:new6},
                {  path: 'new7',component:new7}
            ]    
        },
        { 
            path: '/cp' , 
            component: MyCp ,
            children: [
                { path: '', component: MyCp1 },
                { path: 'mycp1' , component: MyCp1 },
                { path: 'mycp2' , component: MyCp2 },
                { path: 'mycp3' , component: MyCp3 },
                { path: 'mycp4' , component: MyCp4 },
                { path: 'mycp5' , component: MyCp5 },
            ]
        
        },
        {
            path:'/hotelDining',
            component:hotelDining,
            children:[
                {path:'',component:business},
                {path:'/business',component:business},
                {path:'/buffet',component:buffet},
            ]
        },
        {
            path:'/myModel',
            component:myModel,
            children:[
                {path:'',component:model},
                {path:'/model',component:model}
            ]
        },
        { 
            path: '/case' , 
            component: myCase ,
            children: [
                { path: '', component: CaseSix },
                { path: 'Case713', component: Case713 },
                { path: 'Case713dz', component: Case713dz },
                { path: 'CaseHT', component: CaseHT },
                { path: 'CaseSix', component: CaseSix },
                { path: 'CaseZJLY70', component: CaseZJLY70 },
                { path: 'Case38', component: Case38 },
                { path: 'CaseDIY', component: CaseDIY },
            ]
        },
        {
            path:'/team',
            component:MyTeam,
            children:[
                {path:'',component:Introduction},
                {path:'Introduction',component:Introduction},
                {path:'Activity',component:Activity},
                {path:'Training',component:Training}
            ]
        },
        {
            path:'/management',
            component:management,
            children:[
                {path:'',component:sixs},
                {path:'/sixs',component:sixs},
                {path:'/informatization',component:informatization},
                {path:'/nutrition',component:nutrition},
                {path:'/pdca',component:pdca},
                {path:'/safe',component:safe},
                {path:'/system',component:system}
            ]
        },
        { 
            path: '/gy', 
            component: MyGy , 
            children: [
                { path: '', component: MyGy1 },
                { path: 'gy1', component: MyGy1 },
                { path: 'gy2', component: MyGy2 },
            ]
        },
        { path: '/login', component: MyLogin },
        { 
            path: '/myht' , 
            component: MyHt ,
            redirect: "/myht/cggl",
            children: [
                // { path: '', component: MyCggl },
                { 
                    path: 'cggl' , 
                    component: MyCggl,
                    children: [
                        { path: '', component: MyCg1 },
                        { path: 'cg1', component: MyCg1 },
                        { path: 'cg2', component: MyCg2 ,},
                        { 
                            path: 'cg3', 
                            component: MyCg3 ,
                            
                        },
                        { 
                            path: 'cg4', 
                            component: MyCg4 ,
                            
                        },
                        { 
                            path: 'cg5', 
                            component: MyCg5 ,
                            children: [
                                { path: '', component: MyYclfl1 },
                                { path: 'yclfl1', component: MyYclfl1 },
                                { path: 'yclfl2', component: MyYclfl2 },
                                { path: 'yclfl3', component: MyYclfl3 },
                                { path: 'yclfl4', component: MyYclfl4 },
                                { path: 'yclfl5', component: MyYclfl5 },
                            ]
                        },
                    ]
                
                },
                { 
                    path: 'xmgl' , 
                    component: MyXmgl,
                    children: [
                        { path: '', component: MyXm1 },
                        { path: 'xm1', component: MyXm1 },
                        { path: 'xm2', component: MyXm2 },
                        { path: 'xm3', component: MyXm3 },
                    ]
                
                },
                { 
                    path: 'kcgl' , 
                    component: MyKcgl ,
                    children: [
                        { path: '', component: MyKc1 },
                        { path: 'kc1', component: MyKc1 },
                        { path: 'kc2', component: MyKc2 },
                        { path: 'kc3', component: MyKc3 },
                        { path: 'kc4', component: MyKc4 },
                        { path: 'kc5', component: MyKc5 },
                        { path: 'kc6', component: MyKc6 },
                    ]
                },
                { 
                    path: 'szgl' , 
                    component: MySzgl ,
                    children: [
                        { path: '', component: MySz1 },
                        { path: 'sz1', component: MySz1 },
                        { path: 'sz2', component: MySz2 },
                        { path: 'sz3', component: MySz3 },
                        { path: 'sz4', component: MySz4 },
                    ]
                },
                { 
                    path: 'rlzy' , 
                    component: MyRlzy ,
                    children: [
                        { path: '', component: MyRl1 },
                        { path: 'rl1', component: MyRl1 },
                        { path: 'rl2', component: MyRl2 },
                        { path: 'rl3', component: MyRl3 },
                        { path: 'rl4', component: MyRl4 },
                        { path: 'rl5', component: MyRl5 },
                    ]
                },
                { 
                    path: 'xtgl' , 
                    component: MyXtgl ,
                    children: [
                        { path: '', component: MyXt1 },
                        { path: 'xt1', component: MyXt1 },
                        { path: 'xt2', component: MyXt2 },
                    ]
                },
                
            ]
        
        }
        
    ]
})

// router.beforeEach(function(to,from,next){
//     if (to.path === '/myht') {
//         const token = localStorage.getItem('token')
//         if(token) {
//             next()
//         } else {
//             next('/login')
//         }
//     } else {
//         next()
//     }
// })

// 全局前置守卫
router.beforeEach(function(to, from, next) {
    if (pathArr.indexOf(to.path) !== -1) {
      const token = localStorage.getItem('token')
      if (token) {
        next()
      } else {
        next('/login')
      }
    } else {
      next()
    }
  })

//向外共享路由对象
export default router