<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">冬至到&nbsp;&nbsp;吃水饺</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>随着风的呼啸，寒意渐浓，大地悄然沐浴在银装素裹之中。今日，正值冬至，这个寒冷而温馨的日子，是岁月的交汇，是寄托思念的桥梁。我们不禁想起那碗热腾腾、香气四溢的饺子。为此，我们特别准备了一场别开生面的饺子盛宴，用美味传递温馨，温暖每一个味蕾，愉悦每一颗心。</p>
            <div class="new_img1"><img src="../../assets/goyb/new2-b1.jpg" alt=""></div>
            <p class="a1">口味多样，满足个性化</p>
            <p>为了迎合大家的口味偏好，我们的厨师团队精心研发了多种口味的饺子馅料，以供大家选择，每一口都是用心制作的味蕾享受。无论您喜欢清淡的口感还是浓郁的风味，我们都有着丰富的选择，让您在这个冬至季节里品味到家的温馨。</p>
            <p>饺子的面皮薄如蝉翼，馅料鲜美可口，每一个饺子都包裹着大家对美好生活的期盼。品味一口热腾腾的饺子，仿佛感受到了冬至的温暖，就如同心灵的一抹暖意。</p>
            <p class="a1">纯手工制作的匠心</p>
            <p>饺子馅料的制作是一项繁琐而有趣的过程，需要精心挑选食材，并注重搭配和调味。厨师们精心挑选了新鲜的猪肉、茴香、韭菜、鸡蛋和姜等食材，将他们剁碎混合在一起，再加入适量的老抽、盐、香油和秘制香料等调料，搅拌均匀。</p>
            <p>厨师手中的刀刃娴熟地划过姜块，散发出阵阵的清香。新鲜的姜片，为饺子的馅料增添了独特的风味。</p>
            <div class="new_img1"><img src="../../assets/goyb/new2-b2.gif" alt=""></div>
            <p>韭菜与饺子的搭配是一种经典的组合，韭菜的清香和独特的口感能够完美地衬托出饺子的味道。韭菜还具有一定的调味作用，能够为饺子增添一些鲜味和清爽感。韭菜中含有丰富的维生素C和矿物质，这些成分能够提升饺子的口感和营养价值。同时，韭菜还具有一定的辣味，能够为饺子增添一些刺激感，让整个味道更加丰富。</p>
            <div class="new_img1"><img src="../../assets/goyb/new2-b3.jpg" alt=""></div>
            <p>接下来，将准备好的食材调料等充分混合，确保调味料和油均匀地覆盖在肉和蔬菜上，使馅料口感丰富。将调制好的馅料进行腌制，让各种香味更好地渗透到食材中，提升口感。</p>
            <div class="new_img1"><img src="../../assets/goyb/new2-b4.jpg" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new2-b5.gif" alt=""></div>
            <p>在包馅的过程中，一定要注意平衡，不要包得过满，也不要过于松散，这样可以确保在烹饪的时候饺子更容易保持完整。包馅的手法也很重要，一般是将饺子皮对折，然后从两边开始向中间捏合。捏合时要确保封口牢固，以防止在烹饪过程中馅料泄漏。</p>
            <div class="new_img1"><img src="../../assets/goyb/new2-b6.gif" alt=""></div>
            <p>在这个冬至，让我们一同品味温暖，共同迎接新的一年的到来。祝愿大家冬至快乐，身体健康，工作顺利，心情愉悦！</p>
        </div>
        <hr>
        <div class="new_next">
        <router-link to="/honor/newOne">
            <div class="new_right">上一篇:中机六院成立73周年庆典</div>
        </router-link>
        <router-link to="/honor/newThree">
            <div class="new_right">下一篇:“食”刻警惕，以“演”筑防</div>
        </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    font-weight: 700;
}
.new_img1{
    width: 800px;
    height: 400px;
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    /* height: 2rem; */
    /* margin-top: .2rem; */
    /* background-color: pink; */
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
  </style>