<template>
    <div class="yy">
      <!-- 上盒子 -->
      <div class="yy-box1">
          <!-- <div class="yy-box1-1">走进翼博</div> -->
          <ul>
              <li><router-link to="/honor/myzs1">企业简介</router-link></li>
              <li><router-link to="/honor/myzs2">企业文化</router-link></li>
              <li><router-link to="/honor/myzs3">组织架构</router-link></li>
              <li><router-link to="/honor/myzs4">企业资质</router-link></li>
              <li><router-link to="/honor/myzs5">荣誉证书</router-link></li>
              <li><router-link to="/honor/myzs6">新闻动态</router-link></li>
          </ul>
      </div>
  
      <!-- 下盒子 -->
      <div class="ryzs">
        <img src="@/assets/ryzs/(8).jpg" alt="">
        <img src="@/assets/ryzs/(9).jpg" alt="">
        <img src="@/assets/ryzs/(10).jpg" alt="">
        <img src="@/assets/ryzs/(11).jpg" alt="">
        <img src="@/assets/ryzs/(12).jpg" alt="">
        <img src="@/assets/ryzs/(13).jpg" alt="">
        <img src="@/assets/ryzs/(14).jpg" alt="">
        <img src="@/assets/ryzs/(15).jpg" alt="">
        <img src="@/assets/ryzs/(16).jpg" alt="">
        <img src="@/assets/ryzs/12.jpg" alt="">
        <img src="@/assets/ryzs/13.jpg" alt="">
        <img src="@/assets/ryzs/11.jpg" alt="">
        <img src="@/assets/ryzs/14.jpg" alt="">
        <img src="@/assets/ryzs/15.jpg" alt="">
        
          
      </div>
    </div>
  </template>

<script>
export default {

}
</script>

<style scoped>
*{
    box-sizing: border-box;
}
.ryzs {
  width: 80%;
  margin: 0 auto;
}
</style>