<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">守护健康，共筑美味</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>近日，为了进一步加强职工健康管理，确保食品安全，郑州翼博餐饮管理有限公司组织了一次全面的职工体检活动，旨在全面了解和掌握职工的身体状况，预防和控制潜在的健康风险。</p>
            <p>在餐饮行业，员工的健康状况直接关系到食品制作的安全与卫生。因此，翼博餐饮将员工健康视为餐厅运营的重要基石。我们深知，只有拥有健康的体魄，员工们才能以饱满的热情和精湛的技艺，为顾客烹饪出美味可口的佳肴。因此，我们决定定期进行员工体检，确保每位员工都能以最佳状态投入到工作中。</p>
            <p>本次体检，我们携手专业医疗机构，为员工们量身打造了一套全面而科学的体检方案。体检项目不仅包括了血压、血糖、血脂等常规健康指标的检测，还包括胸透检查。我们希望通过这些细致入微的检查，能够及时发现并预防潜在的健康问题，为员工们的健康保驾护航。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-i3.png" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-i2.png" alt=""></div>
            <div class="new_img1"><img src="../../assets/goyb/new-i1.png" alt=""></div>
            <p>本次体检，我们携手专业医疗机构，为员工们量身打造了一套全面而科学的体检方案。体检项目不仅包括了血压、血糖、血脂等常规健康指标的检测，还包括胸透检查。我们希望通过这些细致入微的检查，能够及时发现并预防潜在的健康问题，为员工们的健康保驾护航。</p>
            <p>在翼博餐饮，我们深知健康与美味的密不可分。因此，我们将继续致力于员工健康的守护与提升，为您打造一个安心、健康的用餐环境。感谢您一直以来对我们的支持与厚爱，期待在未来的日子里，我们能够携手共进，共创美好未来！</p>
        </div>
        <hr>
        <div class="new_next">
        <router-link to="/honor/new4">
            <div class="new_right">上一篇:翼博餐饮成功中标郑州海为酒店餐厅服务商，携手共创餐饮新篇章</div>
        </router-link>
        <router-link to="/honor/new1">
            <div class="new_right">下一篇:翼博餐饮中标郑州恒达智控新区餐厅服务商</div>
        </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    font-weight: 700;
}
.new_img1{
    width: 600px;
    height: 400px;
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    /* height: 2rem; */
    /* margin-top: .2rem; */
    /* background-color: pink; */
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
  </style>