import request from "@/utils/requset"



export default{
    getUserList(tables){
        return request({
            url: '/material/list',
            method: 'get',
            params:{
                id: tables.id,
                mcateId: tables.mcateId,
            }
        })
    }
}
