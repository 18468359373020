<template>
  <div class="di" style="width: 100%;">
    <!-- 日期 -->
    <MyDate></MyDate>


    <!-- form表单添加 -->
    <div class="an">
      <el-button type="primary" @click="dialogVisible = true">新增</el-button>

      <MySearch></MySearch>
      <!-- <div style="display: inline-block"> 搜索：</div>
      <el-input v-model="search" style="display: inline-block;width: 1300px" placeholder="请输入搜索内容"></el-input> -->
      <!-- <el-button @click="tables">搜索</el-button> -->

          <el-dialog title="新增数据" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="addForm" label-width="80px">
        <el-form-item label="编号" prop="id">
          <el-input v-model="addForm.id"></el-input>
        </el-form-item>
        <el-form-item label="批次号" prop="pch">
          <el-input v-model="addForm.pch"></el-input>
        </el-form-item>
        <el-form-item label="入库数量" prop="rksl">
          <el-input v-model="addForm.rksl"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="jg">
          <el-input v-model="addForm.jg"></el-input>
        </el-form-item>
        <el-form-item label="供应商编码" prop="gid">
          <el-input v-model="addForm.gid"></el-input>
        </el-form-item>
        <el-form-item label="库存区位" prop="kcqw">
          <el-input v-model="addForm.kcqw"></el-input>
        </el-form-item>
        <el-form-item label="采购员" prop="cgy">
          <el-input v-model="addForm.cgy"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="resetForm">确 定</el-button>
      </span>
    </el-dialog>
    </div>




    <!-- 列表显示 -->
  <el-table :data="tables" border style="width: 100%">
    
    <el-table-column prop="id" label="姓名" width="100"></el-table-column>

    <el-table-column prop="mcateId" label="性别" width="120"></el-table-column>

    <el-table-column prop="code" label="身份证号" width="100"></el-table-column>

    <el-table-column prop="name" label="供应商名称" width="110"></el-table-column>

    <el-table-column prop="brand" label="联系电话1" width="135"></el-table-column>

    <el-table-column prop="standard" label="岗位" width="110"></el-table-column>

    <el-table-column prop="unit" label="联系电话2" width="135"></el-table-column>

    <el-table-column prop="type" label="电子信箱" width="120"></el-table-column>

    <el-table-column prop="lowerLimit" label="状态" width="100"></el-table-column>

    <el-table-column prop="upperLimit" label="工作地址" ></el-table-column>

    <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="showEditDialog(scope.row.id)">修改</el-button>
          <!-- <el-button type="text" size="small" @click="getInfo">请求</el-button> -->
          <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">移除</el-button>
        </template>
        </el-table-column>
  </el-table>


  <MyPager></MyPager>
</div>
</template>

<script>
import userApi from '@/api/user'
import MyDate from '../MyDate.vue'
import MyPager from '../MyPager.vue'
import MySearch from '../MySearch.vue'

  export default {
    components: { MyDate , MyPager , MySearch},

    data() {
      return {

        searchModel:{
          id:1,
        },
        tables:[],

        // 修改
        showEditDialog() {
          // this.addForm = res.data
      this.dialogVisible = true
    },


        dialogVisible:false, //隐藏表单

        addForm: {
          id: '',
          pch: '',
          rksl: '',
          jg: '',
          gid: '',
          kcqw: '',
          cgy: '',
          index: 0
        },


        // 搜索
        // search: '',

        // onInputChange(){},
        // input:'',
        tableData: [
        //   {
        //   id: '1',
        //   date: '图书馆',
        //   name: '111',
        //   pch: '1111',
        //   // address: '河南省郑州市中原区中原西路高庄',
        //   pp: '1111埃杜阿多11',
        //   bz: '111',
        //   jldw: '111',
        //   ggrl: '111',
        //   rkjg: '111',
        //   sl: '11',
        //   xm: '11',
        //   gid: '11',
        //   kcqw: '11',
        //   cgy: '11',
        // },

       ],
      }
    },

    created() {

    this.getUserList();
  },
  
  methods: {
  // 删除数据
    deleteRow (index, rows) {
      rows.splice(index, 1)
      this.addForm.index--
    },

// 添加数据到表格里面
    resetForm (addForm) {
      this.$refs.form.validate(valid => {
        if (!valid) return this.$message.warning('表单填写有误，请检查！')
        this.$message.success('添加成功！')
        console.log(addForm)
        // this.$set(target,key,value)
        // target:要更改的数据源（可以是一个对象或者数组）
        // key:要更改的具体数据 （索引）
        // value: 重新赋的值 
        // 当vue的data里边声明或者已经赋值过的对象或者数组（数组里边的值是对象）时，向对象中添加新的属性，如果更新此属性的值，是不会更新视图的。
        this.$set(this.tableData, this.addForm.index, { id: this.addForm.id, pch: this.addForm.pch, rksl: this.addForm.rksl, jg: this.addForm.jg, gid: this.addForm.gid, kcqw: this.addForm.kcqw, cgy: this.addForm.cgy, project: 1, school: this.school, years: this.year })
        this.addForm.index++
        this.addForm.id = ''
        this.addForm.pch = ''
        this.addForm.rksl = ''
        this.addForm.jg = ''
        this.addForm.gid = ''
        this.addForm.kcqw = ''
        this.addForm.cgy = ''
        this.dialogVisible = false
      })
    },


    getUserList(){
      userApi.getUserList(this.searchModel).then(response => {
        this.tables = response.data
      })
    }

  },



  }
  
</script>

<style>
.riqi{
  text-align: left;
  margin: 10px 10px 10px 10px;
}

.an {
  text-align: left;
  margin: 10px 10px 10px 10px;
}
</style>