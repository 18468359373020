<template>
    <div>
        <div class="banner">
            <img src="../../assets/goyb.jpg" alt="">
        </div>
        <div class="main1">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">食材验收</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/aqgl/a03.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/aqgl/a04.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">食品留样</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/aqgl/b01.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/aqgl/b02.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main1">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">巡视检查</div>
            <div class="content1 content3">
                <div class="img3 wow animate__animated animate__bounceInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/aqgl/c01.jpg" alt="">
                </div>
                <div class="img3 wow animate__animated animate__bounceInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/aqgl/c02.jpg" alt="">
                </div>
                <div class="img3 wow animate__animated animate__bounceInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1.5s" data-wow-iteration="1s">
                    <img src="../../assets/aqgl/c03.jpg" alt="">
                </div>
                <div class="img3 wow animate__animated animate__bounceInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2s" data-wow-iteration="1s">
                    <img src="../../assets/aqgl/c05.jpg" alt="">
                </div>
                <div class="img3 wow animate__animated animate__bounceInDown animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="2.5s" data-wow-iteration="1s">
                    <img src="../../assets/aqgl/c04.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="main2">
            <div class="title1 wow animate__animated animate__zoomIn animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">消防设施</div>
            <div class="content1">
                <div class="img1 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/aqgl/d01.png" alt="">
                </div>
                <div class="img1 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/aqgl/d02.png" alt="">
                </div>
                <div class="img1 wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="0.5s" data-wow-iteration="1s">
                    <img src="../../assets/aqgl/d03.png" alt="">
                </div>
                <div class="img1 wow animate__animated animate__bounceInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s" style="margin-top: 100px;">
                    <img src="../../assets/aqgl/d04.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
    mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
    var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
    })
    wow.init()
    })
},
}
</script>

<style scoped>
.banner{
    width: 100%;
    height: 3rem;
    overflow: hidden;
}
.banner:hover img{
    transform: scale(1.2);
}
.banner img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.main1,
.main2{
    width: 100%;
    /* height: 3rem; */
    background-color: #ededed;
    /* margin: 20px auto; */
    padding-top: 20px;
    padding-bottom: 20px;
}
.title1{
    font-size: .1rem;
    font-weight: 700;
    padding: 20px 0;
}
.content1{
    width: 70%;
    /* height: 2.66rem; */
    margin: 0 auto;
    display: flex;
    /* background-color: green; */
    flex-wrap: wrap;
}
.img1,.img2{
    width: 50%;
    height: 375px;
}
img{
    width: 100%;
    height: 100%;
}
.text1{
    width: 50%;
    /* height: 2.66rem; */
    /* background-color: yellow; */
}
/* 第二部分 */
.main2{
    background-color: #fff;
}
/* 第三部分 */
.img3{
    width: 19%;
    height: 2.8rem;
    background-color: pink;
}
.content3{
    width: 80% !important;
    justify-content: space-between;
}
</style>