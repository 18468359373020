<template>
    <div class="yy">
      <div class="banner">
        <img src="../../assets/goyb.jpg" alt="">
    </div>
      <!-- 上盒子 -->
      <div class="yy-box1">
          <!-- <div class="yy-box1-1">走进翼博</div> -->
          <ul>
              <li><router-link to="/honor/myzs1">企业简介</router-link></li>
              <li><router-link to="/honor/myzs2">企业文化</router-link></li>
              <li><router-link to="/honor/myzs3">组织架构</router-link></li>
              <li><router-link to="/honor/myzs4">企业资质</router-link></li>
              <li><router-link to="/honor/myzs5">荣誉证书</router-link></li>
              <li><router-link to="/honor/myzs6">新闻动态</router-link></li>
          </ul>
      </div>
      <!-- 组织架构 -->
      <div class="organization">
        <div class="organization-main">
          <div class="organization-title">
            郑州翼博餐饮企业管理有限公司组织架构图
          </div>
          <div class="organization-content-box">
            <div class="first1">
              总经理
            </div>
            <div class="zong-line1"></div>
            <div class="zong-line2"></div>
            <div class="zong-line3"></div>
            <div class="two1">
              副总经理
            </div>
            <div class="zong-line4"></div>
            <div class="zong-line5"></div>
            <div class="zong-line6 zong-line6-demo"></div>
            <div class="zong-line8 zong-line6-demo"></div>
            <div class="zong-line9 zong-line6-demo"></div>
            <div class="zong-line10 zong-line6-demo"></div>
            <div class="zong-line7"></div>
            
            <div class="three1 three">
              行政管理中心
            </div>
            <div class="three2 three">
              运营中心
            </div>
            <div class="three3 three">
              分支机构管理中心
            </div>
            <div class="three4 three">
              产品研发中心
            </div>
            <div class="xing-line1 line1"></div>
            <div class="xing-line2 line2"></div>
            <div class="xing-line3 line3"></div>
            <div class="xing-line4 line4"></div>
            <div class="xing-line5 line4"></div>
            <div class="yun-line1 line1"></div>
            <div class="yun-line2 line2"></div>
            <div class="yun-line3 line3"></div>
            <div class="yun-line4 line4"></div>
            <div class="yun-line5 line4"></div>
            <div class="fen-line1 line1"></div>
            <div class="fen-line2 line2"></div>
            <div class="fen-line3 line3"></div>
            <div class="fen-line4 line4"></div>
            <div class="fen-line5 line4"></div>
            <div class="fen-line6 line4"></div>
            <div class="fen-line7 line4"></div>
            <div class="chan-line1 line4"></div>
            <div class="chan-line2 line2"></div>
            <div class="chan-line3 line3"></div>
            <div class="chan-line4 line4"></div>
            <div class="chan-line5 line4"></div>
            <div class="xiang-line1 line1"></div>
            <div class="xiang-line2 line2"></div>
            <div class="xiang-line3 line3"></div>
            <div class="xiang-line4 line4"></div>
            <div class="xiang-line5 line4"></div>
            <div class="xiang-line6 line4"></div>
            <div class="xiang-line7 line4"></div>
            <div class="four1 four">
              人力资源部
            </div>
            <div class="four2 four">
              综合保障部
            </div>
            <div class="four3 four">
              财务部
            </div>
            <div class="four4 four">
              安全管理部
            </div>
            <div class="four5 four">
              品质管理部
            </div>
            <div class="four6 four">
              供应链管理部
            </div>
            <div class="four7 four">
              系统研发部
            </div>
            <div class="four8 four">
              菜品研发部
            </div>
            <div class="five1 four">
              项目部
            </div>
            <div class="five2 four">
              第一分公司
            </div>
            <div class="five3 four">
              中原分公司
            </div>
            <div class="five4 four">
              平顶山公司
            </div>
            <div class="five5 four">
              河南厨之本
            </div>
            <div class="six1 four">
              服务部
            </div>
            <div class="six2 four">
              生产部
            </div>
            <div class="six3 four">
              仓储部
            </div>
            <div class="six4 four">
              质控部
            </div>
            <div class="six5 four">
              后勤部
            </div>
          </div>
        </div>
      </div>
      <!-- 下盒子 -->
      <!-- <div class="zzjg">
          <img src="@/assets/zzjg.png" alt="">
      </div> -->
    </div>
  </template>
  
  <script>
  export default {
  methods:{
   
  }
  }
  </script>
  
  <style scoped>
  *{
    box-sizing: border-box;
}
.banner{
        width: 100%;
        height: 3rem;
        overflow: hidden;
    }
    .banner:hover img{
        transform: scale(1.2);
    }
    .banner img{
        transition: all .4s;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
    /* 组织架构 */
    .organization{
      width: 100%;
      margin: 20px 0;
    }
    .organization-main{
      width: 80%;
      /* height: 3.5rem; */
      background-color: #f6f2f1;
      margin: 0 auto;
    }
    .organization-title{
      width: 100%;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      color:#e85b58;
      font-weight: 700;
    }
    .organization-content-box{
      width: 100%;
      height: calc(3rem - 40px);
      position: relative;
    }
/* 第一部分 */
.first1{
  display: inline-block;
  width: .5rem;
  padding: 10px 5px;
  margin: 0 auto;
  background-color: red;
  box-shadow: 5px 2px 5px grey;
  border: 1px solid white;
  border-radius: 5px;
  font-size: .1rem;
  color: white;
  font-weight: 700;
}
.zong-line1{
  position: absolute;
  left: 3.99rem;
  width: 4px;
  height: .4rem;
  background: linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #e60a0a 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #09f32f 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #fad648 0) repeat-x;
  background-size: 0,2px 12px,0,0;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.zong-line2{
  position: absolute;
  left: 4rem;
  top: .44rem;
  width: 100px;
  height: 4px;
  background: 
    linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #0f0ae8 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #09f32f 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #e60a0a 0) repeat-x;
  background-size: 0, 0, 0, 12px 2px;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.zong-line3{
  position: absolute;
  left: 2.5rem;
  top: .54rem;
  width: 1.5rem;
  height: 2px;
  background: 
    linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #0f0ae8 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #09f32f 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #e60a0a 0) repeat-x;
  background-size: 0, 0, 0, 12px 2px;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.zong-line4{
  position: absolute;
  left: 1.5rem;
  top: .65rem;
  width: 2.5rem;
  height: 2px;
  background: 
    linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #0f0ae8 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #09f32f 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #e60a0a 0) repeat-x;
  background-size: 0, 0, 0, 12px 2px;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.zong-line5 {
  position: absolute;
  left: 4rem;
  top: .65rem;
  width: 2.5rem;
  height: 2px;
  background: linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #0f0ae8 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #e60a0a 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #fad648 0) repeat-x;
  background-size: 0, 0, 12px 2px, 0;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.zong-line6-demo{
  position: absolute;
  width: 2px;
  height: .12rem;
  background: linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #e60a0a 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #09f32f 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #fad648 0) repeat-x;
  background-size: 0,2px 12px,0,0;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.zong-line6{
  top: .67rem;
  left: 1.5rem;
}
.zong-line8{
  top: .67rem;
  left: 3.5rem;
}
.zong-line9{
  top: .67rem;
  left: 5.1rem;
}
.zong-line10{
  top: .67rem;
  left: 6.5rem;
}
.zong-line7{
  position: absolute;
  top: .55rem;
  left: 2.49rem;
  width: 2px;
  height: 1.4rem;
  background: linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #e60a0a 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #09f32f 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #fad648 0) repeat-x;
  background-size: 0,2px 12px,0,0;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.line1{
  position: absolute;
  width: 2px;
  height: .3rem;
  background: linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #000000 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #09f32f 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #fad648 0) repeat-x;
  background-size: 0,2px 12px,0,0;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.xing-line1{
  top: 1.05rem;
  left: 1.5rem;
}
.yun-line1{
  top: 1.05rem;
  left: 3.5rem;
}
.fen-line1{
  height: .78rem;
  top: 1.05rem;
  left: 5.1rem;
}
.xiang-line1{
  top: 2.16rem;
  left: 2.48rem;
}

.line2{
  position: absolute;
  width: 0.61rem;
  height: 2px;
  background: 
    linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #0f0ae8 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #09f32f 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #000000 0) repeat-x;
  background-size: 0, 0, 0, 12px 2px;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.xing-line2{
  left: 0.9rem;
  top: 1.2rem;
}
.yun-line2{
  left: 2.9rem;
  top: 1.2rem;
}

.fen-line2{
  width: 0.9rem;
  left: 4.2rem;
  top: 1.82rem;
}
.xiang-line2{
  width: 1rem;
  left: 1.48rem;
  top: 2.3rem;
}
.line3{
  position: absolute;
  width: 0.61rem;
  height: 2px;
  background: linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #0f0ae8 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #000000 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #000000 0) repeat-x;
  background-size: 0, 0, 12px 2px, 0;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.xing-line3 {
  left: 1.5rem;
  top: 1.2rem;
}
.yun-line3 {
  left: 3.5rem;
  top: 1.2rem;
}
.chan-line2{
  left: 6.2rem;
  top: 1.2rem;
  width: 0.3rem;
}
.chan-line3{
  left: 6.5rem;
  top: 1.2rem;
  width: 0.3rem;
}
.fen-line3{
  left: 5.1rem;
  top: 1.82rem;
  width: 0.9rem;
}
.xiang-line3{
  width: 1rem;
  left: 2.48rem;
  top: 2.3rem;
}
.line4{
  position: absolute;
  width: 2px;
  height: .15rem;
  background: linear-gradient(0deg, transparent 6px, #e60a0a 6px) repeat-y,
    linear-gradient(0deg, transparent 50%, #000000 0) repeat-y,
    linear-gradient(90deg, transparent 50%, #09f32f 0) repeat-x,
    linear-gradient(90deg, transparent 50%, #fad648 0) repeat-x;
  background-size: 0,2px 12px,0,0;
  background-position: 0 0, 100% 0, 0 0, 0 100%;
  animation: move2 1s infinite linear;
}
.xing-line4{
  top: 1.2rem;
  left: 0.9rem;
}
.xing-line5{
  top: 1.2rem;
  left: 2.1rem;
}
.yun-line4{
  top: 1.2rem;
  left: 2.9rem;
}
.yun-line5{
  top: 1.2rem;
  left: 4.1rem;
}
.chan-line1{
  top: 1.05rem;
  left: 6.5rem;
}
.chan-line4{
  top: 1.2rem;
  left: 6.2rem;
}
.chan-line5{
  top: 1.2rem;
  left: 6.8rem;
}
.fen-line4{
  top: 1.831rem;
  left: 4.2rem;
}
.fen-line5{
  top: 1.831rem;
  left: 4.8rem;
}
.fen-line6{
  top: 1.831rem;
  left: 5.4rem;
}
.fen-line7{
  top: 1.831rem;
  left: 5.99rem;
}
.chan-line7{
  top: 1.2rem;
  left: 6.8rem;
}
.xiang-line4{
  top: 2.3rem;
  left: 1.46rem;
}
.xiang-line5{
  top: 2.3rem;
  left: 2.01rem;
}
.xiang-line6{
  top: 2.3rem;
  left: 3rem;
}
.xiang-line7{
  top: 2.3rem;
  left: 3.49rem;
}
/* 副总经理 */
.two1{
  position: absolute;
  top: .32rem;
  left: 4.5rem;
  display: inline-block;
  width: .5rem;
  padding: 10px 5px;
  margin: 0 auto;
  background-color: grey;
  box-shadow: 5px 2px 5px grey;
  border: 1px solid white;
  border-radius: 5px;
  font-size: .1rem;
  color: white;
  font-weight: 700;
}
/* 行政管理中心 */
.three{
  position: absolute;
  display: inline-block;
  padding: 10px 5px;
  margin: 0 auto;
  background-color: rgb(235, 233, 233);
  box-shadow: 5px 2px 5px grey;
  border: 1px solid red;
  border-radius: 5px;
  font-size: .1rem;
  color: red;
  font-weight: 700;
}
.three1{
  top: .8rem;
  left: 1.1rem;
  width: .8rem;
}
.three2{
  top: .8rem;
  left: 3.1rem;
  width: .8rem;
}
.three3{
  top: .8rem;
  left: 4.65rem;
  width: .9rem;
}
.three4{
  top: .8rem;
  left: 6.1rem;
  width: .8rem;
}
/* 人力 */
.four{
  position: absolute;
  display: inline-block;
  padding: 5px 5px;
  margin: 0 auto;
  background-color: rgb(235, 233, 233);
  box-shadow: 5px 2px 5px grey;
  border: 1px solid black;
  border-radius: 5px;
  font-size: .09rem;
  color: black;
  font-weight: 700;
}
.four1{
  top: 1.35rem;
  left: 0.63rem;
  width: .55rem;
}
.four2{
  top: 1.35rem;
  left: 1.22rem;
  width: .55rem;
}
.four3{
  top: 1.35rem;
  left: 1.8rem;
  width: .55rem;
}
.four4{
  top: 1.35rem;
  left: 2.64rem;
  width: .55rem;
}
.four5{
  top: 1.35rem;
  left: 3.22rem;
  width: .55rem;
}
.four6{
  top: 1.35rem;
  left: 3.8rem;
  width: .65rem;
}
.four7{
  top: 1.35rem;
  left: 5.92rem;
  width: .55rem;
}
.four8{
  top: 1.35rem;
  left: 6.54rem;
  width: .55rem;
}
.five1{
  top: 1.96rem;
  left: 2.2rem;
  width: .55rem;
}
.five2{
  top: 1.96rem;
  left: 3.93rem;
  width: .55rem;
}
.five3{
  top: 1.96rem;
  left: 4.53rem;
  width: .55rem;
}
.five4{
  top: 1.96rem;
  left: 5.12rem;
  width: .55rem;
}
.five5{
  top: 1.96rem;
  left: 5.73rem;
  width: .55rem;
}
.six1{
  top: 2.4rem;
  left: 1.25rem;
  width: .45rem;
}
.six2{
  top: 2.4rem;
  left: 1.75rem;
  width: .45rem;
}
.six3{
  top: 2.4rem;
  left: 2.25rem;
  width: .45rem;
}
.six4{
  top: 2.4rem;
  left: 2.75rem;
  width: .45rem;
}
.six5{
  top: 2.4rem;
  left: 3.25rem;
  width: .45rem;
}
@keyframes move2 {
  from {
  }
  to {
    background-position: 0 -12px, 100% 12px, 12px 0, -12px 100%;
  }
}
  </style>