<template>

    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <!-- 上盒子 -->
      <!-- <div class="yy-box1">
          <ul>
            <li><router-link to="/case/CaseSix">国机集团智能制造高峰论坛</router-link></li>
            <li><router-link to="/case/CaseZJLY70">中机六院成立70周年</router-link></li>
            <li><router-link to="/case/Case713">客户公司成立60周年</router-link></li>
            <li><router-link to="/case/CaseDIY">中秋月饼DIY</router-link></li>
            <li><router-link to="/case/Case38">女神节的祝福</router-link></li>
          </ul>
      </div> -->
      <h2 class="title">情满中秋，爱在六院</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>中秋节来临之际，为弘扬中华民族传统文化，丰富广大职工的文化生活，营造浓厚的节日氛围，翼博餐饮配合中机六院开展了一场名为“情满中秋，爱在六院”的主题活动。</p>
            <p>首先，翼博餐饮的大厨们向参加活动的大朋友和小朋友们介绍了制作月饼所需的食材和工具，并演示了如何将面团搓圆、擀平，并包入香甜的馅料。</p>
            <p>小朋友们迫不及待地戴上一次性手套，用双手捏着面团，小心翼翼地将馅料包裹其中。他们用心地揉搓着面团，感受着面团的温度和韧性。</p>
            <p>厨师们为大家演示了如何使用模具完成月饼的最终成型。小朋友们看着圆圆的面团一下子就变成了月饼的形状，脸上充满了惊喜。他们学着师傅的样子做着自己喜欢的作品，期待着烤出来的效果。</p>
            <p>当月饼从烤箱中取出时，金黄的外皮闪着诱人的光泽。小朋友们急忙品尝起来，脸上露出了满足和幸福的表情。他们发现，自己亲手制作的月饼味道格外美味，因为这不仅仅是一份美食，更是他们的努力和付出的结晶。通过这次活动，不仅让广大职工感受到了中秋节的浓厚氛围，也增进了彼此之间的交流和友谊。大家在共同参与的过程中，体验到了传统文化的魅力，更加深入地了解了中秋节的意义和价值。</p>
            <p></p>
        </div>
        <div class="img">
            <div class="img-all">
                <div class="img1">
                    <img src="../../assets/DIY/06.gif" alt="">
                </div>
                <div class="img1">
                    <img src="../../assets/DIY/07.gif" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/DIY/01.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/DIY/02.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/DIY/03.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/DIY/04.jpg" alt="">
                </div>
                <div class="img1 wow animate__animated animate__fadeInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <img src="../../assets/DIY/05.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
    </div>
  </template>
  
  <script>
 import { WOW } from 'wowjs'
export default {
    mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => { // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animated',//动画css类（默认为animated） 
        offset: 0,//到元素距离触发动画（当默认为0） 
        mobile: true, //在移动设备上触发动画（默认为true） 
        live: true//对异步加载的内容进行操作（默认为true）
      })
      wow.init()
    })
  },
}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .18rem;
}
/* 图片 */
.img-all{
    width: 850px;
    /* height: 9.3rem; */
    /* background-color: blue; */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}
.img-all .img1{
    width: 850px;
    height: 450px;
    overflow: hidden;
    margin: 10px 0;
}
/* .img-all .img3{
    width: 850px;
    height:300px;
    overflow: hidden;
} */
.img-all .img-ji{
    width: 850px;
    height: 473px;
    /* background-color: pink; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
}

.img-all .img1:hover img{
    transform: scale(1.2);
}
.img-all img{
    transition: all .4s;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
  </style>