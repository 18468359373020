<template>
    <div class="yy">
      <!-- 左侧盒子 -->
      <div class="yy-box1">
          <div class="yy-box1-1">组织架构</div>
          <ul>
              <li><router-link to="/gy/gy1">组织架构</router-link></li>
              <li><router-link to="/gy/gy2">联系我们</router-link></li>
          </ul>
      </div>
  
      <!-- 右侧盒子 -->
      <div class="yy-box2">
          <h2 class="zzzs">组织架构</h2>
          <div class="yy-box2-1">
              <div class="zp"><img src="@/assets/zzjg.png" alt=""></div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
      
 
  </style>