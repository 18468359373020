<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">翼博餐饮成功注册“翼帆食刻”商标，开启品牌发展新篇章</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>近日，郑州翼博餐饮企业管理有限公司（以下简称“翼博餐饮”）旗下的“翼帆食刻”（YFSK）商标已成功获得国家知识产权局的正式批准注册。</p>
            <p>自2024年初提交注册申请以来，“翼帆食刻”商标历经数月的严格审查和公示程序，最终赢得了国家知识产权局的认可。这一过程的顺利完成，不仅彰显了翼博餐饮对品牌建设的深刻理解和高度重视，也体现了公司在市场竞争中强大的品牌影响力和独特的市场定位。</p>
            <p>回顾翼博餐饮的发展历程，自2014年成立以来，我们始终秉持“品质至上，服务为先”的经营理念，致力于为消费者提供健康、美味、便捷的餐饮服务。经过数年的不懈努力，公司已成为郑州地区知名的餐饮企业管理公司。“翼帆食刻”作为公司精心打造的新品牌，承载着我们对未来的无限憧憬和期待。</p>
            <p>据公司法定代表人杨天义先生介绍，“翼帆食刻”品牌将传承翼博餐饮一贯的品质标准和服务理念，同时融入更多时尚、健康的元素，旨在通过创新的产品和服务，满足消费者对高品质餐饮体验的需求。我们相信，“翼帆食刻”的推出，将为公司带来新的增长点，进一步巩固和扩大我们在餐饮市场的份额。</p>
            <p>随着“翼帆食刻”商标的成功注册，翼博餐饮将进一步加强品牌建设和市场推广力度。我们将通过多元化的营销渠道和创新的市场策略，不断提升品牌知名度和美誉度。同时，公司也将继续秉承创新、务实、诚信的经营原则，为消费者提供更加优质的产品和服务，为餐饮行业的繁荣发展贡献自己的力量。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-k.png" alt=""></div>
        </div>
        <hr>
        <div class="new_next">
        <router-link to="/honor/new3">
            <div class="new_right">上一篇:翼博餐饮成功中标郑州海为酒店餐厅服务商，携手共创餐饮新篇章</div>
        </router-link>
        <router-link to="/honor/new2">
            <div class="new_right">下一篇:守护健康，共筑美味</div>
        </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    font-weight: 700;
}
.new_img1{
    width: 400px;
    height: 400px;
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    /* height: 2rem; */
    /* margin-top: .2rem; */
    /* background-color: pink; */
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
  </style>