<template>
  <div>
    <div class="banner">
      <img src="../../assets/goyb.jpg" alt="" />
    </div>
    <!-- 上盒子 -->
    <!-- <div class="yy-box1">
      <ul>
        <li>
          <router-link to="/case/CaseSix">国机集团智能制造高峰论坛</router-link>
        </li>
        <li><router-link to="/case/CaseZJLY70">中机六院成立70周年</router-link></li>
        <li><router-link to="/case/Case713">客户公司成立60周年</router-link></li>
        <li><router-link to="/case/CaseDIY">中秋月饼DIY</router-link></li>
        <li><router-link to="/case/Case38">女神节的祝福</router-link></li>
      </ul>
    </div> -->
    <h2 class="title">女神节的祝福</h2>
    <hr />
    <div class="main">
      <div class="content">
        <p>
          在这个充满爱与关怀的节日里，我们用心制作了一块块精美的蛋糕，旨在向身边的女神们传递我们的敬意、感激和祝福。
        </p>
        <!-- <p>首先，在制作蛋糕环节，大家齐心协力，共同完成了一个个精美的蛋糕。每一块蛋糕都寓意着我们对她们的敬意和感激，每一口都是我们对她们的爱意和祝福。蛋糕上的糖霜如同她们的笑容，甜美而温暖；蛋糕中的水果如同她们的生活，丰富多彩。在这个过程中，大家不仅体验到了亲手制作美食的乐趣，还增进了彼此之间的感情。</p>
            <p>接下来，在赠送奶茶环节，我们为她们送上了一杯杯热气腾腾的奶茶。奶茶中的奶香如同她们的爱，浓郁而深沉；茶香则象征着她们的智慧，清新而独特。这杯奶茶不仅仅是一种饮品，更是我们对她们的关爱和祝福的传递。</p>
            <p>整个活动充满了欢声笑语和温馨的氛围，让我们深刻体会到了女性在家庭和社会中的重要地位。通过这个活动，我们更加珍惜与她们相处的时光，愿她们在这个特殊的日子里感受到满满的幸福和关爱。</p> -->
      </div>
    </div>
    <div class="demo1-video">
      <!-- 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。 -->
      <!-- autoplay: "muted", //自动播放属性,muted:静音播放 -->
      <!-- preload="auto" //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。 -->
      <!-- poster="../assets/img/E0531.jpg"> //设置视频封面        -->
      <!-- <source :src="这里后台传回来的视频链接地址" type="video/mp4" >    //视频地址 -->
      <Video
        id="myVideo"
        class="myVideo"
        controls
        autoplay="muted"
        preload="auto"
        poster="../../assets/38/01.jpg"
      >
        <source src="../../assets/38/38.mp4" type="video/mp4" />
      </Video>
    </div>
  </div>
</template>
  <script>
import { WOW } from "wowjs";
export default {
  mounted() {
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
};
</script>
  
  <style scoped>
.banner {
  width: 100%;
  height: 3rem;
  overflow: hidden;
}
.banner:hover img {
  transform: scale(1.2);
}
.banner img {
  transition: all 0.4s;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.yy-box1 {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f6f6f6;
}

/* 标题 */
.title {
  margin: 0.2rem 0;
  font-size: 0.13rem;
}
hr {
  width: 60%;
  margin: 0 auto;
}
.main {
  width: 60%;
  /* background-color: pink; */
  margin: 0.1rem auto;
}
.content p {
  text-indent: 2em;
  text-align: center;
  font-size: 0.1rem;
  line-height: 0.18rem;
}
/* 视频 */
#myVideo {
  margin: 20px 0;
  width: 50%;
  height: 3rem;
}
/* source{
    width: 50%;
    height: 3rem;
} */
</style>