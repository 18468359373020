<template>
  <div class="yy">
    <div class="banner">
        <img src="../../assets/goyb.jpg" alt="">
    </div>
    <!-- 上盒子 -->
    <div class="yy-box1">
        <ul>
            <li><router-link to="/honor/myzs1" class="router-link-active">企业简介</router-link></li>
            <li><router-link to="/honor/myzs2">企业文化</router-link></li>
            <li><router-link to="/honor/myzs3">组织架构</router-link></li>
            <li><router-link to="/honor/myzs4">企业资质</router-link></li>
            <li><router-link to="/honor/myzs5">荣誉证书</router-link></li>
            <li><router-link to="/honor/myzs6">新闻动态</router-link></li>
        </ul>
    </div>
    <div class="yb-main">
        <div class="yb-introduction">
            <div class="yb-introduction-img-box wow animate__animated animate__bounceInLeft animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                <img src="../../assets/goyb/qyjj.jpg" alt="">
            </div>
            <div class="yb-introduction-content-box">
                <div class="img">
                    <div class="yb-introduction-content-bg"></div>
                    <img src="../../assets/goyb/bg2.png" alt="">
                </div>
                <div class="yb-introduction-content wow animate__animated animate__zoomInRight animate__delay-1s animate__slower animate__repeat-1 " data-wow-duration="1s" data-wow-delay="1s" data-wow-iteration="1s">
                    <p>郑州翼博餐饮企业管理有限公司成立于2014年，注册资金2100万元，是一家专注团餐服务的餐饮管理企业。服务行业涉及大、中专院校餐饮服务、机关单位职工餐厅餐饮服务、物流园区职工餐厅餐饮服务、企事业职工餐厅餐饮服务、军工企业职工餐厅餐饮服务等多个领域。</p>
                    <p>公司曾荣获全国优秀餐饮企业、AAA信用等级企业、全国高校后勤优秀服务企业、中国校园团餐联盟企业、重合同守信用企业、诚信经营示范单位、全国标准化食堂诚信示范单位、全国团膳质量诚信服务双十佳单位。</p>
                    <p>公司总经理荣获“中国诚信企业家”称号。</p>
                    <p>公司项目管理已通过质量管理体系认证、食品安全管理体系认证、职业健康安全管理体系认证、环境管理体系认证。</p>
                    <p> 公司发展战略：供应链+互联网战略。公司自成立以来，荟萃业界精英，将食品安全、厨艺技术、星级服务、明厨亮灶、6S+智能互联网后台相结合，借助供应链管理思维，为合作伙伴提供全方位的餐厅饮食服务综合解决方案，助力合作伙伴提高餐饮管理水平和饮食服务质量。</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
    return {
     
    }
  },
  methods: {
  
  }
}

</script>

<style scoped>
*{
    box-sizing: border-box;
}
.yb-main{
    width: 100%;
    background-color: #ededed;
}
/* 顶部banner css */
    .banner{
        width: 100%;
        height: 3rem;
        overflow: hidden;
    }
    .banner:hover img{
        transform: scale(1.2);
    }
    .banner img{
        transition: all .4s;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
    .yb-introduction{
        width: 82%;
        height: 3rem;
        margin: 20px auto;
        display: flex;
    }
    .yb-introduction-img-box{
        width: 4rem;
        height: 3rem;
        overflow: hidden;
    }
    .yb-introduction-img-box:hover img{
        transform: scale(1.2);
    }
    .yb-introduction-img-box img{
        transition: all .4s;
        display: block;
        width: 100%;
        /* height: 100%; */
        object-fit: cover;
    }
    .yb-introduction-content-box{
        position: relative;
        width: 4rem;
        height: 3rem;
    }
    .yb-introduction-content-box .img{
        width: 4rem;
        height: .6rem;
        border: none;
        vertical-align: middle;
    }
    .yb-introduction-content-box .img img{
        position: absolute;
        left: 140px;
        top: 15px;
        width: 85px;
        height: 85px;
    }
    .yb-introduction-content-bg{
        background-image: url(../../assets/goyb/bg1.png);
        width: 275px;
        height: 100px;
        position: absolute;
        left: 150px;
        top: 30px;
    }
    .yb-introduction-content{
        width: 5rem;
        height: 1.9rem;
        padding: 0 150px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 0.1rem !important;
        /* background-color: pink; */
    }
    .yb-introduction-content p {
        line-height: 30px;
        text-align: left;
        font-size: 0.09rem;
        text-indent:2em;
    }
    .yy-box2 {
        /* padding-top: 1%; */
        padding-bottom: 2%;
        width: 70%;
        margin: 0 auto;
        text-align: left;
        /* float: right; */
    }

    .yy-box2 p {
        text-indent:2em;
        line-height: 40px;
        font-size: 18px;
        font-family: 华文隶书;
    }




</style>