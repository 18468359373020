<template>
    <div>
      <div class="banner">
          <img src="../../assets/goyb.jpg" alt="">
      </div>
      <h2 class="title">郑州翼博餐饮企业管理有限公司成功举办2024年度秋季安全生产管理培训会议</h2>
    <hr>
    <div class="main">
        <div class="content">
            <p>在秋风送爽、硕果累累的季节里，郑州翼博餐饮企业管理有限公司于2024年8月26日在郑州海为酒店二楼一号会议室成功举办了2024年度秋季安全生产管理培训会议。此次会议汇聚了公司高层管理人员及核心团队成员，旨在通过系统培训，进一步提升公司在食品安全与消防安全领域的管理水平，确保顾客与员工的安全健康，推动公司稳健发展。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-n01.png" alt=""></div>
            <p>会议由公司副总经理杨天龙先生主持。公司总经理杨天义先生致开幕辞。杨总经理以其一贯的严谨与远见，首先强调了安全在企业发展中的不可撼动地位，深刻阐述了安全不仅是生产的前提，更是企业生存与发展的基石，安全责任重于泰山。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-n02.png" alt=""></div>
            <p>随后，会议进入了更为深入的探讨阶段，由公司副总经理杨天龙先生为大家带来了一场关于安全管理知识的精彩分享。杨副总凭借其丰富的行业经验和独特的视角，不仅对当前食品安全与消防安全领域的形势与挑战进行了深入剖析，还紧密结合公司实际情况，提出了一系列针对性强、实用性高的管理策略和实操建议。他的讲解生动具体，案例丰富详实，让与会者受益匪浅，纷纷表示将把所学应用到实际工作中，为公司的安全发展贡献自己的力量。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-n03.png" alt=""></div>
            <p>会议内容广泛而深入，不仅涵盖了从食材采购、储存、加工到餐桌服务的食品安全全链条管理，还详细讲解了消防设施的维护与检查、火灾应急预案的制定与执行等消防安全知识。通过生动的案例分析和实操演练，员工们对安全生产的重要性有了更加深刻的认识，也掌握了更多实用的安全管理技能。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-n04.png" alt=""></div>
            <p>会议过程中，特别设置了互动提问环节，鼓励员工们积极发言，就实际工作中遇到的安全问题进行交流探讨。这一环节不仅激发了员工的参与热情，也有效促进了安全管理知识的传播与内化。总经理亲临现场，对员工的积极参与和提出的问题给予了耐心解答和高度评价，进一步强调了公司对于安全生产的高度重视和坚定决心。</p>
            <div class="new_img1"><img src="../../assets/goyb/new-n05.png" alt=""></div>
            <p>安全是企业发展的生命线，也是每一位员工的共同责任。郑州翼博餐饮企业管理有限公司将以本次培训会议为契机，持续加强安全生产管理，不断提升安全管理水平，为公司的长远发展奠定坚实基础。我们相信，在全体员工的共同努力下，郑州翼博餐饮企业管理有限公司的明天将更加辉煌！</p>
        </div>
        <hr>
        <div class="new_next">
        <!-- <router-link to="/honor/new6">
            <div class="new_right">上一篇:郑州翼博餐饮携手海为酒店，首次婚宴合作美食盛宴引赞叹</div>
        </router-link> -->
        <router-link to="/honor/new6">
            <div class="new_right">下一篇:郑州翼博餐饮携手海为酒店，首次婚宴合作美食盛宴引赞叹</div>
        </router-link>
        </div>
    </div>
    </div>
  </template>
  
  <script>
export default {
    mounted () 
 {
    this.$router.afterEach((to, from, next) => {
        window.scrollTo(0, 0)
    })
}

}
  </script>
  
  <style scoped>
  .banner{
      width: 100%;
      height: 3rem;
      overflow: hidden;
  }
  .banner:hover img{
      transform: scale(1.2);
  }
  .banner img{
      transition: all .4s;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
  }
  .yy-box1{
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f6f6f6;
}
  
  /* 标题 */
.title{
    margin: .2rem 0;
    font-size: .13rem;
}
hr{
    width: 60%;
    margin: 0 auto;
}
.main{
    width: 60%;
    /* background-color: pink; */
    margin: .1rem auto;
}
.content p{
    text-indent:2em;
    text-align: left;
    font-size: .1rem;
    line-height: .23rem;
}
.content .a1{
    text-align: center;
    font-weight: 700;
}
.new_img1{
    width: 800px;
    height: 400px;
    margin: 5px auto;
}
.new_img1 img{
    width: 100%;
    height: 100%;
}
/* 翻篇 */
.new_next{
    width: 100%;
    /* height: 2rem; */
    /* margin-top: .2rem; */
    /* background-color: pink; */
    font-size: .09rem;
    text-align: left;
}
/* .new_next .new_left, */
.new_next .new_right{
    padding:.09rem 0;
}
.new_next .new_right:hover{
    color: red;
}
.main hr{
    width: 100%;
    margin-top: .2rem;
}
  </style>